import Authentication from '../services/authentication'

export default {
    
    //create new season
    async login (data) {
		let authData, result;
		try {
            authData = await Authentication.login(data)
            //console.log(authData.data)
            if(authData.data.success)
            {
                const auth = {
                    accessToken: authData.data.token,
                    idToken: authData.data.user.usuarioId,
                    name: authData.data.user.persona.nombre + " " + authData.data.user.persona.apellido,
                    fotoUri : authData.data.user.fotoUri,
                    rol: authData.data.user.rol.rolId
                }
                this.setSession(auth)
            }
     // console.log(authData)
			result = authData.data;
		} catch (error) {
			//console.log(error)
			result = false;
		}
		return result;
    },
    async checkPassword (data) {
		let result;
		try {
            result = await Authentication.checkPassword(data)
		} catch (error) {
			//console.log(error)
			result = false;
		}
		return result ? result.data : false;
    },
    async forgotPassword (data) {
		let result;
		try {
            result = await Authentication.forgotPassword(data)
		} catch (error) {
			//console.log(error)
			result = false;
		}
		return result ? result.data : false;
    },
    // Sets user details in localStorage
    setSession(authResult){
        //console.log(authResult,"authresult")
        // Set the time that the access token will expire at
        //5minutos

        //EXPERE TONKEN IN 1 HOUR
        let expiresAt = JSON.stringify((3600000) + new Date().getTime());
        console.log("🚀 ~ file: authentication.js:58 ~ setSession ~ expiresAt:", expiresAt)
       

//         let expiresAt = JSON.stringify((900000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('user_name', authResult.name);
        localStorage.setItem('user_photo', authResult.fotoUri);
        localStorage.setItem('user_rol', authResult.rol);
        localStorage.setItem('expires_at', expiresAt);

    }
}