import { server } from './config'
import client from './client'

export default {
    list (data,config = {}) {
		return client.get(`${server}/producto?p=${data.page}`)
	},
	save(data,config = {}){
       return client.post(`${server}/producto`, data, config)
   },
   saveMovimiento(data,config = {}){
	return client.post(`${server}/inventario`, data, config)
},
   listTipoMovimientos (config = {}) {
		return client.get(`${server}/tipos/movimientos`)
	},
	listMovimientos (data,config = {}) {
		return client.get(`${server}/inventario?p=${data.page}`)
	},
	listCategorias (config = {}) {
		return client.get(`${server}/categoria`)
	},
	listUbicaciones (config = {}) {
		return client.get(`${server}/ubicacion`)
	},
	get (productoId,config = {}) {
		return client.get(`${server}/producto/${productoId}`)
	},
	listProductoBySearch (data,config = {}) {
		return client.get(`${server}/search/producto/${data.search}?p=${data.page}`)
	},
	listMovimientosBySearch (data,config = {}) {
		return client.get(`${server}/search/inventario?p=${data.page}&fecha=${data.fecha}&search=${data.search}`)
	},
	update(data,config = {}){
       return client.put(`${server}/producto`, data, config)
   },
   delete (proveedorId,config = {}) {
		return client.delete(`${server}/producto/${proveedorId}`)
	},
	upload(data,config = { headers: {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}}){
		return client.post(`${server}/upload`, data, config)
	},

}