import { server } from './config'
import client from './client'

export default {
    list (data,config = {}) {
		return client.get(`${server}/notificaciones/${data.id}?p=${data.page}`)
	},
	update (data,config = {}) {
		return client.put(`${server}/notificacion`,data,config)
	}
}