import { server } from './config'
import client from './client'

export default {
  save (data, config = {}, editable) {
    return client.post(`${server}/orden?editable=${editable}`,data,config)
  },
  sendRequest (data, config = {}) {
    return client.post(`${server}/solicitud/orden`,data,config)
  },
  addTaskItem (data, config = {}) {
    return client.post(`${server}/orden/tarea`,data,config)
  },
  addProductItem (data, config = {}) {
    return client.post(`${server}/orden/producto`,data,config)
  },
  removeProductItem (data, config = {}) {
    return client.delete(`${server}/orden/producto/${data.id}`)
  },
  update (data, config = {}, editable) {
    return client.put(`${server}/orden?editable=${editable}`,data,config)
  },
  updateTask (data, config = {}) {
    return client.put(`${server}/orden/tarea`,data,config)
  },
  listById (data, config = {}) {
    return client.get(`${server}/orden/${data.id}`)
  },
  list (data) {
    return client.get(`${server}/orden?filtro=${data.filtro}&p=${data.page}&c=${15}`)
  },
  listByFilter (data) {
    return client.get(`${server}/filter/orden?filtro=${data.filtro}&p=${data.page}&c=${15}`)
  },
  listByFilterService (data) {
    return client.get(`${server}/filter/servicio?filtro=${data.filtro}&p=${data.page}&c=${15}`)
  },
  listTask (data) {
    return client.get(`${server}/user/tareas/${data.id}?displayAll=${data.displayAll}`)
  },
  search (data) {
    return client.get(`${server}/search/orden/${data.search}?p=${data.page}&c=${15}`)
  },
  listOrdersRequests (data) {
    return client.get(`${server}/solicitud/orden?p=${data.page}&filtro=${data.filtro}&c=${15}`)
  },
  lastOt (data) {
    return client.get(`${server}/activo/lastOt/${data.id}`)
  },
  revisionOT (data) {
    return client.get(`${server}/revision/orden/${data.activo_id}?fecha=${data.fecha}&tipo=${data.tipo}`)
  }
}