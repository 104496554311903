import React from 'react';

export default class DashboardCard extends React.Component {
    render() {
        let className = "card widget_2 big_icon ";
        if (this.props.iconName) {
            className += this.props.iconName;
        }
        return (
            <div className="col-lg-3 col-md-6 col-sm-12">
                <div className={className}>
                    <div className="body">
                        <h6 style={{ zIndex: 1000 }}>{this.props.title}</h6>
                        <h2
                            style={{
                                color: this.props.indicadoresMantenimiento && "black"
                            }}
                        >{this.props.value}<small className="info">{this.props.value2}</small></h2>
                        {this.props.showProgress &&
                            <div className="progress" style={{ zIndex: 800 }}>
                                <div className="progress-bar l-green" role="progressbar" aria-valuenow={this.props.valuePorct} aria-valuemin={0} aria-valuemax={this.props.valueMax} style={{ width: this.props.valuePorctSt }} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}