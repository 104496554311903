import axios from 'axios'
import history from '../history';
const client = axios.create({
    timeout: 50000,
    headers: { 'Content-Type': 'application/json' }
})

// add interceptor on http client to add token authentication on every request
client.interceptors.request.use((config) => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('access_token')
        const userId = localStorage.getItem('id_token')
        if (config.headers["Content-Type"] !== "multipart/form-data") {
            if (token) {
                //auth = JSON.parse(token);
                let expiresAt = localStorage.getItem('expires_at');
                if (new Date().getTime() > expiresAt) {
                    // Clear access token and ID token from local storage
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('id_token');
                    localStorage.removeItem('expires_at');
                    localStorage.removeItem('user_name');
                    localStorage.removeItem('user_photo');
                    localStorage.removeItem('user_rol');
                    history.replace('/login');
                } else {
                    config.headers.Authorization = `${token}`
                    if (config.data && userId !== null && userId !== undefined) {
                        config.data.usuario = { usuarioId: parseInt(userId) }
                    }
                    let expiresAt = JSON.stringify((3600000) + new Date().getTime());

                    // let expiresAt = JSON.stringify((900000) + new Date().getTime());
                    localStorage.setItem('expires_at', expiresAt);
                }
            }
        }
        //console.log(config)
        resolve(config)
    })
}, (error) => {
    return Promise.reject(error)
})

/* agregando interceptor si el servidor responde status 401 se regresa al login*/
// response interceptors
client.interceptors.response.use((response) => {
    return response
}, (error) => {

    if (error && error.response.status && error.response.status === 401) {
        // Clear access token and ID token from local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_photo');
        localStorage.removeItem('user_rol');
        history.replace('/login');
    }
    return Promise.reject(error)
})

export default client;
