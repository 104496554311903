import React, { useEffect, useState } from 'react';
import '../../App.css';
import { Card, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

export default function ModaladdUserProveedor(props) {
    const { show, onHide, data, handleChange, save } = props;
    const [dataUser, setDataUser] = useState({
        nombre: '',
        direccion: ''
    });

    useEffect(() => {
        if (show) {
            console.log("===================> Modal add user <===================");
            setDataUser({ nombre: data.value });
            console.log(data)
        }
        // eslint-disable-next-line
    }, [show]);

    return (
        <Modal isOpen={show} size="md" backdrop className="p-0">
            <ModalHeader toggle={() => { onHide() }}>
                <div className="header">
                    <h6><strong>Agregar </strong> Nuevo Proveedor</h6>
                </div>
            </ModalHeader>
            <section className="content" style={{ width: '100%', margin: 0 }}>
                <ModalBody>
                    <Card className="mb-3">

                        <label htmlFor="nombre">Ingresa el nombre del proveedor</label>
                        <Input
                            type="text"
                            name='trabajoRealizado'
                            id='trabajoRealizado'
                            placeholder="Ingrese el nombre del proveedor"
                            value={dataUser.nombre}
                            onChange={(e) => { handleChange({ [e.target.name]: e.target.value, type: data.type }); setDataUser({ [e.target.name]: e.target.value }) }}
                        />
                        <label htmlFor="nombre">Ingresa la dirección</label>
                        <Input
                            type="textarea"
                            name='direccion'
                            id='direccion'
                            placeholder="Ingrese la dirección del proveedor"
                            value={dataUser.direccion}
                            onChange={(e) => { handleChange({ [e.target.name]: e.target.value, type: data.type }); setDataUser({ [e.target.name]: e.target.value }) }}
                        />
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => { save() }}>Guardar</Button>
                    <Button color="secondary" onClick={() => { onHide() }}>Cancelar</Button>
                </ModalFooter>
            </section>
        </Modal>
    )
}