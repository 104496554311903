import React from 'react';
import Loading from "../home/Loading"
import Container from "../Container"
import moment from "moment"
import "moment/locale/es";
import Api from "../modules/plan"
import { Card, Row, Col, Button, Alert, Breadcrumb, BreadcrumbItem, Table, Input, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TableHeaderlist from '../components/TableHeaderList';
import TableItemlist from '../components/TableItemList';
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationAlert from 'react-notification-alert';
import "react-notification-alert/dist/animate.css";
import history from '../history';

const options = {
    place: "br",
    message: "Sin Resultados",
    type: "warning",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
const options2 = {
    place: "br",
    message: "Error Inesperado",
    type: "danger",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
export default class PlanesMantenimientos extends React.Component {

    constructor(props) {
        super(props)
        moment.locale("es"); // it is required to select default locale manually
    }

    state = {
        loading: true,
        search: "",
        message: "",
        visible: false,
        warning: false,
        error: false,
        confirm: false,
        confirmMsg: "",
        tareasPopup: false,
        toggleNested: false,
        actividadesList: [],
        itemProcedimientos: "",
        data: [],
        totalPages: 0,
        currentPage: 0,
        pages: [],
        confirmDelete: false,
    }

    componentDidMount() {
        let userRol = (localStorage.getItem('user_rol'));
        this.setState({ userRol: parseInt(userRol, 10) });
        this.listPlanes(1);
    }

    getTipoMantenimiento(value) {
        switch (value) {
            case 1:
                return "Preventivo";
            case 2:
                return "Correctivo";
            case 3:
                return "Predictivo";
            case 4:
                return "Emergencia";
            default: return "";
        }
    }
    calcularTareas(arr) {
        let num = 0;
        for (const key in arr) {
            num = +arr[key].actividades.length;
        }
        return num;
    }

    async listPlanes(page) {
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        Api.list({ page }).then(res => {
            // console.log(res)
            if (res) {
                const response = res.data;
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    data.push({
                        id: response[key].planMantenimientoId,
                        nombre: response[key].descripcion,
                        tipo: this.getTipoMantenimiento(response[key].tipoMantenimiento),
                        tipoMantenimiento: response[key].tipoMantenimiento,
                        activo: response[key].activo.nombre,
                        activoId: response[key].activo.activoId,
                        activoCode: response[key].activo.codigo,
                        notareas: response[key].partes.length > 0 ? this.calcularTareas(response[key].partes) : 0,
                        estado: response[key].status,
                        actividades: response[key].partes.length > 0 ? response[key].partes[0].actividades : [],
                        usuario: response[key].tecnico,
                        proveedor: response[key].proveedor,
                        fecha: response[key].fecha,
                        hora: response[key].hora
                    })
                }
                if (totalPages === 0) this.refs.notify.notificationAlert(options);
            }
        }).catch((err) => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                data,
                pages,
                currentPage,
                totalPages,
                loading: false
            })
        })
    }
    editEvent = (event) => {
        history.push('/addevent', { event });
    }

    onSearch = (page) => {
        const { search } = this.state;
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        if (search !== "") {
            Api.search({ search, page }).then(res => {
                // console.log(res)
                if (res) {
                    const response = res.data;
                    totalPages = res.totalPages;
                    currentPage = res.currentPage;
                    for (let key in response) {
                        data.push({
                            id: response[key].planMantenimientoId,
                            nombre: response[key].descripcion,
                            tipo: this.getTipoMantenimiento(response[key].tipoMantenimiento),
                            activo: response[key].activo.nombre,
                            activoId: response[key].activo.activoId,
                            activoCode: response[key].activo.codigo,
                            notareas: response[key].partes.length > 0 ? this.calcularTareas(response[key].partes) : 0,
                            estado: response[key].status,
                            actividades: response[key].partes.length > 0 ? response[key].partes[0].actividades : [],
                            usuario: response[key].tecnico,
                            proveedor: response[key].proveedor,
                            fecha: response[key].fecha,
                        })
                    }
                    if (totalPages === 0) this.refs.notify.notificationAlert(options);
                }
            }).catch((err) => {
                this.refs.notifyError.notificationAlert(options2);
            }).finally(() => {
                for (let i = 0; i < totalPages; i++) {
                    pages.push((i + 1));
                }
                this.setState({
                    data,
                    pages,
                    currentPage,
                    totalPages,
                    loading: false
                })
            })
        } else {
            this.listPlanes(page);
        }
    }

    showDialog(id, status) {
        this.setState({
            confirm: true,
            planId: id,
            confirmMsg: status < 5 ? "Desactivar Plan" : "Activar Plan"
        })
    }

    deactivatePlan = () => {
        const { planId, confirmMsg } = this.state;
        this.setState({ confirm: false, visible: true })
        //console.log(planId)
        Api.update({
            planMantenimientoId: planId,
            status: (confirmMsg === "Desactivar Plan" ? 5 : 1)
        }).then((response) => {
            if (response) {
                this.setState({
                    visible: response.success,
                    warning: response.warning,
                    message: response.message,
                    confirm: false
                }, () => this.listPlanes(1))
            } else {
                this.setState({ error: true, confirm: false })
            }
        })
    }

    deletePlan = () => {
        const { planId } = this.state;
        Api.delete({ id: planId, }).then((response) => {
            if (response) {
                this.setState({
                    visible: response.success,
                    warning: response.warning,
                    message: response.message,
                    confirmDelete: false
                }, () => this.listPlanes(1))
            } else {
                this.setState({ error: true, confirmDelete: false })
            }
        })
    }

    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }

    truncateString(str, num) {
        // If the length of str is less than or equal to num
        // just return str--don't truncate it.
        if (!str || str.length <= num) {
            return str
        }
        // Return str truncated with '...' concatenated to the end of str.
        return str.slice(0, num) + '... '
    }

    render() {
        const { loading, data } = this.state;
        return (
            <Container menuId="tareas">
                {loading && <Loading />}
                <NotificationAlert ref="notify" />
                <NotificationAlert ref="notifyError" />
                <section className="content">
                    <div className="container-fluid">
                        <div className="block-header">
                            <Row >
                                <Col lg={7} md={6} sm={12} >
                                    <h2>Planes de Mantenimientos</h2>
                                    <Breadcrumb tag="ul" className="breadcrumb">
                                        <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                        <BreadcrumbItem className="breadcrumb-item" tag="span">Tareas</BreadcrumbItem>
                                        <BreadcrumbItem className="breadcrumb-item" active tag="span">Planes de Mantenimientos</BreadcrumbItem>
                                    </Breadcrumb>
                                    <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"></Button>
                                </Col>
                                <Col lg={5} md={6} sm={12} >
                                    {this.state.userRol < 3 && <a href="/addevent" className="App-link">
                                        <Button color="success" className="btn-icon float-right" type="button">
                                            <i className="zmdi zmdi-plus" />
                                        </Button>
                                    </a>}
                                    <InputGroup className="float-right">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText><i className="zmdi zmdi-search" /></InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text"
                                            onKeyUp={(event) => { if (event.keyCode === 13) this.onSearch(1) }}
                                            className="form-control"
                                            placeholder="Busqueda..."
                                            value={this.state.search}
                                            onChange={(event) => this.setState({ search: event.target.value })} />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <Row className="clearfix">
                            <Col lg={12} >
                                <Card className="card">
                                    <Table hover responsive className="mb-0 c_list c_table break_table theme-color">
                                        <thead>
                                            <tr>
                                                <TableHeaderlist title="#" />
                                                <TableHeaderlist title="Nombre" />
                                                <TableHeaderlist title="Tipo" style={{ width: 120 }} />
                                                <TableHeaderlist title="Activo Asociado" />
                                                <TableHeaderlist title={"No.\nActividades"} style={{ width: 100 }} />
                                                {this.state.userRol < 3 && <TableHeaderlist title="Acción" />}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.map((value, i) => {
                                                    return (
                                                        <tr>
                                                            <TableItemlist item={((this.state.currentPage - 1) * 15) + (i + 1)} />
                                                            <TableItemlist item={value.nombre} />
                                                            <TableItemlist item={value.tipo} />
                                                            <TableItemlist item={value.activo} />
                                                            <TableItemlist item={value.notareas} />
                                                            <td>
                                                                <Button title="Ver Actividades" color="secondary" size="sm" onClick={() => this.setState({ actividadesList: value.actividades, tareasPopup: true })}><i className={"zmdi zmdi-format-list-bulleted"} /></Button>
                                                                {this.state.userRol < 3 && <Button color="primary" size="sm" onClick={() => this.editEvent(value)} disabled={value.estado === 5 && true}><i className="zmdi zmdi-edit" /></Button>}
                                                                {this.state.userRol < 3 && <Button title={value.estado < 5 ? "Desactivar" : "Activar"} onClick={() => this.showDialog(value.id, value.estado)} color={value.estado < 5 ? "danger" : "success"} className="btn btn-sm"><i className={"zmdi " + (value.estado < 5 ? "zmdi-block" : "zmdi-check")} /></Button>}
                                                                {this.state.userRol < 3 && <Button title={"Eliminar"} onClick={() => this.setState({ confirmDelete: true, planId: value.id })} color={"danger"} className="btn btn-sm"><i className={"zmdi zmdi-delete"} /></Button>}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    <div className="justify-content-center">
                                        <Pagination className="pagination pagination-success mt-4">
                                            {
                                                this.state.pages.map((value, index) => {
                                                    return (
                                                        <PaginationItem active={value === this.state.currentPage}>
                                                            <PaginationLink href="#" onClick={() => this.onSearch(value)}>{value}</PaginationLink>
                                                        </PaginationItem>
                                                    );
                                                })
                                            }
                                        </Pagination>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <Modal size="lg" isOpen={this.state.tareasPopup} toggle={() => this.setState({ tareasPopup: false })}>
                        <ModalHeader toggle={() => this.setState({ tareasPopup: false })}>Actividades</ModalHeader>
                        <ModalBody>
                            <Row className="clearfix">
                                <Col sm={12}>
                                    <Table responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <TableHeaderlist title="#" />
                                                <TableHeaderlist title="Descripción" />
                                                <TableHeaderlist title="Frecuencia" />
                                                <TableHeaderlist title="Instrucciones"></TableHeaderlist>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.actividadesList.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <TableItemlist item={(index + 1)} />
                                                            <TableItemlist item={item.descripcion} />
                                                            <TableItemlist item={`Cada ${item.frecuencia} ${item.unidad}`} />
                                                            <td>
                                                                {this.truncateString(item.procedimientos, 30)}
                                                                {item.procedimientos && item.procedimientos.length > 30 && <Button title="Ver Instrucciones" onClick={() => this.setState({ itemProcedimientos: item.procedimientos, toggleNested: true })}>...</Button>}
                                                            </td>

                                                            {/* <TableItemlist item={item.checked && <i class="zmdi zmdi-check"></i>}/> */}
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Modal isOpen={this.state.toggleNested} toggle={() => this.setState({ toggleNested: false })} >
                                <ModalHeader>Instrucciones</ModalHeader>
                                <ModalBody><p>{this.state.itemProcedimientos}</p></ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={() => this.setState({ toggleNested: false })}>Aceptar</Button>{' '}
                                </ModalFooter>
                            </Modal>
                        </ModalBody>
                    </Modal>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        Error al eliminar
                    </Alert>
                </section>
                <SweetAlert warning showCancel
                    confirmBtnText="Sí"
                    cancelBtnText="No"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="default"
                    focusCancelBtn
                    title={"¿Estas Seguro?"}
                    onConfirm={this.deactivatePlan}
                    onCancel={() => this.setState({ confirm: false })}
                    show={this.state.confirm}
                >
                    {this.state.confirmMsg}
                </SweetAlert>
                <SweetAlert warning showCancel
                    confirmBtnText="Sí, estoy seguro"
                    cancelBtnText="No"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="default"
                    focusCancelBtn
                    title={"¿Estas Seguro?"}
                    onConfirm={this.deletePlan}
                    onCancel={() => this.setState({ confirmDelete: false })}
                    show={this.state.confirmDelete}
                >
                    {`¿Esta seguro que desea eliminar este plan de mantenimiento?\n
                        Esta acción es irreversible
                      `}
                </SweetAlert>
            </Container>
        )
    }
}