import React from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import {
    Button, Alert, Col, Row, Card,
    FormGroup, Breadcrumb,
    BreadcrumbItem, Spinner
} from 'reactstrap';
import Api from "../modules/proveedor"
import Activos from "../modules/activos"
import history from '../history';
import Select from 'react-select';
import { AvForm, AvField } from 'availity-reactstrap-validation';
export default class CrearProveedor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            nombre: "",
            telefono: "",
            correo: "",
            contacto: "",
            codigo: "",
            direccion: "",
            ciudad: "",
            estado: "",
            pais: "",
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "",
            loading: true,
            editing: false,
            proveedorId: undefined,
            tipoActivos: [],
            tipoProveedor: {
                value: 1,
                label: "Proveedor"
            },
            telefono2: "",
            tipoActivo: null,
            tipoProveedorError: "none"
        }
    }

    async componentDidMount() {
        const state = this.props.location.state
        await this.getClasificacion()
        if (state && state.id) {
            await this.getUserData(state.id)
        } else {
            this.setState({ loading: false })
        }
    }

    async getClasificacion() {
        let tipoActivos = []
        Activos.listTiposActivos().then((response) => {
            if (response) {
                for (let key in response) {
                    tipoActivos.push({
                        value: response[key].tipoActivoId,
                        label: response[key].descripcion
                    })
                }
            }
        }).finally(() => {
            this.setState({ tipoActivos })
        })
    }

    async getUserData(id) {
        Api.get({ id }).then((response) => {
            if (response) {
                this.setState({
                    proveedorId: response.proveedorId,
                    nombre: response.persona.nombre,
                    telefono: response.persona.telefono,
                    telefono2: response.persona.telefono2,
                    correo: response.correo,
                    contacto: response.personaContacto,
                    codigo: response.persona.identificacion,
                    direccion: response.persona.direccion,
                    ciudad: response.persona.ciudad,
                    estado: response.persona.estado,
                    pais: response.persona.pais,
                    editing: true,
                    tipoActivo: response.tipoActivo ? {
                        label: response.tipoActivo.descripcion,
                        value: response.tipoActivo.tipoActivoId
                    } : null,
                    tipoProveedor: response.tipoProveedor ? {
                        label: response.tipoProveedor
                    } : {
                        value: 1,
                        label: "Proveedor"
                    }
                })
            }
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    save() {
        this.setState({ disabled: true, isLoading: true })
        const { nombre, contacto, codigo, telefono, correo, pais, estado, ciudad, direccion, tipoActivo, telefono2, tipoProveedor } = this.state;
        const data = {
            correo,
            tipoProveedor: tipoProveedor.label,
            personaContacto: contacto,
            tipoActivo: tipoActivo ? {
                tipoActivoId: tipoActivo.value
            } : null,
            persona: {
                nombre,
                apellido: "",
                identificacion: codigo,
                fechaNacimiento: "",
                sexo: "",
                telefono,
                telefono2,
                direccion,
                pais,
                estado,
                ciudad,
                cargo: ""
            }
        }
        // console.log("🚀 ~ file: CreateSuppliers.js:111 ~ CrearProveedor ~ save ~ data", data)

        Api.save(data).then((response) => {
            // console.log("🚀 ~ file: CreateSuppliers.js:135 ~ CrearProveedor ~ Api.save ~ response", response)
            if (response && response.proveedorId > 0) {
                this.setState({
                    visible: true,
                    message: "Proveedor Agregado",
                })
            } else {
                if (response.warning) {
                    this.setState({
                        warning: true,
                        message: response.message,
                        disabled: false
                    })
                } else {
                    this.setState({ error: true, disabled: false })
                }

            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    update() {
        this.setState({ disabled: true, isLoading: true })
        const { proveedorId, nombre, contacto, codigo, telefono, correo, pais, estado, ciudad, direccion, tipoActivo, tipoProveedor, telefono2 } = this.state;
        const data = {
            proveedorId,
            correo,
            personaContacto: contacto,
            tipoProveedor: tipoProveedor.label,
            tipoActivo: tipoActivo ? {
                tipoActivoId: tipoActivo.value
            } : null,
            persona: {
                nombre,
                apellido: "",
                identificacion: codigo,
                fechaNacimiento: "",
                sexo: "",
                telefono,
                telefono2,
                direccion,
                pais,
                estado,
                ciudad,
                cargo: ""
            }
        }
        Api.update(data).then((response) => {
            if (response) {
                this.setState({
                    visible: response.success,
                    warning: response.warning,
                    message: response.message,
                    disabled: false
                })
            } else {
                this.setState({ error: true, disabled: false })
            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }

    handleSubmit(event, errors, values) {
        //validate select
        if (this.state.tipoProveedorError === "none") {
            this.state.editing ? this.update() : this.save()
        }
    }

    handleInvalidSubmit(event, errors, values) {

    }

    validate() {
        //validate selects
        if (this.state.tipoProveedor === null) {
            this.setState({ tipoProveedorError: "block" }, () => this.form.submit())
        } else {
            this.setState({ tipoProveedorError: "none" }, () => this.form.submit())
        }
    }


    render() {
        const { loading } = this.state;
        if (loading) return <Loading />;
        return (
            <Container menuId="reservas">
                <section className="content">
                    <div className="block-header">
                        <Row>
                            <Col lg={7} md={6} sm={12}>
                                <h2>Proveedor</h2>
                                <Breadcrumb tag="ul" listTag="li" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Inventario</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item App-link active" href="/managesuppliers" tag="a">Proveedores</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="span">Crear Proveedor</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"></Button>
                            </Col>
                        </Row>
                    </div>
                    <Card>
                        <div className="body">
                            <Row className="clearfix">
                                <Col sm={12}>
                                    <AvForm
                                        onValidSubmit={() => this.handleSubmit()}
                                        ref={e => this.form = e}
                                        onInvalidSubmit={() => this.handleInvalidSubmit()}>
                                        <Row className="clearfix">
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Select noOptionsMessage={() => 'Sin Resultados'}
                                                        placeholder={"-- Clasificación Proveedor --"}
                                                        value={this.state.tipoActivo}
                                                        onChange={(selectedOption) => {
                                                            this.setState({ tipoActivo: selectedOption });
                                                        }}
                                                        options={this.state.tipoActivos}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <AvField name="codigo"
                                                        innerRef={e => this.codigoRef = e}
                                                        onKeyUp={(event) => { if (event.keyCode === 13) this.nombreRef.focus() }}
                                                        type="text"
                                                        value={this.state.codigo}
                                                        onChange={(event) => this.setState({ codigo: event.target.value })}
                                                        className="form-control"
                                                        placeholder="Identificación"
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Ingrese el rif del proveedor' },
                                                            pattern: { value: '^[A-z0-9]+$', errorMessage: 'La identificación debe contener solo letras y numeros' },
                                                            maxLength: { value: 15, errorMessage: 'El rif debe tener menos de 15 caracteres' }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="clearfix">
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <AvField name="nombreEmpresa"
                                                        innerRef={e => this.nombreRef = e}
                                                        onKeyUp={(event) => { if (event.keyCode === 13) this.contactoRef.focus() }}
                                                        type="text"
                                                        value={this.state.nombre}
                                                        onChange={(event) => this.setState({ nombre: event.target.value })}
                                                        className="form-control"
                                                        placeholder="Nombre de la Empresa"
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Ingrese nombre o descripción de la empresa' },
                                                            pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'El nombre debe contener solo letras y numeros' },
                                                            maxLength: { value: 50, errorMessage: 'El nombre debe tener menos de 50 caracteres' }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <AvField name="personaContacto"
                                                        innerRef={e => this.contactoRef = e}
                                                        onKeyUp={(event) => { if (event.keyCode === 13) this.correoRef.focus() }}
                                                        type="text"
                                                        value={this.state.contacto}
                                                        onChange={(event) => this.setState({ contacto: event.target.value })}
                                                        className="form-control"
                                                        placeholder="Persona de Contacto"
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Ingrese nombre de la persona de contacto' },
                                                            pattern: { value: '^[A-z0-9À-ž ]+$', errorMessage: 'El nombre debe contener solo letras y numeros' },
                                                            maxLength: { value: 50, errorMessage: 'El nombre debe tener menos de 50 caracteres' }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="clearfix">
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <AvField name="correo"
                                                        innerRef={e => this.correoRef = e}
                                                        onKeyUp={(event) => { if (event.keyCode === 13) this.tipoProveedorRef.focus() }}
                                                        type="text"
                                                        value={this.state.correo}
                                                        onChange={(event) => this.setState({ correo: event.target.value })}
                                                        className="form-control"
                                                        placeholder="Correo electronico"
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Ingrese el correo electronico del proveedor' },
                                                            email: { value: true, errorMessage: 'Ingrese un correo electronico válido' },
                                                            maxLength: { value: 50, errorMessage: 'El correo debe tener menos de 50 caracteres' }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Select noOptionsMessage={() => 'Sin Resultados'}
                                                        styles={{
                                                            container: base => ({
                                                                ...base,
                                                                border: this.state.tipoProveedorError === "block" ? "1px solid #ced4da" : "none",
                                                                borderRadius: ".25rem",
                                                                borderColor: "#dc3545"
                                                            }),
                                                        }}
                                                        ref={ref => { this.tipoProveedorRef = ref; }}
                                                        placeholder={"-- Tipo Proveedor --"}
                                                        value={this.state.tipoProveedor}
                                                        onChange={(selectedOption) => {
                                                            this.setState({ tipoProveedor: selectedOption });
                                                        }}
                                                        options={[{
                                                            value: 1,
                                                            label: "Proveedor"
                                                        }, {
                                                            value: 2,
                                                            label: "Reparación"
                                                        }, {
                                                            value: 3,
                                                            label: "Mantenimiento"
                                                        }, {
                                                            value: 4,
                                                            label: "Instalación"
                                                        }, {
                                                            value: 5,
                                                            label: "Repuestos"
                                                        }]}
                                                        onBlur={() => { this.state.tipoProveedor === null ? this.setState({ tipoProveedorError: "block" }) : this.setState({ tipoProveedorError: "none" }) }}
                                                    />
                                                    <div style={{ display: this.state.tipoProveedorError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione el tipo de Proveedor</div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="clearfix">
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <AvField name="telefono1"
                                                        innerRef={e => this.telefono1Ref = e}
                                                        onKeyUp={(event) => { if (event.keyCode === 13) this.telefono2Ref.focus() }}
                                                        type="text"
                                                        value={this.state.telefono}
                                                        onChange={(event) => this.setState({ telefono: event.target.value })}
                                                        className="form-control"
                                                        placeholder="Teléfono Primario"
                                                        validate={{
                                                            required: { value: false, errorMessage: '' },
                                                            pattern: { value: '^[0-9  -+]+$', errorMessage: 'El telefono contiene caracteres inválidos' },
                                                            maxLength: { value: 50, errorMessage: 'El telefono primario debe tener menos de 50 caracteres' }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <AvField name="telefono2"
                                                        innerRef={e => this.telefono2Ref = e}
                                                        onKeyUp={(event) => { if (event.keyCode === 13) this.direccion.focus() }}
                                                        type="text"
                                                        value={this.state.telefono2}
                                                        onChange={(event) => this.setState({ telefono2: event.target.value })}
                                                        className="form-control"
                                                        placeholder="Teléfono Secundario"
                                                        validate={{
                                                            required: { value: false, errorMessage: '' },
                                                            pattern: { value: '^[0-9  -+]+$', errorMessage: 'El telefono contiene caracteres inválidos' },
                                                            maxLength: { value: 50, errorMessage: 'El telefono secundario debe tener menos de 50 caracteres' }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="clearfix">
                                            <Col sm={12}>
                                                <FormGroup >
                                                    <AvField name="direccion"
                                                        innerRef={e => this.direccion = e}
                                                        onKeyUp={(event) => { if (event.keyCode === 13) this.validate() }}
                                                        type="textarea"
                                                        value={this.state.direccion}
                                                        onChange={(event) => this.setState({ direccion: event.target.value })}
                                                        className="form-control"
                                                        placeholder="Dirección"
                                                        validate={{
                                                            required: { value: false, errorMessage: '' },
                                                            // eslint-disable-next-line
                                                            pattern: { value: '/^[A-z0-9À-ž .()-_\s]+$/m', errorMessage: 'La dirección deben contener solo letras y numeros' },
                                                            maxLength: { value: 150, errorMessage: 'La dirección debe tener menos de 150 caracteres' }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button disabled={this.state.disabled} onClick={() => this.validate()} className="btn  badge-success btn-lg" type="submit">{this.state.isLoading ? <Spinner color="light" size="sm" /> : "Guardar"}</Button>
                                        <Button onClick={() => history.goBack()} className="btn badge-default btn-lg">Cancelar</Button>
                                    </AvForm>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {/* {this.state.message} */}
                        {/* <a
                            // href='/#'
                               href="javascript:location.reload()"
                            className="alert-link"> ¿Crear Otro?</a> */}
                        <Button
                            onClick={() => window.location.reload()}
                            // href="javascript:location.reload()"
                            color="link"
                        >{this.state.message} ¿Crear Otro Preveedor?
                        </Button>
                        {/* <p>
                            {this.state.message}
                        </p> */}
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        No se pudo crear el nuevo proveedor
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                </section>
            </Container>
        )
    }
}