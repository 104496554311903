import React from 'react';
//import Init from "./init"
import Menu from "./components/Menu";
import RightPanel from "./components/RightPanel";

export default class Container extends React.Component {
    render() {
        return (
            <div className="theme-green">
                {/* Overlay For Sidebars */}
                <div className="overlay" />
                {/* Left Sidebar */}
                <aside id="leftsidebar" className="sidebar">
                    <Menu
                        id={this.props.menuId}
                        submenu={this.props.submenu}
                        taskToggled={this.props.taskToggled}
                        assetsToggled={this.props.assetsToggled}
                        inventoryToggled={this.props.inventoryToggled}
                        usersToggled={this.props.usersToggled}
                    />
                </aside>
                {/* <!-- Right Icon menu Sidebar --> */}
                <div className="navbar-right">
                    <RightPanel />
                </div>
                {this.props.children}

            </div>
        )
    }
}