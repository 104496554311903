import React from 'react';
import Loading from "../home/Loading"
import Container from "../Container"
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment"
import "moment/locale/es";
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    Button,
    Row,
    Col,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    FormGroup,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Badge,
    Alert
    , Spinner
} from 'reactstrap';
import Nestable from 'react-nestable';
import Select from 'react-select';
import history from '../history';
import NumberFormat from 'react-currency-format';
import Api from "../modules/plan"
import User from "../modules/user"
import { flatten, randomId, searchTree } from "../util/tree"
import { AvForm, AvField } from 'availity-reactstrap-validation';
import AvGroup from 'availity-reactstrap-validation/lib/AvGroup';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';
import AvFeedback from 'availity-reactstrap-validation/lib/AvFeedback';
import SweetAlert from 'react-bootstrap-sweetalert';
import Proveedor from "../modules/proveedor"
// import proveedor from '../services/proveedor';
const defaultAdditional = {
    page: 1
};

export default class NewMaintenantEvent extends React.Component {
    constructor(props) {
        super(props)
        moment.locale("es"); // it is required to select default locale manually
    }
    state = {
        isLoading: false,
        tecnicos: [],
        tecnicoProveedor: { value: 1, label: "Asignar plan de mantenimiento a" },
        dataProveedor: [],
        loading: true,
        showAdd: false,
        fechaFinalizacion: new Date(),
        isOpen: false,
        showActivityModal: false,
        isDeleting: false,
        msgVisible: false,
        selectedItem: {},
        repuestosAgregados: [{//static respuesto nombre del activo
            id: randomId(),
            text: "",
            value: randomId(),
            label: "",
            activity: [],
            children: []
        }],
        addRepuestos: [],
        repuestoPadre: null,
        repuestos: [],
        checked: "esFecha",
        nombrePlan: "",
        visible: false,
        error: false,
        disabled: false,
        warning: false,
        message: "",
        activoError: "none",
        activo: null,
        repuestoError: "none",
        status: null,
        tipoMantenimiento: null,
        frecuenciaFecha: null,
        unidadActividadLectura: null,
        unidadActividadFecha: null,
        unidadActividadMixto: null,
        unidadActividadMixtoFecha: null,
        frecuenciaLecturaError: "none",
        frecuenicaFechaError: "none",
        frecuenciaMixtoError: "none",
        frecuenciaMixtoFechaError: "none",
        unidadesMedicion: [],
        estados: [
            "Abierto",
            "En Espera",
            "En Progreso",
            "Finalizado",
            "Cancelado"
        ],
        editar: false,
        unidadFecha: [{
            Dias: 0,
            Semanas: 1,
            Meses: 2,
            Años: 3
        }],
        planMantenimientoId: "",
        alert: false,
        frecuencias: false,
        alertTitle: "",
        newActivity: false,
        forDeleting: null,
        editActivity: false,
        alertDelete: false,
    }

    async componentDidMount() {
        const state = this.props.location.state;
        let activos;
        let estado;
        let mantenimiento;
        let tecnicos;
        let proveedorSel;
        let tecnicoProveedor;
        let unidad_fecha = {
            Dias: 0,
            Semanas: 1,
            Meses: 2,
            Años: 3
        }

        if (state && state.event) {
            // console.log(state.event, "state.event")
            const addRepuestos = this.state.repuestosAgregados;//vacio
            // console.log(mantenimientos[0],"mantIndex")
            addRepuestos[0].label = `${state.event.activo} (${state.event.activoCode})`
            addRepuestos[0].text = `${state.event.activo} (${state.event.activoCode})`
            activos = {
                label: `${state.event.activo} (${state.event.activoCode})`,
                value: state.event.activoId
            };
            estado = {
                value: state.event.estado,
                label: this.state.estados[state.event.estado - 1]
            };
            mantenimiento = {
                value: state.event.tipoMantenimiento,
                label: state.event.tipo
            };
            if (state.event.usuario !== null) {
                tecnicos = {
                    value: state.event.usuario.usuarioId,
                    label: state.event.usuario.persona.nombre + " " + state.event.usuario.persona.apellido
                };
                tecnicoProveedor = {
                    value: 1,
                    label: "Técnico"
                };
            }
            if (state.event.proveedor !== null) {
                proveedorSel = {
                    value: state.event.proveedor.usuarioId,
                    label: state.event.proveedor.persona.nombre
                };
                tecnicoProveedor = {
                    value: 2,
                    label: "Proveedor"
                };
            };
            if (state.event.usuario === null && state.event.proveedor === null) {
                tecnicos = {
                    value: "",
                    label: ""
                };
                proveedorSel = {
                    value: "",
                    label: ""
                };
                tecnicoProveedor = {
                    value: 0,
                    label: "Ninguno"
                };
            }


            for (let i = 0; i < state.event.actividades.length; i++) {
                addRepuestos[0].activity.push({
                    id: 1,
                    descripcion: state.event.actividades[i].descripcion,
                    esFecha: state.event.actividades[i].esFecha,
                    esLectura: state.event.actividades[i].esLectura,
                    esMixto: state.event.actividades[i].esMixto,
                    frecuencia: state.event.actividades[i].frecuencia,
                    unidad: state.event.actividades[i].unidad,
                    unidadMedicion: state.event.actividades[i].unidadMedicion,
                    frecuenciaMixto: state.event.actividades[i].frecuenciaMixto,
                    unidadMixto: state.event.actividades[i].unidadMixto,
                    procedimientos: state.event.actividades[i].procedimientos,
                    prioridad: state.event.actividades[i].prioridad,
                    limiteMinimo: state.event.actividades[i].limiteMinimo,
                    limiteMaximo: state.event.actividades[i].limiteMaximo,
                })

                if (state.event.actividades[0]) {
                    let item = state.event.actividades[0];
                    let unidad = [];
                    let unidadesMed = [];
                    //find item unidad into unidadFecha array

                    if (item.unidadMedicion !== null) {
                        unidadesMed.push({
                            value: item.unidadMedicion.unidadMedicionId,
                            label: `${item.unidadMedicion.descripcion} (${item.unidadMedicion.simbolo})`,
                            simbolo: item.unidadMedicion.simbolo
                        });

                    };

                    if (item.esMixto === true) {
                        unidad.push({
                            value: 2,
                            label: item.unidadMixto
                        });
                    }

                    // console.log({
                    //     // selectedItem: item,
                    //     // activiyName: item.descripcion,
                    //     checked: item.esFecha === true ? "esFecha" : item.esLectura === true ? "esLectura" : "esMixto",
                    //     frecuenciaFecha: item.esFecha === true && item.frecuencia,
                    //     unidadActividadFecha: item.esFecha === true && {
                    //         value: unidad_fecha[item.unidad],
                    //         label: item.unidad
                    //     },
                    //     frecuenciaLectura: item.esLectura === true && item.frecuencia,
                    //     unidadActividadLectura: item.esLectura === true && unidadesMed[0],
                    //     frecuenciaMixto: item.esMixto === true && item.frecuencia,
                    //     unidadActividadMixto: item.esMixto === true && unidadesMed[0],
                    //     frecuenciaMixtoFecha: item.esMixto === true && item.frecuenciaMixto,
                    //     unidadActividadMixtoFecha: item.esMixto === true && {
                    //         value: unidad_fecha[item.unidadMixto],
                    //         label: item.unidadMixto
                    //     },
                    //     frecuencias: true
                    //     // procedimientos: item.procedimientos,
                    //     // prioridad: item.prioridad,
                    //     // limiteMinimo: item.limiteMinimo,
                    //     // limiteMaximo: item.limiteMaximo,
                    //     // showActivityModal: true,
                    // })

                    this.setState({
                        // selectedItem: item,
                        // activiyName: item.descripcion,
                        checked: item.esFecha === true ? "esFecha" : item.esLectura === true ? "esLectura" : "esMixto",
                        frecuenciaFecha: item.esFecha === true && item.frecuencia,
                        unidadActividadFecha: item.esFecha === true && {
                            value: unidad_fecha[item.unidad],
                            label: item.unidad
                        },
                        frecuenciaLectura: item.esLectura === true && item.frecuencia,
                        unidadActividadLectura: item.esLectura === true && unidadesMed[0],
                        frecuenciaMixto: item.esMixto === true && item.frecuencia,
                        unidadActividadMixto: item.esMixto === true && unidadesMed[0],
                        frecuenciaMixtoFecha: item.esMixto === true && item.frecuenciaMixto,
                        unidadActividadMixtoFecha: item.esMixto === true && {
                            value: unidad_fecha[item.unidadMixto],
                            label: item.unidadMixto
                        },
                        frecuencias: true
                        // procedimientos: item.procedimientos,
                        // prioridad: item.prioridad,
                        // limiteMinimo: item.limiteMinimo,
                        // limiteMaximo: item.limiteMaximo,
                        // showActivityModal: true,
                    })
                }
            };

            const formatDate = moment(state.event.fecha).format("DD/MM/YYYY")
            const fechahora = moment(formatDate + " " + state.event.hora, "DD/MM/YYYY HH:mm:ss")
            this.setState({
                nombrePlan: state.event.nombre,
                activo: activos,
                status: estado,
                tipoMantenimiento: mantenimiento,
                tecnico: tecnicos,
                fechaFinalizacion: fechahora,
                editar: true,
                repuestosAgregados: addRepuestos,
                planMantenimientoId: state.event.id,
                proveedor: proveedorSel,
                tecnicoProveedor
            });
        }
        this.getTecnicos();
        this.getProveedores();
        await this.getUnidadesMedicion();
       
        // console.log({
        //     checked:this.state.checked,
        //     frecuenciaFecha:this.state.frecuenciaFecha,
        //     unidadActividadFecha:this.state.unidadActividadFecha,
        //     frecuenciaLectura:this.state.frecuenciaLectura,
        //     unidadActividadLectura:this.state.unidadActividadLectura,
        //     frecuenciaMixto:this.state.frecuenciaMixto,
        //     unidadActividadMixto:this.state.unidadActividadMixto,
        //     frecuenciaMixtoFecha:this.state.frecuenciaMixtoFecha,
        //     unidadActividadMixtoFecha:this.state.unidadActividadMixtoFecha,
        //     frecuencias:this.state.frecuencias
        // })
    }

    getTecnicos = () => {
        let tecnicos = []
        User.list({ filtro: 1 }).then((response) => {
            if (response) {
                const res = response.data
                for (const key in res) {
                    tecnicos.push({
                        value: res[key].usuarioId,
                        label: res[key].persona.nombre + " " + res[key].persona.apellido
                    })
                }
            }
        }).finally(() => {
            this.setState({
                tecnicos,
                loading: false
            })
        })
    }

    getProveedores = () => {
        let asignadoa = []
        // const { activo } = this.state;
        Proveedor.listBy({}).then((res) => {
            if (res) {
                for (const key in res) {
                    asignadoa.push({
                        value: res[key].proveedorId,
                        label: res[key].persona.nombre + " " + res[key].persona.apellido
                    })
                }
            }
        }).finally(() => {
            this.setState({
                dataProveedor: asignadoa,
                loading: false
            })
        })
    }

    async getUnidadesMedicion(item) {
        let unidadesMedicion = [];
        Api.listUnidadesMedicion().then((response) => {
            if (response) {
                for (let key in response) {
                    unidadesMedicion.push({
                        value: response[key].unidadMedicionId,
                        label: `${response[key].descripcion} (${response[key].simbolo})`,
                        simbolo: response[key].simbolo
                    })
                }
            }
        }).finally(() => {
            this.setState({ unidadesMedicion, loading: false })
        })
    }

    renderItem = ({ item, index }) => { 

        return (
            <div
                className="dd-handle"
                onClick={() => {
                    !this.state.isDeleting ?
                        this.setState({
                            selectedItem: item,
                            activiyName: item.descripcion,
                            procedimientos: item.procedimientos,
                            prioridad: item.prioridad,
                            limiteMinimo: item.limiteMinimo,
                            limiteMaximo: item.limiteMaximo,
                            showActivityModal: true,
                            forDeleting: index,
                            editActivity: true,
                        }) :
                        this.setState({
                            forDeleting: index,
                            alertDelete: true,
                        })
                        
                }}
                >
                {`${item.descripcion}`}
            </div>
        )
    }

    actividad() {
        if (!this.state.frecuencias) {
            this.setState({ error: false, disabled: false, warning: true, isLoading: false, message: "Debes seleccionar una frecuencia", isDeleting: false })
        } else {
            this.setState({
                showActivityModal: true,
                newActivity: true,
                activiyName: "",
                procedimientos: "",
                prioridad: "",
                limiteMinimo: "",
                limiteMaximo: "",
                isDeleting: false
            })
        }
    }

    deleteActivity = (index) => {
        console.log('deleteActivity')

        const { repuestosAgregados } = this.state;
        const r = repuestosAgregados[0];
        console.log("🚀 ~ file: NewMaintenantEvent.js:416 ~ NewMaintenantEvent ~ deleteActivity ~ r:",r.activity)
        r.activity.splice(index, 1);
        this.setState({
            repuestosAgregados,
            editActivity: false,
            alertDelete: false,
            // forDeleting: null,
        })

    }

    onOrderChange = (items, index) => {
        console.log('onOrderChange')
        const data=[{
            activity:{items}
        }]
        this.setState({
            repuestosAgregados: data
        });
    }

    addParte = () => {
        let { descripcion, repuestoPadre, repuestosAgregados, repuestos } = this.state;
        console.log()
        let o = {
            id: randomId(),
            text: descripcion,
            value: randomId(),
            label: descripcion,
            activity: [],
            children: []
        }
        if (repuestoPadre !== null) {
            const root = [{ children: repuestosAgregados }]
            const padre = searchTree(root[0], repuestoPadre.id)
            padre.parent = repuestoPadre.id
            if (padre) {
                if (padre.hasOwnProperty('children')) {
                    padre.children.push(o)
                } else {
                    padre.children = [o]
                }
            }
        } else {
            repuestosAgregados.push(o)
        }
        repuestos.push(o)
        this.setState({
            repuestos,
            repuestoPadre: null,
            descripcion: "",
            repuestosAgregados,
            showAdd: false
        })
    }

     addActividad = () => {
        const {  repuestosAgregados, activiyName, checked, frecuenciaLectura, frecuenciaFecha, frecuenciaMixtoFecha, frecuenciaValueMixtoFecha, unidadActividadFecha, unidadActividadLectura, unidadActividadMixto, procedimientos, unidadActividadMixtoFecha,forDeleting } = this.state;
        const newRepuestosArray = [...repuestosAgregados];
        const item = newRepuestosArray;
        const selecteIndex = forDeleting === null ? item[0].activity.length : forDeleting;

        const newItem = {
            descripcion: activiyName,
            procedimientos,
            prioridad: 1,
            limiteMinimo: 0,
            limiteMaximo: 0,
            esFecha: checked === "esFecha",
            esLectura: checked === "esLectura",
            esMixto: checked === "esMixto",
            frecuencia: checked === "esFecha" ? frecuenciaFecha : checked === "esLectura" ? frecuenciaLectura : frecuenciaMixtoFecha,
            unidad: checked === "esFecha" ? unidadActividadFecha.label : checked === "esLectura" ? unidadActividadLectura.simbolo : unidadActividadMixto.simbolo,
            unidadMedicion: checked === "esFecha" ? null : checked === "esLectura" ? { unidadMedicionId: unidadActividadLectura.value } : { unidadMedicionId: unidadActividadMixto.value },
            frecuenciaMixto: checked === "esMixto" ? frecuenciaValueMixtoFecha : null,
            unidadMixto: checked === "esMixto" ? unidadActividadMixtoFecha.label : null,
        };

        // console.log(newItem)

        //add new item to array position selecteIndex
        item[0].activity.splice(selecteIndex, 0, newItem);
       
        //remove old item from array


        // item[0].activity.push()

        this.setState({
            selectedItem: {},
            activiyName: "",
            procedimientos: "",
            repuestosAgregados: item,
            showActivityModal: false,
            forDeleting: null,
            newActivity: false,
        })

    }

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    loadOptions = async (search, prevOptions, { page }) => {
        if (search === "") search = "a";
        let activos = []
        const res = await Api.listActivoBySearch({ search, page, filtro: 1 });
        const response = res.data
        const totalPages = res.totalPages;
        const currentPage = res.currentPage;
        if (response) {
            for (let key in response) {
                activos.push({
                    label: `${response[key].nombre} (${response[key].codigo})`,
                    value: response[key].activoId
                })
            }
        }
        const results = {
            options: activos,
            hasMore: currentPage !== totalPages,
            additional: {
                page: page + 1
            }
        }
        return results;
    };

    onSearchChange = (selectedOption) => {
        console.log(selectedOption)
        if (selectedOption) {
            let { repuestosAgregados } = this.state;
            repuestosAgregados[0].label = selectedOption.label
            repuestosAgregados[0].text = selectedOption.label
            this.setState({
                activo: selectedOption,
                repuestosAgregados,
            });
        }
    };

    onSave() {
        this.setState({ disabled: true, isLoading: true })
        const { repuestosAgregados, tecnico, fechaFinalizacion, nombrePlan,
            tipoMantenimiento, activo, status, proveedor } = this.state
        const root = { children: repuestosAgregados }
        const repuestosArray = flatten(root);
        // console.log({repuestosArray})
        const partes = []
        for (const key in repuestosArray) {
            const r = repuestosArray[key];
            partes.push({
                descripcion: r.label,
                internoId: r.id,
                padreId: r.parent,
                level: r.level,
                actividades: r.activity
            })
        }
        const data = {
            descripcion: nombrePlan,
            activo: { activoId: activo.value, descripcion: activo.label },
            status: status.value,
            tipoMantenimiento: tipoMantenimiento.value,
            tecnico: tecnico ? {
                usuarioId: tecnico.value
            } : null,
            proveedor: proveedor ? {
                proveedorId: proveedor.value
            } : null,
            fecha: moment(fechaFinalizacion).format("YYYY-MM-DD"),
            hora: moment(fechaFinalizacion).format("HH:mm:ss"),
            partes,
        }
        // console.log(data,"data")
        Api.save(data).then((response) => {
            if (response && response.planMantenimientoId > 0) {
                this.setState({
                    visible: response.success,
                    warning: response.warning,
                    message: response.message,
                    disabled: !response.warning
                })
            } else {
                this.setState({ error: response.success, disabled: !response.warning, warning: response.warning, message: response.message, })
            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    onEdit() {
        this.setState({ disabled: true, isLoading: true })
        const { repuestosAgregados, tecnico, fechaFinalizacion, nombrePlan, tipoMantenimiento, activo, status, proveedor } = this.state
        const { checked,frecuenciaLectura, frecuenciaFecha,frecuenciaMixto,  frecuenciaMixtoFecha, unidadActividadFecha, unidadActividadLectura, unidadActividadMixto, unidadActividadMixtoFecha } = this.state;
        const root = { children: repuestosAgregados }
        const repuestosArray = flatten(root);
        const partes = []
        const frecuencia = {
            esFecha: checked === "esFecha",
            esLectura: checked === "esLectura",
            esMixto: checked === "esMixto",
            frecuencia: checked === "esFecha" ? frecuenciaFecha : checked === "esLectura" ? frecuenciaLectura : frecuenciaMixto,
            unidad: checked === "esFecha" ? unidadActividadFecha.label : checked === "esLectura" ? unidadActividadLectura.simbolo : unidadActividadMixto.simbolo,
            unidadMedicion: checked === "esFecha" ? null : checked === "esLectura" ? { unidadMedicionId: unidadActividadLectura.value } : { unidadMedicionId: unidadActividadMixto.value },
            frecuenciaMixto: checked === "esMixto" ? frecuenciaMixtoFecha : null,
            unidadMixto: checked === "esMixto" ? unidadActividadMixtoFecha.label : null,
        }
        console.log("🚀 ~ file: NewMaintenantEvent.js:624 ~ NewMaintenantEvent ~ onEdit ~ frecuencia:", frecuencia)
        //update all activities in repuestosArray.actividades with frecuencia
        let newRepuestosArray = repuestosArray.map((r) => {
            if (r.activity) {
                r.activity = r.activity.map((a) => {
                    let data = {
                        ...a,
                        ...frecuencia
                    }
                    return data
                })
            }
            return r
        })


        for (const key in newRepuestosArray) {
            const r = newRepuestosArray[key];
            partes.push({
                descripcion: r.label,
                internoId: r.id,
                padreId: r.parent,
                level: r.level,
                actividades: r.activity.length > 0 ? r.activity : null
            })
        }


        const data = {
            planMantenimientoId: this.state.planMantenimientoId,
            descripcion: nombrePlan,
            activo: { activoId: activo.value, descripcion: activo.label },
            status: status.value,
            tipoMantenimiento: tipoMantenimiento.value,
            tecnico: tecnico ? {
                usuarioId: tecnico.value
            } : null,
            proveedor: proveedor ? {
                proveedorId: proveedor.value
            } : null,
            fecha: moment(fechaFinalizacion).format("YYYY-MM-DD"),
            hora: moment(fechaFinalizacion).format("HH:mm:ss"),
            partes: partes
        }
        // console.log(data,"data")
        if (repuestosArray[0].activity.length === 0) {
            this.setState({ error: false, disabled: false, warning: true, isLoading: false, message: "Debe agregar al menos una actividad" })
        } else {
            Api.edit(data).then((response) => {
                console.log(response)
                if (response) {
                    this.setState({
                        visible: response.success,
                        warning: response.warning,
                        message: response.message,
                        disabled: !response.warning
                    },
                        () => history.push("events")
                    )

                } else {
                    this.setState({ error: true, disabled: false })
                }
            }).finally(() => {
                this.setState({ isLoading: false })
            })
        }
    }

    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }

    validate() {
        //validate selects
        const { status, tipoMantenimiento, activo, repuestosAgregados } = this.state;
        this.setState({
            activoError: activo === null ? "block" : "none",
            estadoMantError: status === null ? "block" : "none",
            tipoMantError: tipoMantenimiento === null ? "block" : "none",
            tipoSelectError: tipoMantenimiento === null ? "block" : "none",
            repuestoError: repuestosAgregados.length === 0 ? "block" : "none"
        },
            () => this.form.submit())
    }

    handleSubmit(event, errors, values) {
        //validate select
        const { activoError, estadoMantError, tipoMantError, repuestoError } = this.state;
        if (activoError === "none" && estadoMantError === "none" && tipoMantError === "none" && repuestoError === "none") {
            // if(this.state.tecnicoProveedor.value !== -1){
            // console.log("🚀 ~ file: NewMaintenantEvent.js:696 ~ NewMaintenantEvent ~ handleSubmit ~ this.state.tecnicoProveedor", this.state.tecnicoProveedor)
            this.state.editar ? this.onEdit() : this.onSave()
            // }else{
            //     this.setState({
            //         tipoSelectError: "block",
            //         warning: true,
            //         message: "Debe seleccionar a quien va dirigido el mantenimiento"
            //     })
            // }
        }
    }

    handleInvalidSubmit(event, errors, values) {

    }

    validateActivity = () => {
        //validate selects
        const { checked, unidadActividadFecha, unidadActividadMixto, unidadActividadLectura, unidadActividadMixtoFecha } = this.state;
        if (checked !== "") {
            if (checked === "esLectura" && unidadActividadLectura === "") {
                this.setState({
                    alert: true,
                    alertTitle: "Debes seleccionar una unidad de lectura",
                })
                return
            } else {
                if (checked === "esFecha" && unidadActividadFecha === "") {
                    this.setState({
                        alert: true,
                        alertTitle: "Debes seleccionar una unidad de fecha",
                    })
                    return
                } else {
                    if ((checked === "esMixto" && unidadActividadMixto === "") || (checked === "esMixtoFecha" && unidadActividadMixtoFecha === "")) {
                        this.setState({
                            alert: true,
                            alertTitle: "Debes seleccionar una unidad de mixto",
                        })
                        return
                    } else {
                        console.log('entro')
                        this.state.editActivity && this.deleteActivity(this.state.forDeleting)
                        this.setState({
                            frecuenciaLecturaError: checked === "esLectura" && unidadActividadLectura === null ? "block" : "none",
                            frecuenicaFechaError: checked === "esFecha" && unidadActividadFecha === null ? "block" : "none",
                            frecuenciaMixtoError: checked === "esMixto" && unidadActividadMixto === null ? "block" : "none",
                            frecuenciaMixtoFechaError: checked === "esMixto" && unidadActividadMixtoFecha === null ? "block" : "none",
                            editActivity: false,
                            newActivity: true,
                        },
                            () => this.formActivity.submit() )
                    }
                }
            }
        } else {
            this.setState({
                alert: true,
                alertTitle: "Debes seleccionar una frecuencia para continuar"
            })
        }
    };

    submitActivity() {
        const { frecuenciaLecturaError, frecuenicaFechaError, frecuenciaMixtoError, frecuenciaMixtoFechaError } = this.state;
        if (frecuenciaLecturaError === "none" && frecuenicaFechaError === "none" && frecuenciaMixtoError === "none" && frecuenciaMixtoFechaError === "none") {
            this.addActividad()
        }
    }

    render() {
        const { loading } = this.state;
        if (loading) return <Loading />;
        return (
            <Container menuId="tareas">
                <section className="content">
                    <div className="block-header">
                        <Row className="row">
                            <Col lg={12} md={12} sm={12} >
                                <h2>Nuevo Plan de Mantenimiento</h2>
                                <Breadcrumb tag="ul" listTag="li" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Tareas</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item App-link active" tag="a" href="/calendarevents">Planes de Mantenimientos</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="a">Nuevo Plan de Mantenimiento</BreadcrumbItem>
                                </Breadcrumb>
                                <Button className="btn btn-primary btn-icon btn-icon-mini mobile_menu" type="button"></Button>
                            </Col>
                        </Row>
                    </div>
                    <AvForm onValidSubmit={() => this.handleSubmit()}
                        ref={e => this.form = e}
                        onInvalidSubmit={() => this.handleInvalidSubmit()}>
                        <Card className="card">
                            <div className="body">
                                <div className="header">
                                    <h2 className="card-inside-title">Datos Básicos</h2>
                                    {/* <FormGroup>
                                        <label>Seleccione a quien va dirigida:</label>
                                        <Select noOptionsMessage={() => 'Sin Resultados'}
                                            placeholder={"Seleccione a quien va dirigida"}
                                            value={this.state.tecnicoProveedor}
                                            onChange={(tecnicoProveedor) => this.setState({ tecnicoProveedor })}
                                            options={[
                                                { value: 0, label: "Ninguno" },
                                                { value: 1, label: "Técnico" },
                                                { value: 2, label: "Proveedor" }
                                            ]}
                                            styles={{
                                                container: base => ({
                                                    ...base,
                                                    border: this.state.tipoSelectError === "block" ? "1px solid #ced4da" : "none",
                                                    borderRadius: ".25rem",
                                                    borderColor: "#dc3545"
                                                })
                                            }}

                                        />
                                    </FormGroup> */}
                                </div>
                                <Row className="row clearfix">
                                    <Col md={12}>
                                        <FormGroup>
                                            <label>Nombre del Plan</label>
                                            <AvField onKeyUp={(event) => { if (event.keyCode === 13) this.nombreRef.focus() }}
                                                type="text"
                                                value={this.state.nombrePlan}
                                                onChange={(event) => this.setState({ nombrePlan: event.target.value })}
                                                name="planName"
                                                id="planName"
                                                placeholder="Nombre del Plan"
                                                validate={{
                                                    required: { value: true, errorMessage: 'Ingrese nombre del plan' },
                                                    pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'El nombre debe contener solo letras y numeros' },
                                                    maxLength: { value: 50, errorMessage: 'El nombre debe tener menos de 50 caracteres' }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="row clearfix">
                                    <Col md={12}>
                                        <FormGroup>
                                            <label>Seleccione un activo:</label>
                                            <AsyncPaginate noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.activoError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                additional={defaultAdditional}
                                                refs={ref => { this.activoRef = ref; }}
                                                placeholder={"Escriba para buscar activo"}
                                                loadingMessage={() => "Buscando activo.."}
                                                cacheOptions
                                                value={this.state.activo}
                                                onChange={(e) => { this.onSearchChange(e); }}
                                                loadOptions={this.loadOptions}
                                                defaultOptions
                                                onInputChange={this.handleInputChange}
                                                onBlur={() => { this.state.activo === null ? this.setState({ activoError: "block" }) : this.setState({ activoError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.activoError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione un activo</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="row clearfix">
                                    <Col sm={6}>
                                        <FormGroup>
                                            <label>Seleccione un estado:</label>
                                            <Select noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.estadoMantError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                ref={ref => { this.estadoMantRef = ref; }}
                                                placeholder={"Seleccione estado del Mantenimiento"}
                                                value={this.state.status}
                                                onChange={(status) => this.setState({ status })}
                                                options={[
                                                    { value: 1, label: "Abierto" },
                                                    { value: 2, label: "En Espera" },
                                                    { value: 3, label: "En Progreso" },
                                                    { value: 4, label: "Finalizado" },
                                                    { value: 5, label: "Cancelado" },
                                                ]}
                                                onBlur={() => { this.state.status === null ? this.setState({ estadoMantError: "block" }) : this.setState({ estadoMantError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.estadoMantError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione estado del Mantenimiento</div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <label>Seleccione tipo de mantenimiento:</label>
                                            <Select noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.tipoMantError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                placeholder={"Seleccione tipo del Mantenimiento"}
                                                value={this.state.tipoMantenimiento}
                                                onChange={(tipoMantenimiento) => this.setState({ tipoMantenimiento })}
                                                options={[
                                                    { value: 1, label: "Preventivo" },
                                                    { value: 2, label: "Correctivo" },
                                                    { value: 3, label: "Predictivo" },
                                                    { value: 4, label: "Emergencia" },
                                                ]}
                                                onBlur={() => { this.state.tipoMantenimiento === null ? this.setState({ tipoMantError: "block" }) : this.setState({ tipoMantError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.tipoMantError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione un tipo de Mantenimiento</div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <label>Asignado a:</label>
                                        <FormGroup>
                                            {this.state.tecnicoProveedor.value === 0 ?
                                                <Select placeholder={"Ninguno"} isDisabled /> :
                                                this.state.tecnicoProveedor.value === 1 ?
                                                    <Select noOptionsMessage={() => 'Sin Resultados'}
                                                        styles={{
                                                            container: base => ({
                                                                ...base,
                                                                border: this.state.tipoMantError === "block" ? "1px solid #ced4da" : "none",
                                                                borderRadius: ".25rem",
                                                                borderColor: "#dc3545"
                                                            }),
                                                        }}
                                                        placeholder={"Seleccione Técnico Asignado"}
                                                        value={this.state.tecnico}
                                                        onChange={(tecnico) => this.setState({ tecnico })}
                                                        options={this.state.tecnicos}
                                                        isDisabled={this.state.tecnicoProveedor.value === 0}
                                                    /> :
                                                    this.state.tecnicoProveedor.value === 2 &&
                                                    <Select noOptionsMessage={() => 'Sin Resultados'}
                                                        styles={{
                                                            container: base => ({
                                                                ...base,
                                                                border: this.state.tipoMantError === "block" ? "1px solid #ced4da" : "none",
                                                                borderRadius: ".25rem",
                                                                borderColor: "#dc3545"
                                                            }),
                                                        }}
                                                        placeholder={"Seleccione Proveedor Asignado"}
                                                        value={this.state.proveedor}
                                                        onChange={(proveedor) => this.setState({ proveedor })}
                                                        options={this.state.dataProveedor}
                                                        isDisabled={this.state.tecnicoProveedor.value === 0}
                                                    />}
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <label>Seleccione una fecha:</label>
                                        <div className="input-group" onClick={() => this.setState({ isOpen: true })} >
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="zmdi zmdi-calendar" /></span>
                                            </div>
                                            <Input
                                                type="text"
                                                value={moment(this.state.fechaFinalizacion).format("DD/MM/YYYY HH:mm")}
                                                className="form-control datetimepicker"
                                                placeholder="Fecha de Finalización"
                                                onChange={() => console.log('')}
                                            />
                                        </div>
                                        <div style={{ display: "none" }}>
                                            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={"es"}>
                                                <DateTimePicker
                                                    value={this.state.fechaFinalizacion}
                                                    disablePast
                                                    open={this.state.isOpen}
                                                    onOpen={() => this.setState({ isOpen: true })}
                                                    onClose={() => this.setState({ isOpen: false })}
                                                    onChange={(date) => this.setState({ fechaFinalizacion: date })}
                                                    label="Fecha de Finalización"
                                                    showTodayButton
                                                    cancelLabel={"Cancelar"}
                                                    okLabel={"Aceptar"}
                                                    todayLabel={"HOY"}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </Card>
                        <Card className="card">
                            <div className="body">
                                <Row tag="fieldset" className="clearfix">
                                    <Col sm={12}>
                                        <legend className="col-form-label">Frecuencias</legend>
                                    </Col>
                                </Row>
                                <Row className="clearfix" >
                                    <Col sm={2}>
                                        <FormGroup className={"radio"}>
                                            <Input type="radio" name="radio1" id="radio1" value="fecha" checked={this.state.checked === 'esFecha'} onChange={() => this.setState({ checked: "esFecha" })} />
                                            <Label for="radio1">Fecha</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={5}>
                                        <FormGroup>
                                            <AvGroup>
                                                <AvInput tag={NumberFormat}
                                                    name="frecuenciaFecha"
                                                    value={this.state.frecuenciaFecha}
                                                    customInput={Input}
                                                    // thousandSeparator={false}
                                                    allowNegative={false}
                                                    prefix={''}
                                                    placeholder="Ej. cada 2 semanas"
                                                    disabled={this.state.checked !== 'esFecha'}
                                                    onValueChange={(values) => {
                                                        const { formattedValue, value } = values;
                                                        this.setState({ frecuenciaFecha: formattedValue, frecuenciaValueFecha: value })
                                                        if (formattedValue === "") {
                                                            this.setState({ frecuencias: false })
                                                        } else {
                                                            this.setState({ frecuencias: true })
                                                        }
                                                    }}
                                                    validate={{
                                                        required: { value: this.state.checked === 'esFecha', errorMessage: 'Ingrese existencia actual' },
                                                    }}
                                                />
                                                <AvFeedback>Ingrese frecuencia de la actividad</AvFeedback>
                                            </AvGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={5}>
                                        <FormGroup>
                                            <Select noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.checked === 'esFecha' && this.state.frecuenicaFechaError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                isDisabled={this.state.checked !== 'esFecha'}
                                                placeholder={"Unidad"}
                                                value={this.state.unidadActividadFecha}
                                                onChange={(unidadActividadFecha) => {
                                                    this.setState({ unidadActividadFecha });
                                                    if (unidadActividadFecha !== null) {
                                                        this.setState({ frecuencias: true })
                                                    } else {
                                                        this.setState({ frecuencias: false })
                                                    }
                                                }}
                                                options={[
                                                    { value: 0, label: "Dias" },
                                                    { value: 1, label: "Semanas" },
                                                    { value: 2, label: "Meses" },
                                                    { value: 3, label: "Años" }
                                                ]}
                                                onBlur={() => { this.state.checked === 'esFecha' && this.state.unidadActividadFecha === null ? this.setState({ frecuenicaFechaError: "block" }) : this.setState({ frecuenicaFechaError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.frecuenicaFechaError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione la unidad de la frecuencia</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="clearfix" >
                                    <Col sm={2}>
                                        <FormGroup className={"radio"}>
                                            <Input type="radio" name="radio1" id="radio2" value="lecturas" checked={this.state.checked === 'esLectura'} onChange={() => this.setState({ checked: "esLectura" })} />
                                            <Label for="radio2">Lecturas</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={5}>
                                        <FormGroup>
                                            <AvGroup>
                                                <AvInput tag={NumberFormat}
                                                    name="frecuenciaLectura"
                                                    value={this.state.frecuenciaLectura}
                                                    customInput={Input}
                                                    // thousandSeparator={false}
                                                    allowNegative={false}
                                                    prefix={''}
                                                    placeholder="Frecuencia"
                                                    disabled={this.state.checked !== 'esLectura'}
                                                    onValueChange={(values) => {
                                                        const { formattedValue, value } = values;
                                                        this.setState({ frecuenciaLectura: formattedValue, frecuenciaValueLectura: value })
                                                        if (formattedValue === "") {
                                                            this.setState({ frecuencias: false })
                                                        } else {
                                                            this.setState({ frecuencias: true })
                                                        }
                                                    }}
                                                    validate={{
                                                        required: { value: this.state.checked === 'esLectura', errorMessage: 'Ingrese existencia actual' },
                                                    }}
                                                />
                                                <AvFeedback>Ingrese frecuencia de la actividad</AvFeedback>
                                            </AvGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={5}>
                                        <FormGroup>
                                            <Select noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.checked === 'esLectura' && this.state.frecuenciaLecturaError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                isDisabled={this.state.checked !== 'esLectura'}
                                                placeholder={"Unidad"}
                                                value={this.state.unidadActividadLectura}
                                                onChange={(unidadActividadLectura) => {
                                                    this.setState({ unidadActividadLectura })
                                                    if (unidadActividadLectura !== null) {
                                                        this.setState({ frecuencias: true })
                                                    } else {
                                                        this.setState({ frecuencias: false })
                                                    }
                                                }}
                                                options={this.state.unidadesMedicion}
                                                onBlur={() => { this.state.checked === 'esLectura' && this.state.unidadActividadLectura === null ? this.setState({ frecuenciaLecturaError: "block" }) : this.setState({ frecuenciaLecturaError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.frecuenciaLecturaError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione la unidad de la frecuencia</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="clearfix">
                                    <Col sm={2}>
                                        <FormGroup className={"radio"}>
                                            <Input type="radio" name="radio1" id="radio3" value="mixto" checked={this.state.checked === 'esMixto'} onChange={() => this.setState({ checked: "esMixto" })} />
                                            <Label for="radio3">Mixto</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={5}>
                                        <FormGroup>
                                            <AvGroup>
                                                <AvInput tag={NumberFormat}
                                                    name="frecuenciaMixto"
                                                    value={this.state.frecuenciaMixto}
                                                    customInput={Input}
                                                    // thousandSeparator={false}
                                                    prefix={''}
                                                    placeholder="Frecuencia"
                                                    disabled={this.state.checked !== 'esMixto'}
                                                    onValueChange={(values) => {
                                                        const { formattedValue, value } = values;
                                                        this.setState({ frecuenciaMixto: formattedValue, frecuenciaValueMixto: value })
                                                        if (formattedValue === "") {
                                                            this.setState({ frecuencias: false })
                                                        } else {
                                                            this.setState({ frecuencias: true })
                                                        }
                                                    }}
                                                    validate={{
                                                        required: { value: this.state.checked === 'esMixto', errorMessage: 'Ingrese frecuencia de lectura' },
                                                    }}
                                                />
                                                <AvFeedback>Ingrese frecuencia de la actividad</AvFeedback>
                                            </AvGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={5}>
                                        <FormGroup>
                                            <Select noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.checked === 'esMixto' && this.state.frecuenciaMixtoError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                isDisabled={this.state.checked !== 'esMixto'}
                                                placeholder={"Unidad"}
                                                value={this.state.unidadActividadMixto}
                                                onChange={(unidadActividadMixto) => {
                                                    this.setState({ unidadActividadMixto })
                                                    if (unidadActividadMixto !== null) {
                                                        this.setState({ frecuencias: true })
                                                    } else {
                                                        this.setState({ frecuencias: false })
                                                    }
                                                }}
                                                options={this.state.unidadesMedicion}
                                                onBlur={() => { this.state.checked === 'esMixto' && this.state.unidadActividadMixto === null ? this.setState({ frecuenciaMixtoError: "block" }) : this.setState({ frecuenciaMixtoError: "none" }) }} nBlur={() => { this.state.checked === 'esMixto' && this.state.unidadActividadMixto === null ? this.setState({ frecuenciaMixtoError: "block" }) : this.setState({ frecuenciaMixtoError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.frecuenciaMixtoError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione la unidad de la frecuencia</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={2}>
                                        <Badge color="info">* Lo que suceda<br />primero</Badge>
                                    </Col>
                                    <Col sm={5}>
                                        <FormGroup>
                                            <AvGroup>
                                                <AvInput tag={NumberFormat}
                                                    name="frecuenciaMixtoFecha"
                                                    value={this.state.frecuenciaMixtoFecha}
                                                    customInput={Input}
                                                    // thousandSeparator={false}
                                                    prefix={''}
                                                    placeholder="O cada"
                                                    disabled={this.state.checked !== 'esMixto'}
                                                    onValueChange={(values) => {
                                                        const { formattedValue, value } = values;
                                                        this.setState({ frecuenciaMixtoFecha: formattedValue, frecuenciaValueMixtoFecha: value })
                                                        if (formattedValue === "") {
                                                            this.setState({ frecuencias: false })
                                                        } else {
                                                            this.setState({ frecuencias: true })
                                                        }
                                                    }}
                                                    validate={{
                                                        required: { value: this.state.checked === 'esMixto', errorMessage: 'Ingrese frecuencia' },
                                                    }}
                                                />
                                                <AvFeedback>Ingrese frecuencia de la actividad</AvFeedback>
                                            </AvGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={5}>
                                        <FormGroup>
                                            <Select noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.checked === 'esMixto' && this.state.frecuenciaMixtoFechaError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                isDisabled={this.state.checked !== 'esMixto'}
                                                placeholder={"Unidad"}
                                                value={this.state.unidadActividadMixtoFecha}
                                                onChange={(unidadActividadMixtoFecha) => {
                                                    this.setState({ unidadActividadMixtoFecha })
                                                    if (unidadActividadMixtoFecha !== null) {
                                                        this.setState({ frecuencias: true })
                                                    } else {
                                                        this.setState({ frecuencias: false })
                                                    }
                                                }}
                                                options={[
                                                    { value: 0, label: "Dias" },
                                                    { value: 1, label: "Semanas" },
                                                    { value: 2, label: "Meses" },
                                                    { value: 3, label: "Años" }
                                                ]}
                                                onBlur={() => { this.state.checked === 'esMixto' && this.state.unidadActividadMixtoFecha === null ? this.setState({ frecuenciaMixtoFechaError: "block" }) : this.setState({ frecuenciaMixtoFechaError: "none" }) }} nBlur={() => { this.state.checked === 'esMixto' && this.state.unidadActividadMixtoFecha === null ? this.setState({ frecuenciaMixtoFechaError: "block" }) : this.setState({ frecuenciaMixtoFechaError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.frecuenciaMixtoFechaError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione la unidad de la frecuencia</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                        <Card className="card">
                            <div className="body">
                                <div className="header">
                                    <h2 className="card-inside-title">Actividades</h2>
                                    <ul className="header-dropdown">
                                        <li > <Button role="button" color="link" onClick={() => this.actividad()}><i className="zmdi zmdi-plus" /></Button> </li>{/* Agregar Repuesto/Material */}
                                        {!this.state.isDeleting && <li > <Button onClick={() => this.setState({ isDeleting: true, msgVisible: true })} role="button" color="link"><i className="zmdi zmdi-delete" /></Button> </li>}{/* Cancelar eliminar */}
                                        {this.state.isDeleting && <li > <Button onClick={() => this.setState({ isDeleting: false })} role="button" color="link"><i className="zmdi zmdi-close-circle" /></Button> </li>}{/* Eliminar Repuesto seleccionado */}
                                    </ul>
                                </div>
                                <div style={{ display: this.state.repuestoError, marginBottom: "1rem" }} className="invalid-feedback">Ingrese por lo menos una actividad</div>
                                <Nestable
                                    collapsed={false}
                                    group={"repuestos"}
                                    idProp={items => items.id}
                                    ref={(ref) => this.nestable = ref}
                                    items={this.state.repuestosAgregados[0].activity}
                                    renderItem={this.renderItem}
                                    onChange={this.onOrderChange}
                                    renderCollapseIcon={({ isCollapsed }) => <i className="zmdi zmdi-plus" />}
                                />
                            </div>
                        </Card>
                        {this.state.editar ? <Button color="primary" size="lg" disabled={this.state.disabled} onClick={() => this.validate()}>{this.state.isLoading ? <Spinner color="light" size="sm" /> : "Modificar"}</Button> : <Button color="success" size="lg" disabled={this.state.disabled} onClick={() => this.validate()}>{this.state.isLoading ? <Spinner color="light" size="sm" /> : "Guardar"}</Button>}
                        <Button color="default" size="lg" onClick={() => history.goBack()} >Cancelar</Button>
                    </AvForm>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {this.state.message}
                        {/* eslint-disable-next-line */}
                         <a href="javascript:location.reload()" className="alert-link"> ¿Crear Otro Plan de Mantenimiento?</a>
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        Error al guardar
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                </section>
                <SweetAlert
                    show={this.state.msgVisible}
                    title="Seleccione las actividades que desea eliminar"
                    type={"info"}
                    showCloseButton
                    onCancel={() => this.setState({ msgVisible: false })}
                    customButtons={
                        <React.Fragment>
                            <Button color="primary" onClick={() => this.setState({ msgVisible: false })}>Ok</Button>
                            <Button color="secondary" onClick={() => this.setState({ msgVisible: false, isDeleting:false })}>Cerrar</Button>
                        </React.Fragment>
                    }
                />
                               <SweetAlert
                    show={this.state.alertDelete}
                    title={"¿Desea eliminar la actividad?"}
                    type={"warning"}
                    showCloseButton
                    onCancel={() => this.setState({ alertDelete: false })}
                    customButtons={
                        <React.Fragment>
                            <Button color="secondary" onClick={() => this.setState({ alertDelete: false })}>Cancelar</Button>
                            <Button color="primary" onClick={() => this.deleteActivity(this.state.forDeleting)}>Eliminar</Button>
                        </React.Fragment>
                    }
                />
                <Modal className="modal-lg" isOpen={this.state.showAdd} toggle={() => this.setState({ showAdd: false })} >
                    <ModalBody >
                        <AvForm onValidSubmit={this.addParte}
                            ref={e => this.formPartes = e}>
                            <Card>
                                <div className="body">
                                    <Row className="clearfix">
                                        <Col md={12}>
                                            <FormGroup>
                                                <AvField name="parte"
                                                    value={this.state.descripcion}
                                                    onChange={(event) => this.setState({ descripcion: event.target.value })}
                                                    type="text"
                                                    placeholder="Descripción"
                                                    validate={{
                                                        required: { value: true, errorMessage: 'Ingrese una descripción para la parte' },
                                                        pattern: { value: '^[A-z0-9À-ž .,:-\n]+$', errorMessage: 'La descripción debe contener solo letras y numeros' },
                                                        maxLength: { value: 50, errorMessage: 'La descripción debe tener menos de 50 caracteres' }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="clearfix">
                                        <Col sm={4}>
                                            <div className="checkbox">
                                                <input id="procheck1" type="checkbox" defaultChecked />
                                                <label htmlFor="procheck1">parte de:</label>
                                            </div>
                                        </Col>
                                        <Col sm={8}>
                                            <Select noOptionsMessage={() => 'Sin Resultados'}
                                                placeholder={"Partes"}
                                                value={this.state.repuestoPadre}
                                                onChange={(repuestoPadre) => this.setState({ repuestoPadre })}
                                                options={this.state.repuestos}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </AvForm>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" className="btn-round waves-effect" onClick={() => this.formPartes.submit()}>Aceptar</Button>{' '}
                        <Button color="danger" className="waves-effect" onClick={() => this.setState({ showAdd: false })}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                <Modal className="modal-lg" isOpen={this.state.showActivityModal} toggle={() => this.setState({ showActivityModal: false })} >
                    <ModalHeader> Actividades </ModalHeader>
                    <ModalBody className={"theme-green"}>
                        <AvForm onValidSubmit={() => this.submitActivity()}
                            ref={e => this.formActivity = e}>
                            <Row className="clearfix">
                                <Col sm={12}>
                                    <label>Descripción de la Actividad</label>
                                    <AvField name="actividadNombre"
                                        type="text"
                                        value={this.state.activiyName}
                                        onChange={(event) => this.setState({ activiyName: event.target.value })}
                                        placeholder="Breve Descripción de Actividad"
                                        validate={{
                                            required: { value: true, errorMessage: 'Ingrese una descripción para la actividad' },
                                            pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'La descripción debe contener solo letras y numeros' },
                                            maxLength: { value: 50, errorMessage: 'La descripción debe tener menos de 50 caracteres' }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="clearfix">
                                <Col sm={12}>
                                    <label>Instrucciones</label>
                                    <AvField name="instrucciones"
                                        type="textarea"
                                        value={this.state.procedimientos}
                                        onChange={(event) => this.setState({ procedimientos: event.target.value })}
                                        placeholder="Instrucciones"
                                        validate={{
                                            required: { value: false, errorMessage: '' },
                                            // eslint-disable-next-line
                                            pattern: { value: '/^[A-z0-9À-ž .()-_\s]+$/m', errorMessage: 'Las instrucciones deben contener solo letras y numeros' },
                                            maxLength: { value: 500, errorMessage: 'Las instrucciones deben tener menos de 150 caracteres' }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </AvForm>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" className="btn-round waves-effect" onClick={() => this.validateActivity()}>Aceptar</Button>{' '}
                        <Button color="danger" className="waves-effect" onClick={() => this.setState({ showActivityModal: false })}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                <SweetAlert
                    show={this.state.alert}
                    title={this.state.alertTitle}
                    onConfirm={() => this.setState({ alert: false })}
                    onCancel={() => this.setState({ alert: false })}
                />
 
            </Container>
        )
    }
}
//eslint-disable-next-line
{/* <Row tag="fieldset" className="clearfix">
                                <Col sm={12}>
                                    <legend className="col-form-label">Frecuencias</legend>
                                </Col>
                            </Row>
                            <Row className="clearfix" >
                                <Col sm={2}>
                                    <FormGroup className={"radio"}>
                                        <Input type="radio" name="radio1" id="radio1" value="fecha" checked={this.state.checked === 'esFecha'} onClick={() => this.setState({ checked: "esFecha" })} />
                                        <Label for="radio1">Fecha</Label>
                                    </FormGroup>
                                </Col>
                                <Col sm={5}>
                                    <FormGroup>
                                        <AvGroup>
                                            <AvInput tag={NumberFormat}
                                                name="frecuenciaFecha"
                                                value={this.state.frecuenciaFecha}
                                                customInput={Input}
                                                thousandSeparator={false}
                                                allowNegative={false}
                                                prefix={''}
                                                placeholder="Ej. cada 2 semanas"
                                                disabled={this.state.checked !== 'esFecha'}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    this.setState({ frecuenciaFecha: formattedValue, frecuenciaValueFecha: value })
                                                }}
                                                validate={{
                                                    required: { value: this.state.checked === 'esFecha', errorMessage: 'Ingrese existencia actual' },
                                                }}
                                            />
                                            <AvFeedback>Ingrese frecuencia de la actividad</AvFeedback>
                                        </AvGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={5}>
                                    <FormGroup>
                                        <Select noOptionsMessage={() => 'Sin Resultados'}
                                            styles={{
                                                container: base => ({
                                                    ...base,
                                                    border: this.state.checked === 'esFecha' && this.state.frecuenicaFechaError === "block" ? "1px solid #ced4da" : "none",
                                                    borderRadius: ".25rem",
                                                    borderColor: "#dc3545"
                                                }),
                                            }}
                                            isDisabled={this.state.checked !== 'esFecha'}
                                            placeholder={"Unidad"}
                                            value={this.state.unidadActividadFecha}
                                            onChange={(unidadActividadFecha) => this.setState({ unidadActividadFecha })}
                                            options={[
                                                { value: 0, label: "Dias" },
                                                { value: 1, label: "Semanas" },
                                                { value: 2, label: "Meses" },
                                                { value: 3, label: "Años" }
                                            ]}
                                            onBlur={() => { this.state.checked === 'esFecha' && this.state.unidadActividadFecha === null ? this.setState({ frecuenicaFechaError: "block" }) : this.setState({ frecuenicaFechaError: "none" }) }}
                                        />
                                        <div style={{ display: this.state.frecuenicaFechaError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione la unidad de la frecuencia</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="clearfix" >
                                <Col sm={2}>
                                    <FormGroup className={"radio"}>
                                        <Input type="radio" name="radio1" id="radio2" value="lecturas" checked={this.state.checked === 'esLectura'} onClick={() => this.setState({ checked: "esLectura" })} />
                                        <Label for="radio2">Lecturas</Label>
                                    </FormGroup>
                                </Col>
                                <Col sm={5}>
                                    <FormGroup>
                                        <AvGroup>
                                            <AvInput tag={NumberFormat}
                                                name="frecuenciaLectura"
                                                value={this.state.frecuenciaLectura}
                                                customInput={Input}
                                                thousandSeparator={false}
                                                allowNegative={false}
                                                prefix={''}
                                                placeholder="Frecuencia"
                                                disabled={this.state.checked !== 'esLectura'}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    this.setState({ frecuenciaLectura: formattedValue, frecuenciaValueLectura: value })
                                                }}
                                                validate={{
                                                    required: { value: this.state.checked === 'esLectura', errorMessage: 'Ingrese existencia actual' },
                                                }}
                                            />
                                            <AvFeedback>Ingrese frecuencia de la actividad</AvFeedback>
                                        </AvGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={5}>
                                    <FormGroup>
                                        <Select noOptionsMessage={() => 'Sin Resultados'}
                                            styles={{
                                                container: base => ({
                                                    ...base,
                                                    border: this.state.checked === 'esLectura' && this.state.frecuenciaLecturaError === "block" ? "1px solid #ced4da" : "none",
                                                    borderRadius: ".25rem",
                                                    borderColor: "#dc3545"
                                                }),
                                            }}
                                            isDisabled={this.state.checked !== 'esLectura'}
                                            placeholder={"Unidad"}
                                            value={this.state.unidadActividadLectura}
                                            onChange={(unidadActividadLectura) => this.setState({ unidadActividadLectura })}
                                            options={this.state.unidadesMedicion}
                                            onBlur={() => { this.state.checked === 'esLectura' && this.state.unidadActividadLectura === null ? this.setState({ frecuenciaLecturaError: "block" }) : this.setState({ frecuenciaLecturaError: "none" }) }}
                                        />
                                        <div style={{ display: this.state.frecuenciaLecturaError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione la unidad de la frecuencia</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="clearfix">
                                <Col sm={2}>
                                    <FormGroup className={"radio"}>
                                        <Input type="radio" name="radio1" id="radio3" value="mixto" checked={this.state.checked === 'esMixto'} onClick={() => this.setState({ checked: "esMixto" })} />
                                        <Label for="radio3">Mixto</Label>
                                    </FormGroup>
                                </Col>
                                <Col sm={5}>
                                    <FormGroup>
                                        <AvGroup>
                                            <AvInput tag={NumberFormat}
                                                name="frecuenciaMixto"
                                                value={this.state.frecuenciaMixto}
                                                customInput={Input}
                                                thousandSeparator={false}
                                                prefix={''}
                                                placeholder="Frecuencia"
                                                disabled={this.state.checked !== 'esMixto'}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    this.setState({ frecuenciaMixto: formattedValue, frecuenciaValueMixto: value })
                                                }}
                                                validate={{
                                                    required: { value: this.state.checked === 'esMixto', errorMessage: 'Ingrese frecuencia de lectura' },
                                                }}
                                            />
                                            <AvFeedback>Ingrese frecuencia de la actividad</AvFeedback>
                                        </AvGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={5}>
                                    <FormGroup>
                                        <Select noOptionsMessage={() => 'Sin Resultados'}
                                            styles={{
                                                container: base => ({
                                                    ...base,
                                                    border: this.state.checked === 'esMixto' && this.state.frecuenciaMixtoError === "block" ? "1px solid #ced4da" : "none",
                                                    borderRadius: ".25rem",
                                                    borderColor: "#dc3545"
                                                }),
                                            }}
                                            isDisabled={this.state.checked !== 'esMixto'}
                                            placeholder={"Unidad"}
                                            value={this.state.unidadActividadMixto}
                                            onChange={(unidadActividadMixto) => this.setState({ unidadActividadMixto })}
                                            options={this.state.unidadesMedicion}
                                            onBlur={() => { this.state.checked === 'esMixto' && this.state.unidadActividadMixto === null ? this.setState({ frecuenciaMixtoError: "block" }) : this.setState({ frecuenciaMixtoError: "none" }) }} nBlur={() => { this.state.checked === 'esMixto' && this.state.unidadActividadMixto === null ? this.setState({ frecuenciaMixtoError: "block" }) : this.setState({ frecuenciaMixtoError: "none" }) }}
                                        />
                                        <div style={{ display: this.state.frecuenciaMixtoError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione la unidad de la frecuencia</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2}>
                                    <Badge color="info">* Lo que suceda<br />primero</Badge>
                                </Col>
                                <Col sm={5}>
                                    <FormGroup>
                                        <AvGroup>
                                            <AvInput tag={NumberFormat}
                                                name="frecuenciaMixtoFecha"
                                                value={this.state.frecuenciaMixtoFecha}
                                                customInput={Input}
                                                thousandSeparator={false}
                                                prefix={''}
                                                placeholder="O cada"
                                                disabled={this.state.checked !== 'esMixto'}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    this.setState({ frecuenciaMixtoFecha: formattedValue, frecuenciaValueMixtoFecha: value })
                                                }}
                                                validate={{
                                                    required: { value: this.state.checked === 'esMixto', errorMessage: 'Ingrese frecuencia' },
                                                }}
                                            />
                                            <AvFeedback>Ingrese frecuencia de la actividad</AvFeedback>
                                        </AvGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={5}>
                                    <FormGroup>
                                        <Select noOptionsMessage={() => 'Sin Resultados'}
                                            styles={{
                                                container: base => ({
                                                    ...base,
                                                    border: this.state.checked === 'esMixto' && this.state.frecuenciaMixtoFechaError === "block" ? "1px solid #ced4da" : "none",
                                                    borderRadius: ".25rem",
                                                    borderColor: "#dc3545"
                                                }),
                                            }}
                                            isDisabled={this.state.checked !== 'esMixto'}
                                            placeholder={"Unidad"}
                                            value={this.state.unidadActividadMixtoFecha}
                                            onChange={(unidadActividadMixtoFecha) => this.setState({ unidadActividadMixtoFecha })}
                                            options={[
                                                { value: 0, label: "Dias" },
                                                { value: 1, label: "Semanas" },
                                                { value: 2, label: "Meses" },
                                                { value: 3, label: "Años" }
                                            ]}
                                            onBlur={() => { this.state.checked === 'esMixto' && this.state.unidadActividadMixtoFecha === null ? this.setState({ frecuenciaMixtoFechaError: "block" }) : this.setState({ frecuenciaMixtoFechaError: "none" }) }} nBlur={() => { this.state.checked === 'esMixto' && this.state.unidadActividadMixtoFecha === null ? this.setState({ frecuenciaMixtoFechaError: "block" }) : this.setState({ frecuenciaMixtoFechaError: "none" }) }}
                                        />
                                        <div style={{ display: this.state.frecuenciaMixtoFechaError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione la unidad de la frecuencia</div>
                                    </FormGroup>
                                </Col>
                            </Row> */}
                            // return (
                            //     <div class="dd-handle" onClick={() => {
                            //         // const { unidadFecha } = this.state
                            //         // let i = item.unidad;
                            //         // let m = item.unidadMixto;
                    
                            //         // if (item.unidadMedicion === null) {
                            //         //     unidad.push({
                            //         //         value: unidadFecha.i,
                            //         //         label: item.unidad
                            //         //     });
                            //         // } else {
                            //         //     unidadesMed.push({
                            //         //         value: item.unidadMedicion.unidadMedicionId,
                            //         //         label: `${item.unidadMedicion.descripcion} (${item.unidadMedicion.simbolo})`,
                            //         //         simbolo: item.unidadMedicion.simbolo
                            //         //     });
                            //         //     // console.log(unidadesMed[0], "unidadesMed")
                    
                            //         // };
                    
                            //         // if (item.esMixto === true) {
                            //         //     unidad.push({
                            //         //         value: 2,
                            //         //         label: item.unidadMixto
                            //         //     });
                            //         // }
                    
                            //         !this.state.isDeleting ?
                            //             this.setState({
                            //                 selectedItem: item,
                            //                 activiyName: item.descripcion,
                            //                 // checked: item.esFecha === true ? "esFecha" : item.esLectura === true ? "esLectura" : "esMixto",
                            //                 // frecuenciaFecha: item.esFecha === true && item.frecuencia,
                            //                 // unidadActividadFecha: item.esFecha === true && unidad,
                            //                 // frecuenciaLectura: item.esLectura === true && item.frecuencia,
                            //                 // unidadActividadLectura: item.esLectura === true && unidadesMed,
                            //                 // frecuenciaMixto: item.esMixto === true && item.frecuencia,
                            //                 // unidadActividadMixto: item.esMixto === true && unidadesMed[0],
                            //                 // frecuenciaMixtoFecha: item.esMixto === true && item.frecuenciaMixto,
                            //                 // unidadActividadMixtoFecha: item.esMixto === true && unidad[0],
                            //                 procedimientos: item.procedimientos,
                            //                 prioridad: item.prioridad,
                            //                 limiteMinimo: item.limiteMinimo,
                            //                 limiteMaximo: item.limiteMaximo,
                            //                 showActivityModal: true,
                            //             }) :
                            //             this.deleteActivity(item, index);
                            //     }}>{`${item.descripcion}`}</div>
                            // )
                            //     addActivity  = () => {
//         const { repuestosAgregados, activiyName, checked, frecuenciaValueLectura, frecuenciaFecha,
//             frecuenciaValueMixto, frecuenciaValueMixtoFecha, unidadActividadFecha, unidadActividadLectura, unidadActividadMixto, procedimientos, unidadActividadMixtoFecha } = this.state;
// console.log(repuestosAgregados)
//         //search item
//         //const root = [{ children: repuestosAgregados }]
//         //const item = searchTree(root[0], selectedItem.id);
//         const newRepuestosArray = {...repuestosAgregados[0].activity};
//         const item = newRepuestosArray;
//         item.push({
//             descripcion: activiyName,
//             esFecha: checked === "esFecha",
//             esLectura: checked === "esLectura",
//             esMixto: checked === "esMixto",
//             frecuencia: checked === "esFecha" ? frecuenciaFecha : checked === "esLectura" ? frecuenciaValueLectura : frecuenciaValueMixto,
//             unidad: checked === "esFecha" ? unidadActividadFecha.label : checked === "esLectura" ? unidadActividadLectura.simbolo : unidadActividadMixto.simbolo,
//             unidadMedicion: checked === "esFecha" ? null : checked === "esLectura" ? { unidadMedicionId: unidadActividadLectura.value } : { unidadMedicionId: unidadActividadMixto.value },
//             frecuenciaMixto: checked === "esMixto" ? frecuenciaValueMixtoFecha : null,
//             unidadMixto: checked === "esMixto" ? unidadActividadMixtoFecha.label : null,
//             procedimientos,
//             prioridad: 1,
//             limiteMinimo: 0,
//             limiteMaximo: 0,
//         })

//         this.setState({
//             selectedItem: {},
//             activiyName: "",
//             // checked: "esFecha",
//             // frecuenciaFecha: "",
//             // frecuenciaLectura: "",
//             // frecuenciaMixto: "",
//             // frecuenciaMixtoFecha: "",
//             // frecuenciaValueFecha: 0,
//             // frecuenciaValueLectura: 0,
//             // frecuenciaValueMixto: 0,
//             // frecuenciaValueMixtoFecha: 0,
//             // unidadActividadFecha: null,
//             // unidadActividadLectura: null,
//             // unidadActividadMixto: null,
//             // unidadActividadMixtoFecha: null,
//             procedimientos: "",
//             repuestosAgregados: item,
//             showActivityModal: false
//         })

//     }