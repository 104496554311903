import React, { useState, useEffect, useRef } from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import Api from "../modules/activos"

import {
    Row, Col, Button, Alert, InputGroup, InputGroupAddon, InputGroupText, Input
    //Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
// import TableHeaderlist from '../components/TableHeaderList';
// import TableItemlist from '../components/TableItemList';
import BreadcrumbComponent from '../components/BreadcrumbComponent';
import ModalAssestType from './components/ModalAssestType';
import SweetAlert from 'react-bootstrap-sweetalert';
import "react-notification-alert/dist/animate.css";
import { Table as AntTable, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';



// const TableAssestType = ({ data, update, remove }) => { // print table view
//     return (
//         <Col xl={12} lg={12} md={12} sm={12} className='p-0'>
//             <Table hover responsive className="mb-0 c_list c_table break_table theme-color">
//                 <thead>
//                     <tr>
//                         <TableHeaderlist title="#" />
//                         <TableHeaderlist title="Descripción" />
//                         <TableHeaderlist title="Tipo de activo" />
//                         <TableHeaderlist title="Acciones" className='text-center' style={{ width: 150 }} />
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {data.map((item, i) => {
//                         return (
//                             <tr key={i} className="justify-content-center">
//                                 <TableItemlist item={i + 1} />
//                                 <TableItemlist item={item.descripcion} />
//                                 <TableItemlist item={item?.tipoActivoParentId?.descripcion} />
//                                 <td className='text-center'>
//                                     <Button color="info" className="btn-icon" type="button" onClick={() => update(item, i)}>
//                                         <i className="zmdi zmdi-edit" />
//                                     </Button>
//                                     <Button color="danger" className="btn-icon" type="button" onClick={() => remove(item, i)}>
//                                         <i className="zmdi zmdi-delete" />
//                                     </Button>
//                                 </td>
//                             </tr>
//                         )
//                     })}
//                 </tbody>
//             </Table>
//         </Col>
//     );
// };

export default function AssestType() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [modal, setModal] = useState(false);
    const [tipos, setTipos] = useState([]);
    const [descripcion, setDescripcion] = useState("");
    const [tipoActivoParent, setTipoActivoParent] = useState(null);
    const [selected, setSelected] = useState(null);
    const [alert, setAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [alert2, setAlert2] = useState(false);
    const [pages, setPages] = useState([]);
    //eslint-disable-next-line
    const [currentPage, setCurrentPage] = useState(1);
    //eslint-disable-next-line
    const [totalPages, setTotalPages] = useState(10);
    const showSearch = false;
    useEffect(() => {
        getTipoActivosList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setAlert2(false);
        }, 4000);
    }, [alert2]);

    const getTipoActivosList = async () => {
        setLoading(true);
        const list = await Api.listTiposActivos();
        //add key to list
        list.forEach((element, i) => {
            element.key = element.tipoActivoId;
        });
        // console.log("🚀 ~ file: AssestType.js:85 ~ getTipoActivosList ~ list", list)

        if (list) {
            list.forEach((element, i) => {
                element.key = element.tipoActivoId;
            });
            setData(list);
            setLoading(false);
            listaActivos(list);
        } else {
            setLoading(false);
            setAlertTitle('!Error¡');
            setAlertMessage('Error al cargar los datos');
            setAlertType('danger');
            setAlert2(true);
            // alert("Error al cargar los datos");
        };
    };

    const listaActivos = (list) => {
        let listActivos = [];
        list.forEach(element => {
            listActivos.push({
                value: element?.tipoActivoId,
                label: element?.descripcion,
                tipoActivoParent: element?.tipoActivoParentId
            });
        });
        setTipos(listActivos);
    };

    const addTipoActivo = async () => {
        setAlertTitle('!Éxito¡');
        setAlertMessage('Tipo de activo creado correctamente');
        setAlertType('success');
        // console.log("🚀 ~ file: AssestType.js:122 ~ addTipoActivo ~ tipoActivoParent", tipoActivoParent)
        let data = {
            descripcion: descripcion,
            tipoActivoParentId: tipoActivoParent !== null ? {
                // descripcion: tipoActivoParent.tipoActivoParent,
                tipoActivoId: tipoActivoParent.value
            } : null
        };
        // console.log(data);
        const response = await Api.createTipoActivo(data);
        if (response) {
            setSelected({})
            setDescripcion("");
            getTipoActivosList();
            setModal(false);
            setAlert2(true);
        } else {
            setAlertTitle('!Error¡');
            setAlertMessage('Error al crear el tipo de activo');
            setAlertType('danger');
            setAlert2(true);
        };
    };

    const removeTipoActivo = async () => {
        setAlertTitle('!Éxito¡');
        setAlertMessage('Tipo de activo eliminado correctamente');
        setAlertType('success');
        setAlert(false);
        let tipoActivoId = selected.tipoActivoId;
        const response = await Api.deleteTipoActivo(tipoActivoId);
        if (response) {
            setAlert2(true);
            setSelected(null);
            setTipoActivoParent(null);
            setDescripcion("");
            getTipoActivosList();
        } else {
            setAlertTitle('!Error¡');
            setAlertMessage('Error al eliminar el tipo de activo');
            setAlertType('danger');
            setAlert2(true);
        };
    };

    const updateTipoActivo = async () => {
        // console.log(selected, "selected");
        // console.log(tipoActivoParent, "tipoActivoParent");
        // console.log(descripcion, "descripcion")

        setAlertTitle('!Éxito¡');
        setAlertMessage('Tipo de activo actualizado correctamente');
        setAlertType('success');
        let des = descripcion === '' ? selected.descripcion : descripcion;
        let id = tipoActivoParent === null ? null : tipoActivoParent.value;
        let data = {
            descripcion: des,
            tipoActivoId: selected.tipoActivoId,
            tipoActivoParentId: tipoActivoParent !== null ? {
                // descripcion: tipoActivoParent.tipoActivoParent,
                tipoActivoId: tipoActivoParent !== null ? id : null

            } : null
        };
        console.log(data);
        const response = await Api.updateTipoActivo(data);
        if (response) {
            setSelected(null)
            setDescripcion("");
            getTipoActivosList(null);
            setModal(false);
            setAlert2(true);
        } else {
            setAlertTitle('!Error¡');
            setAlertMessage('Error al actualizar el tipo de activo');
            setAlertType('danger');
            setAlert2(true);
        };
    };

    const searchTipoActivo = async (page) => {
        console.log(search);
        if (search !== "" && search.length > 2) {
            const res = await Api.searchTipoActivo({ page, search })
            if (res) {
                const response = res.data;
                for (let key in response) {
                    data.push(response[key])
                }
                for (let i = 0; i < res.totalPages; i++) {
                    pages.push((i + 1));
                }
                setData(data);
                setPages(pages);
                setCurrentPage(res.currentPage);
                setTotalPages(res.totalPages);
            } else {
                console.log(res);
            }
        }
    }

    const openModal = () => {
        setSelected(null);
        setTipoActivoParent(null);
        setDescripcion("");
        setModal(true);

    };

    const update = (item, i) => {
        setModal(true);
        setSelected({ ...item, index: i, update: true });
    }

    const remove = (item, i) => {
        setAlert(true);
        setSelected({ ...item, index: i });
    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys)[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filtrar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Cerrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                // setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
            rowScope: 'row',
        },
        {
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            ...getColumnSearchProps('descripcion'),
        },
        {
            title: 'Tipo de activo',
            dataIndex: 'TipoActivo',
            key: 'tipoActivo',
            render: (text, record) => (
                <div className="d-flex align-items-center">
                    <div className="mr-2">
                        {record.tipoActivoParentId?.descripcion}<br />
                        {record.codigo}
                    </div>
                </div>
            ),
            sorter: (a, b) => a.descripcion - b.descripcion,

        },
        {
            title: 'Acciones',
            dataIndex: 'acciones',
            key: 'acciones',
            render: (text, record, index) => (
                <div className="d-flex align-items-center">
                    <Button color="info" className="btn-icon" type="button" onClick={() => update(record, index)}>
                        <i className="zmdi zmdi-edit" />
                    </Button>
                    <Button color="danger" className="btn-icon" type="button" onClick={() => remove(record, index)}>
                        <i className="zmdi zmdi-delete" />
                    </Button>
                </div>
            ),
            // sorter: (a, b) => a.age - b.age,
        },

    ];

    return (
        <Container menuId="configuracion" submenu="tipo" usersToggled>
            {loading && <Loading />}
            <section className="content">
                <Row>
                    <BreadcrumbComponent
                        title="Tipos de activo"
                        b1="Inicio"
                        href1="/home"
                        b2="Configuración"
                        b3="Tipos de activo"
                    />
                    <Col lg={5} md={4} sm={12} >
                        <Button color="success" className="btn-icon float-right" type="button" onClick={() => openModal()}>
                            <i className="zmdi zmdi-plus" />
                        </Button>
                        {showSearch &&
                            <InputGroup className="float-right">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><i className="zmdi zmdi-search" /></InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    onKeyUp={() => searchTipoActivo(1)}
                                    type="text"
                                    className="form-control"
                                    placeholder="Busqueda..."
                                    value={search}
                                    onChange={(event) => setSearch(event.target.value)}
                                />
                            </InputGroup>
                        }
                    </Col>
                </Row>
                <AntTable
                    columns={columns}
                    dataSource={data}
                />

                <Alert
                    color={alertType}
                    className='mt-1'
                    isOpen={alert2}
                    toggle={() => setAlert2(false)}
                >
                    <strong>{alertTitle} </strong>{alertMessage}
                </Alert>
            </section>
            <SweetAlert
                show={alert}
                onCancel={() => setAlert(false)}
                warning
                showCancel
                confirmBtnText="Sí, eliminar"
                cancelBtnText="No"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                focusCancelBtn
                title={"¿Estas Seguro?"}
                onConfirm={() => { removeTipoActivo() }}
            >
                Ésta acción es irreversible
            </SweetAlert>
            <ModalAssestType
                show={modal}
                update={selected?.update}
                selected={selected}
                setSelected={(value) => setSelected(value)}
                onHide={() => {
                    setModal(false);
                    setSelected(null);
                    setDescripcion("");
                    setTipoActivoParent(null);
                }}
                descripcion={descripcion}
                setDescripcion={(value) => setDescripcion(value)}
                tipoActivoParent={tipoActivoParent}
                setTipoActivoParent={(value) => { setTipoActivoParent(value); }}
                options={tipos}
                crear={() => addTipoActivo()}
                actualizar={() => updateTipoActivo()}
            />
            {/*
                <Alert color="success" isOpen={visible} toggle={()=> onDismiss()}>
                    {message}
                </Alert>
                <Alert color="warning" isOpen={warning} toggle={()=> onDismiss()}>
                    {message}
                </Alert>
                <Alert color="danger" isOpen={error} toggle={()=> onDismiss()}>
                    Error al eliminar
                </Alert>
            */}
        </Container>
    )

}
//eslint-disable-next-line
{/* <Pagination className="pagination pagination-success mt-4">
                    {
                        pages.map((value, index) => {
                            return (
                                <>
                                    <PaginationItem active={value === currentPage}>
                                        <PaginationLink href="#" onClick={() => searchTipoActivo(value)}>{value}</PaginationLink>
                                    </PaginationItem>
                                </>
                            );
                        })
                    }
                </Pagination> */}
