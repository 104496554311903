import React from 'react';
import { 
    Col,
} from 'reactstrap';
import { Sparklines, SparklinesBars } from 'react-sparklines';

export default class ReportCard extends React.Component {
    render(){
        let className = "zmdi ";
        if (this.props.iconName) {
          className += this.props.iconName;
        }
        return(
            <Col lg={3} md={6} sm={6} >
                <div className="state_w1 mb-1 mt-1 body">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h5>{this.props.monthValue}</h5>
                            <span><i className={className} /> {this.props.title}</span>
                        </div>
                        <div className="sparkline">
                            <Sparklines data={this.props.weekValues} svgHeight={83} svgWidth={62} >
                                <SparklinesBars barWidth={15} margin={5} style={{ stroke: "white", fill: this.props.barColor }} />
                            </Sparklines>
                        </div>

                    </div>
                </div>
            </Col>
        )
    }
}