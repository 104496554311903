import React, { useEffect, useState } from 'react';
import Container from "../Container";
import '../App.css';
import Api from "../modules/activos";
import User from "../modules/user";
import Orden from "../modules/ordenTrabajo";
import Mantenimiento from "../modules/plan"
import ModalDetail from '../components/Modal/ModalDetail';
import ModalOrderJob from '../components/Modal/ModalOrderJob';
import ModalRevisionsJob from '../components/Modal/ModalRevisionsJob';
import ModalRealizarOT from '../components/Modal/ModalRealizarOT';
import BreadcrumbComponent from '../components/BreadcrumbComponent';
import { Card, Col, Button, Row, InputGroup, InputGroupAddon, Input, Spinner } from 'reactstrap';
import "react-notification-alert/dist/animate.css";
//eslint-disable-next-line
import { Tree, Modal, Table as AntTable, Tag, Button as ButtonAnt } from 'antd';
import NewWindow from 'react-new-window'
import ReporteOrdenTrabajo from '../Order/reporte/ReporteOrdenTrabajo';
import moment from 'moment';
import download from 'downloadjs';
import { useReactToPrint } from "react-to-print";
import { PrinterOutlined } from '@ant-design/icons';
import { DATOS_BASICOS } from '../constants/index'
import history from '../history';

function searchTree(element, matchingKey) {
    // eslint-disable-next-line
    if (element.key == matchingKey) {
        return element;
    } else if (element.children.length > 0) {
        let i = 0;
        let result = null;
        for (i; result == null && i < element.children.length; i++) {
            result = searchTree(element.children[i], matchingKey);
        }
        return result;
    }
    return null;
}


export default function AssetsTree() {
    const server = `${window.app && window.app.env.API_URL}/api`
    const [dummyData, setDummyData] = useState([]);
    const [list, setList] = useState([]);
    // eslint-disable-next-line
    const [pages, setPages] = useState([]);
    // eslint-disable-next-line
    const [currentPage, setCurrentPage] = useState(0);
    // eslint-disable-next-line
    const [totalPages, setTotalPages] = useState(0);
    const [disabledButton, setDisabledButton] = useState(true);
    // eslint-disable-next-line
    const [selectedItem, setSelectedItem] = useState({});
    const [searchTitle, setSearchTitle] = useState({ id: -1, label: DATOS_BASICOS.NOMBRE_EMPRESA });
    const [showModal, setShowModal] = useState(false);
    const [historialOrdenes, setHistorialOrdenes] = useState([]);
    // eslint-disable-next-line
    const [historialRevisiones, setHistorialRevisiones] = useState([]);
    const [data, setData] = useState({});
    const [showOrdenes, setShowOrdenes] = useState(false)
    const [showRevisiones, setShowRevisiones] = useState(false)
    const [selectedCheckbox, setSelectedCheckbox] = useState([]); // selected checkboxes
    // eslint-disable-next-line
    const [categoriasLocalizacion, setCategoriasLocalizacion] = useState([]);
    // eslint-disable-next-line
    const [tecnicos, setTecnicos] = useState([]);
    // eslint-disable-next-line
    const [tipoActivos, setTipoActivos] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    //eslint-disable-next-line
    const [popupData, setPopupData] = useState({});
    const [fechaOT, setFechaOT] = useState(null);
    const [tipoOT, setTipoOT] = useState(1);
    const [modalData, setModalData] = useState({});
    const [activo_id, setActivo_id] = useState(null);
    const [search, setSearch] = useState({
        codigoBarra: "",
        busqueda: "",
        categoria: -1,
        tipoActivo: -1,
        tecnico: -1,
    });
    const [showModalOT, setShowModalOT] = useState(false);
    const [open, setOpen] = useState(false);
    const [arryData, setArryData] = useState([])

    useEffect(() => {
        console.log("===================> Resumen de Mantenimiento <===================");
        listActivosTree(1);
        // eslint-disable-next-line
    }, []);

    const getChildrens = async (tree) => {
        // get children for each node recursively
        for (let i = 0; i < tree?.length; i++) {
            // console.log("find activos from " + tree[i].key?.replace("TP_", ""))
            const res = await Mantenimiento.getTreeActivos({
                tipoActivoId: tree[i].key?.replace("TP_", "")
            });
            if (res) {
                // console.log("res.data", res)
                if (res?.length > 0) {
                    // for each item in res find its parent and add it to tree[i].children
                    for (let j = 0; j < res.length; j++) {
                        // find node parent in a tree by its key
                        const parent = searchTree({ key: "root", children: tree }, "TP_" + res[j].tipoActivoId);
                        // console.log("🚀 ~ file: AssetsTree.js:82 ~ getChildrens ~ parent", parent, "TP_" + res[j].tipoActivoId)
                        if (parent) {
                            if (!parent.children) {
                                parent.children = [];
                            }
                            parent.children.push(res[j]);
                            // parent.title = parent.title + " (" + parent.children.length + ")";
                        }
                    }

                    tree[i].leaf = false;
                    tree[i].isLeaf = false;
                    // tree[i].title = tree[i].title + " (" + res.length + ")";
                    const treeChildres = tree[i].children;
                    // console.log("treeChildres", treeChildres)
                    // for each treeChildres where parent is null, set parent equals to tree[i].key
                    for (let j = 0; j < treeChildres?.length; j++) {
                        if (treeChildres[j].parent === null) {
                            treeChildres[j].parent = tree[i].key;
                        }
                    }
                }
            }
        }
    }

    const listActivosTree = async (page) => { // get root data
        let data = [
            {
                title: DATOS_BASICOS.NOMBRE_EMPRESA,
                key: "-1",
                icon: <i className='zmdi zmdi-city pr-1' />,
                children: []
            }
        ];
        const res = await Mantenimiento.getTreeCategorias();
        if (res) {
            const rootChildren = data[0];
            rootChildren.children = res.tree.data;
            const tree = rootChildren.children;
            await getChildrens(tree);
        }
        // console.log("data", data)
        setDummyData(data);
        // getChild(1, -1);
        getCategoriasLocalizacion();
    };

    const getCategoriasLocalizacion = () => {
        // console.log("getCategoriasLocalizacion");
        let categoriasLocalizacion = [{ value: -1, label: "----" }];
        Api.listCategoriasLocalizacion().then((response) => {
            if (response) {
                for (let key in response) {
                    categoriasLocalizacion.push({
                        value: response[key].categoriaLocalizacionId,
                        label: response[key].descripcion,
                        name: "categoria"
                    })
                }
            }
        }).finally(() => {
            setCategoriasLocalizacion(categoriasLocalizacion);
            getTecnicos();
        })
    };

    const getTecnicos = () => {
        // conssole.log("getTecnicos");
        let tecnicos = [{ value: -1, label: "----" }]
        User.list({ filtro: 1 }).then((response) => {
            if (response) {
                const res = response.data
                for (const key in res) {
                    tecnicos.push({
                        value: res[key].usuarioId,
                        label: res[key].persona.nombre + " " + res[key].persona.apellido,
                        name: "tecnico"
                    })
                }
            }
        }).finally(() => {
            setTecnicos(tecnicos);
            getTipoActivos();
        })
    }

    const getTipoActivos = () => {
        // console.log("getTipoActivos");
        let tipoActivos = [{ value: -1, label: "----" }]
        Api.listTiposActivos().then((response) => {
            if (response) {
                // console.log("🚀 ~ file: AssetsTree.js:199 ~ Api.listTiposActivos ~ response:", response)
                for (let key in response) {
                    tipoActivos.push({
                        value: response[key].tipoActivoId,
                        label: response[key].descripcion,
                        name: "tipoActivo",
                        tipoActivoParentId: response[key].tipoActivoParentId
                    })
                }
            }
        }).finally(() => {
            setTipoActivos(tipoActivos);
        })
    }
    //eslint-disable-next-line
    const handleNodeClicked = async (label, key, leaf) => { // handle node click
        setSelectedItem([]);
        const newDummyData = [...dummyData];
        const data = newDummyData[0].children;
        //find value recursively in childrens or until all data is traversed
        if (key.node.key !== "-1") {
            const item = await findNode(data, key.node.key);
            // console.log("🚀 ~ file: AssetsTree.js:222 ~ handleNodeClicked ~ key.node.key:", key.node.key)
            setList([]);
            setSearchTitle({ label: item?.title || DATOS_BASICOS.NOMBRE_EMPRESA });
            setSearch({ ...search, id: item?.key || -1 });
            getChild(1, item?.key);
        }
        setList([]);
        setSearchTitle({ label: key.node.title });
        setSearch({ ...search, id: key.node.key });
        getChild(1, key.node.key);
    };

    const findNode = (data, id) => { // find node by id
        for (const element of data) {
            if (element.key === id) {
                return element;
            } else if (element.children) {
                const node = findNode(element.children, id);
                if (node) {
                    return node;
                }
            }
        }
    };

    const getChild = async (page, value) => { // get child data

        // let list = [], pages = [], totalPages = 0, currentPage = 0;
        const newValue = value.id ? value.id : value;
        const valueChange = newValue === "-1" ? parseInt(newValue) : newValue;
        const filter = typeof newValue === "string" ? newValue?.startsWith("TP_") ? 1 : 0 : 0;
        const id = (typeof valueChange === "string" ? valueChange.replace("TP_", "") : valueChange) || 0
        const data = {
            filter,
            Id: id,
            busqueda: value?.busqueda ? value.busqueda : "",
        };
        const mantenimientos = await Mantenimiento.getMantenimientosFromTree(data);
        if (mantenimientos) {
            // console.log("🚀 ~ file: AssetsTree.js:260 ~ getChild ~ mantenimientos:", mantenimientos)
            //add key to each item
            for (let key in mantenimientos) {
                mantenimientos[key].key = key;
            }
            setList(mantenimientos);
        }
    };
    // eslint-disable-next-line
    // const onSearch = (page) => { // search and paginate
    //     // let data = [], pages = [], totalPages = 0, currentPage = 0;
    //     if (search !== "") {
    //         // OrdenTrabajo.search({ search, page }).then((res) => {
    //         //     if (res.success) {
    //         //         const response = res.data
    //         //         totalPages = res.totalPages;
    //         //         currentPage = res.currentPage;
    //         //         for (let key in response) {
    //         //             let equipo = response[key].usuarioAsignado ? [{
    //         //                 src: response[key].usuarioAsignado.fotoUri,
    //         //                 name: response[key].usuarioAsignado.persona.nombre
    //         //             }] : []
    //         //             for (let k in response[key].checkList) {
    //         //                 const member = response[key].checkList[k];
    //         //                 if (member.checkedByUser) {
    //         //                     equipo.push({
    //         //                         src: member.checkedByUser.fotoUri,
    //         //                         name: member.checkedByUser.persona.nombre
    //         //                     })
    //         //                 }
    //         //             }
    //         //             data.push({
    //         //                 id: response[key].ordenTrabajoId,
    //         //                 estado: response[key].estado,
    //         //                 numero: ('00000000' + response[key].ordenTrabajoNumero).slice(-8),
    //         //                 prioridadValor: response[key].prioridad,
    //         //                 prioridad: response[key].prioridad === 1 ? "Alta" : response[key].prioridad === 2 ? "Media" : "Baja",
    //         //                 imagenActivo: response[key].activo.fotoUri,
    //         //                 codigoActivo: response[key].activo.codigo,
    //         //                 nombreActivo: response[key].activo.nombre,
    //         //                 activoId: response[key].activo.activoId,
    //         //                 equipo,
    //         //                 tareas: response[key].checkList,
    //         //                 //progreso : this.getProgress(response[key].checkList),
    //         //                 productosList: _.filter(response[key].productos, (o) => { return o.activo; }),
    //         //                 frecuenciaValueLectura: response[key].activo.valorLectura ? response[key].activo.valorLectura : 0,
    //         //                 unidadActividadLectura: response[key].activo.unidadMedicion ? {
    //         //                     value: response[key].activo.unidadMedicion.unidadMedicionId,
    //         //                     label: `${response[key].activo.unidadMedicion.descripcion} (${response[key].activo.unidadMedicion.simbolo})`,
    //         //                     simbolo: response[key].activo.unidadMedicion.simbolo
    //         //                 } : null,
    //         //                 frecuenciaLectura: response[key].activo.valorLectura ? response[key].activo.valorLectura : "",
    //         //             })
    //         //         }
    //         //         if (totalPages === 0) this.refs.notify.notificationAlert(options);
    //         //     }
    //         // }).catch((err) => {
    //         //     this.refs.notifyError.notificationAlert(options2);
    //         // }).finally(() => {
    //         //     for (let i = 0; i < totalPages; i++) {
    //         //         pages.push((i + 1));
    //         //     }
    //         //     this.setState({
    //         //         data,
    //         //         pages,
    //         //         currentPage,
    //         //         totalPages,
    //         //         loading: false
    //         //     })
    //         // })
    //     } else {
    //         // this.filterBy(filter, page)
    //     }
    // };

    const getDetail = async (value) => { // get detail data
        Api.get({ id: selectedCheckbox[0]?.activo_id }).then((response) => {
            if (response) {
                let newData = {
                    ...response,
                    tipoModal: value,
                }
                // console.log("🚀 ~ file: AssetsTree.js:353 ~ Api.get ~ newData:", newData)

                setData(newData);
                setShowModal(true) //show modal

            } else {
                alert("No se encontró el activo")
            }
        }).then(() => {
        }).catch((err) => {
            console.log(err, "err getDetail")
        })
    };

    const getOrdenesTrabajo = async () => { // get ordenes de trabajo
        Api.listOrders({ activoId: selectedCheckbox[0]?.activo_id }).then((res) => {
            if (res) {
                setHistorialOrdenes(res)
            }
        }).finally(() => {
            setShowOrdenes(true)
        })
    };

    const columns = [
        {
            title: 'Estado',
            dataIndex: 'estadoMantenimiento',
            key: 'estado',
            filters: [
                {
                    text: 'Abierto',
                    value: 0,
                },
                {
                    text: 'Inminente',
                    value: 1,
                },
                {
                    text: 'Retrasado',
                    value: 2,
                },
            ],
            // filterMode: 'tree',
            // filterSearch: true,
            onFilter: (value, record) => record.estadoMantenimiento === value,
            sorter: (a, b) => a.estadoMantenimiento - b.estadoMantenimiento,
            render: (text, record) => (
                <div className="d-flex align-items-center">
                    <small>
                        {
                            <Tag color={
                                record.estadoMantenimiento === 0 ? "success" :
                                    record.estadoMantenimiento === 1 ? "warning" : "red"
                            }>
                                {record.estadoMantenimiento === 0 ? "abierto" :
                                    record.estadoMantenimiento === 1 ? "inminente" : "Retrasado"
                                }
                            </Tag>
                        }
                    </small>
                </div>
            ),
        },
        {
            title: 'Activo',
            dataIndex: 'nombre',
            key: 'activo',
            render: (text, record) => (
                <div className="d-flex align-items-center">
                    <div className="mr-2">
                        {record.nombre}<br />
                        {record.codigo}
                    </div>
                </div>
            ),
        },
        {
            title: 'Actividad',
            dataIndex: 'descripcion',
            key: 'actividad',
        },
        {
            title: 'Frecuencia',
            dataIndex: 'frecuencia',
            key: 'frecuencia',
        },
        {
            title: 'Ultimo Mantenimiento',
            dataIndex: 'ultima_revision',
            key: 'ultimoMantenimiento',
            render: (text, record) => (
                <div className="d-flex align-items-center">
                    {moment(record.ultima_revision).isValid() ? moment(record.ultima_revision)?.format("DD/MM/YYYY") : ""}
                </div>
            ),
        }
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //eslint-disable-next-line
    const [loading, setLoading] = useState(false);

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedCheckbox(selectedRows)
        // console.log("🚀 ~ file: AssetsTree.js:449 ~ onSelectChange ~ selectedRows:", selectedRows)
        if (selectedRows.length > 1 || selectedRows.length === 0) {
            setDisabledButton(true)
        } else {
            setDisabledButton(false)
        }
    };

    const printTableView = () => { // print table view
        const rowSelection = {
            type: 'radio',
            selectedRowKeys,
            onChange: onSelectChange,
            selections: [
                AntTable.SELECTION_ALL,
                AntTable.SELECTION_INVERT,
                AntTable.SELECTION_NONE,
                {
                    key: 'odd',
                    text: 'Select Odd Row',
                    onSelect: (record, changeableRowKeys) => {
                        // console.log(record)
                        let newSelectedRowKeys = [];
                        newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                            if (index % 2 !== 0) {
                                return false;
                            }
                            return true;
                        });
                        setSelectedRowKeys(newSelectedRowKeys);
                    },
                },
                {
                    key: 'even',
                    text: 'Select Even Row',
                    onSelect: (record, changeableRowKeys) => {
                        // console.log(record)

                        let newSelectedRowKeys = [];
                        newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                            if (index % 2 !== 0) {
                                return true;
                            }
                            return false;
                        });
                        setSelectedRowKeys(newSelectedRowKeys);
                    },
                },
            ],
        };
        return < >
            <AntTable
                rowSelection={rowSelection}
                columns={columns}
                dataSource={list}
                pagination={true}
                style={{
                    maxHeight: 'calc(80vh - 45px)',
                    overflowY: 'auto',
                    // overflowX: 'auto',
                    minHeight: 'calc(80vh - 45px)',
                    width: '100%'
                }}
            />
        </>
    };

    const handleChange = (e) => { // handle change
        setSearch({ ...search, [e.target.name]: e.target.value })
    };

    //eslint-disable-next-line
    const handleChangeSelect = (e) => { // handle change select
        // console.log(e, "e")
        setSearch({ ...search, [e.name]: { value: e.value, label: e.label, name: e.name } })
    }

    //eslint-disable-next-line
    const clean = () => { // clean search
        setSearch({
            codigoBarra: "",
            busqueda: "",
            categoria: -1,
            tipoActivo: -1,
            tecnico: -1,
        })
    };

    // const realizarOT = (ordenTrabajoId, tipoOT, item) => { // realizar orden de trabajo
    //     let width = 950;
    //     let height = (window.screen.height / 1.2);
    //     let left = (window.screen.width / 2) - (width / 2) + 50;
    //     let top = (window.screen.height / 2) - (height / 2) - 25;
    //     let specs = `width=900,height=${height.toString()},left=${left.toString()},top=${top.toString()}scrollbars=yes,status=yes`;
    //     if (selectedCheckbox?.length > 0) {
    //         if (selectedCheckbox?.length > 1) {
    //             // const uniqueSelectedCheckbox = [...new Map(selectedCheckbox.map(item => [item['activo_id'], item])).values()]
    //             // uniqueSelectedCheckbox.forEach((value, i) => {
    //             //     let activo_id = value.activo_id;
    //             //     let plan_id = value.plan_id;
    //             window.open(`/reporteot?activo_id=${item.activo_id}&ordenTrabajoId=${ordenTrabajoId}&plan_id=${tipoOT === 1 ? item.plan_id : -1}&tipo_ot=${tipoOT}&fecha=${fechaOT}`, '_blank', specs)
    //             setShowModalOT(false)
    //             setTipoOT(1)
    //             setFechaOT(null)
    //             // })
    //         } else {
    //             console.log("🚀 ~ file: AssetsTree.js:553 ~ realizarOT ~ selectedCheckbox[0]:", selectedCheckbox[0])
    //             let activo_id = selectedCheckbox[0].activo_id
    //             let plan_id = selectedCheckbox[0].plan_id
    //             window.open(`/reporteot?activo_id=${activo_id}&ordenTrabajoId=${ordenTrabajoId}&plan_id=${tipoOT === 1 ? plan_id : -1}&tipo_ot=${tipoOT}&fecha=${fechaOT}`, '_blank', specs)
    //             setShowModalOT(false)
    //             setTipoOT(1)
    //             setFechaOT(null)

    //         }
    //     } else {
    //         alert("Debe seleccionar almenos un plan de mantenimiento")
    //     }
    // };
    //eslint-disable-next-line
    const ModalOT = () => {
        if (selectedCheckbox?.length > 0) {
            if (selectedCheckbox?.length > 1) {
                let selected = [];
                let selected_id = [];
                selectedCheckbox.forEach((value, i) => {
                    selected.push(value);
                    selected_id.push(value.activo_id);
                })
                setModalData(selected);
                setActivo_id(selected_id);
                setShowModalOT(true)
            } else {
                setModalData(selectedCheckbox[0])
                setShowModalOT(true)
                setActivo_id(selectedCheckbox[0].activo_id)
            }
        } else {
            alert("Debe seleccionar almenos un plan de mantenimiento")
        }
        // console.log("🚀 ~ file: AssetsTree.js:586 ~ ModalOT ~ selectedCheckbox[0]", selectedCheckbox[0])
    }

    const handleModal = (e) => {
        // console.log("🚀 ~ file: AssetsTree.js:528 ~ handleModal ~ e", e)
        if (typeof e === "boolean" && e === true) {
            setTipoOT(2)
        }
        if (typeof e === "boolean" && e === false) {
            setTipoOT(1)
        }
        if (typeof e === "string") {
            setFechaOT(e)
        }

    }

    const revisarOT = async () => {
        // revision/orden/11?fecha=2023-01-15&tipo=1
        if (fechaOT !== null) {
            const data = {
                fecha: moment(fechaOT, "DD/MM/YYYY").format("YYYY-MM-DD"),
                tipo: tipoOT,
                activo_id: activo_id
            }

            let data1 = [];
            if (modalData.length > 1) {
                modalData.forEach(async (value, i) => {
                    const revision = await Orden.revisionOT({ ...data, activo_id: value.activo_id });
                    if (revision) {
                        // console.log("🚀 ~ file: AssetsTree.js:609 ~ revisarOT ~ revision", revision)
                        data1.push({
                            ordenTrabajoId: revision.ordenTrabajoId,
                            tipoOT: tipoOT,
                            activo_id: value.activo_id,
                            plan_id: tipoOT === 1 ? value.plan_id : -1,
                            fechaOT: fechaOT,
                        })
                        // realizarOT(revision.ordenTrabajoId, tipoOT, value)
                        // `/reporteot?activo_id=${value.activo_id}&ordenTrabajoId=${revision.ordenTrabajoId}&plan_id=${tipoOT === 1 ? value.plan_id : -1}&tipo_ot=${tipoOT}&fecha=${fechaOT}`
                    } else {
                        alert("No se pudo generar las ordenes de trabajo")
                    }
                    // console.log("🚀 ~ file: AssetsTree.js:609 ~ revisarOT ~ revision", data1)
                    //update when is the last iteration
                    if (i === modalData.length - 1) {
                        setArryData(data1)
                        setOpen(true)
                        setShowModalOT(false)
                        setFechaOT(null)
                    }
                })

            } else {
                const revision = await Orden.revisionOT(data);
                if (revision) {
                    // console.log("🚀 ~ file: AssetsTree.js:609 ~ revisarOT ~ revision", revision)
                    let activo_id = selectedCheckbox[0].activo_id
                    let plan_id = selectedCheckbox[0].plan_id
                    data1.push({
                        ordenTrabajoId: revision.ordenTrabajoId,
                        tipoOT: tipoOT,
                        activo_id: activo_id,
                        plan_id: tipoOT === 1 ? plan_id : -1,
                        fechaOT: fechaOT
                    })
                    // realizarOT(revision.ordenTrabajoId, tipoOT)
                } else {
                    alert("No se pudo generar la orden de trabajo")
                }
                setArryData(data1)
                setOpen(true)
                setShowModalOT(false)
            }
        } else {
            alert("Debe seleccionar una fecha")
        }
    }
    //eslint-disable-next-line
    const printReport = async () => {
        if (selectedCheckbox?.length > 0) {
            const planes = selectedCheckbox.map((value, i) => {
                return value.plan_id
            });
            const planesIds = planes.join(",")
            console.log('print report', planesIds)
            const resp = await fetch(`${server}/report/mantenimientos`, {
                method: 'POST',
                body: JSON.stringify({ planesIds }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const blob = await resp.blob();
            download(blob, "Revision General de Trabajos.pdf");
        } else {
            alert("Debe seleccionar almenos un plan de mantenimiento")
        }
    }

    // const handleChangeFilter = (value) => {
    //     console.log(`selected ${value}`);
    // };
    const componentRef = React.useRef(null);

    const onBeforeGetContentResolve = React.useRef(null);

    const [text, setText] = React.useState("old boring text");
    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called"); // tslint:disable-line no-console
        setShowButton(false);
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 2000);
        });
    }, [setLoading, setText]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentRef.current]);

    const [showButton, setShowButton] = React.useState(false);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: `${DATOS_BASICOS.NOMBRE_EMPRESA} - ${moment().format('DD/MM/YYYY')} - ${arryData.length} Reportes`,
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    React.useEffect(() => {
        if (
            text === "New, Updated Text!" &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onBeforeGetContentResolve.current, text]);

    return (
        <Container menuId="tareas" submenu="resumen" taskToggled >
            {
                showPopup && <NewWindow
                    copyStyles
                    onUnload={() => {
                        setShowPopup(false)
                    }}
                    center="screen"
                    closeOnUnmount
                    features={
                        {
                            width: 1000,
                            height: 1000,
                        }
                    }
                >
                    <ReporteOrdenTrabajo
                        location={{
                            state: {
                                popupData
                            }
                        }}
                    />
                </NewWindow>
            }
            <section section="true" className="content">
                <Row className='d-flex justify-content-between'>
                    <Col lg={8} md={8} sm={12} >
                        <BreadcrumbComponent title="Resumen de Mantenimiento" b1="Inicio" href1="/home" b2="Mantenimiento" />
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <InputGroup className="float-right">
                            {/* <InputGroupAddon addonType="prepend">
                                <InputGroupText><i className="zmdi zmdi-search" /></InputGroupText>
                            </InputGroupAddon> */}
                            <Input
                                type="text"
                                // label="Búsqueda"
                                id="busqueda"
                                name="busqueda"
                                // onKeyUp={(event) => { if (event.keyCode === 13) this.onSearch(1) }}
                                className="form-control"
                                placeholder="código o descripción"
                                value={search.busqueda}
                                onChange={handleChange}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        getChild(1, search)
                                    }
                                }}
                            // value={this.state.search}
                            // onChange={(event) => this.setState({ search: event.target.value })} 
                            />
                            <InputGroupAddon addonType="append">
                                <Button block color={"info"}
                                    style={{}}
                                    className='mt-0 mb-0'
                                    onClick={() => getChild(1, search)}><i className="zmdi zmdi-search"
                                        type="submit"
                                    /></Button>

                                {/* <Button color="primary" onClick={() => onSearch(1)}></Button> */}
                            </InputGroupAddon>

                        </InputGroup>
                    </Col>
                </Row>
                <Col className="container-fluid" >
                    <Row className="clearfix">
                        <Col xs={4} sm={4} md={4} lg={3} xl={3} className="pr-0 " >
                            <Card className="container-fluid  pl-0 pr-0 mb-0">
                                <Col className="header">
                                    <h2><strong>Activos</strong></h2>
                                </Col>
                                <Col className="body pl-0 pr-0" >
                                    {dummyData && dummyData.length > 0 &&
                                        <Tree
                                            showLine
                                            showIcon
                                            blockNode
                                            defaultExpandedKeys={['-1']}
                                            onSelect={(selectedKeys, info) => handleNodeClicked(selectedKeys, info)}
                                            treeData={dummyData}
                                            autoExpandParent={true}
                                            defaultExpandParent={true}
                                            //change cursor to pointer
                                            style={{
                                                cursor: 'pointer',
                                                maxHeight: 'calc(90vh - 240px)',
                                                overflowY: 'auto',
                                                overflowX: 'auto',
                                                minHeight: 'calc(100vh - 240px)',
                                                //add scroll horizontal
                                                // minWidth: 250,
                                                // maxWidth: 250
                                            }}
                                        />
                                    }
                                </Col>
                            </Card>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={9} xl={9}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Card className="mb-0">
                                    <div className="header pb-0">
                                        <h2><strong>Planes de Mantenimiento de</strong> {searchTitle?.label}</h2>
                                    </div>

                                </Card>
                            </Col>
                            <Col className=" d-flex justify-content-between p-0">
                                <Col className="d-flex align-items-center">
                                    <Button color={disabledButton ? "secondary" : "info"} disabled={disabledButton} onClick={() => { history.push({ pathname: '/createorder', state: { selectedCheckbox } }) }}>Crear Orden</Button>
                                    <Button color={disabledButton ? "secondary" : "info"} disabled={disabledButton} onClick={() => { getDetail('detail'); }}>Detalles</Button>
                                    <Button color={disabledButton ? "secondary" : "info"} disabled={disabledButton} onClick={() => { getDetail('document'); }}>Documentos</Button>
                                    <Button color={disabledButton ? "secondary" : "info"} disabled={disabledButton} onClick={() => { getOrdenesTrabajo() }}>Revisiones</Button>

                                </Col>
                                {/* <Col className="d-flex align-items-center justify-content-end">
                                        <Button color={"info"} onClick={() => { printReport() }}>
                                            <i className='zmdi zmdi-assignment' />
                                        </Button>
                                    </Col> */}
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                {/* <Card className="mb-0" */}
                                {/* style={{
                                        maxHeight: 'calc(80vh - 45px)',
                                        overflowY: 'auto',
                                        overflowX: 'auto',
                                        minHeight: 'calc(80vh - 45px)',
                                    }}
                                > */}
                                {printTableView()}
                                {/* </Card> */}

                            </Col>
                        </Col>
                    </Row>
                </Col>
            </section>
            <ModalRealizarOT show={showModalOT} onHide={() => { setShowModalOT(false); setFechaOT(null) }} data={modalData} RealizarOT={revisarOT} handleModal={handleModal} />
            <ModalDetail show={showModal} onHide={() => setShowModal(false)} data={data} />
            <ModalOrderJob show={showOrdenes} onHide={() => setShowOrdenes(false)} data={historialOrdenes} dataReporst={(value) => { setArryData(value); setOpen(true); setShowOrdenes(false) }} />
            <ModalRevisionsJob show={showRevisiones} onHide={() => setShowRevisiones(false)} data={historialRevisiones} />
            <Modal
                title=''
                centered
                open={open}
                onOk={() => { handlePrint(); setShowButton(true) }}
                onCancel={() => setOpen(false)}
                width={1000}
                okText={loading ? "Cargando..." : "Imprimir todo"}
                cancelText="Cerrar"
                okButtonProps={{
                    icon: loading ? <Spinner size='sm' /> : <PrinterOutlined style={{ display: 'inline-flex' }} />,
                    style: { backgroundColor: '#0c7ce6', color: '#fff', alignItems: 'center' },
                    disabled: loading ? true : false,
                }}
                closable={false}
                bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}
            >
                <div ref={componentRef} text={text} >
                    {arryData.map((value, i) => {
                        if (i === arryData.length - 1) {
                        }
                        return (
                            <ReporteOrdenTrabajo key={i} data={value} show={showButton} />
                        )
                    })}
                </div>
            </Modal>
        </Container>
    )
}