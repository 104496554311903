import React from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import { Label, Input, Button, Alert, Col, Breadcrumb, BreadcrumbItem, Row, Card, FormGroup, Spinner } from 'reactstrap';
import Api from "../modules/user"
import Select from 'react-select';
import { AsyncPaginate } from "react-select-async-paginate";
import history from '../history';
import moment from "moment"
import "moment/locale/es";
import Plan from "../modules/plan"
import OT from "../modules/ordenTrabajo"
import { AvForm, AvField } from 'availity-reactstrap-validation';
const defaultAdditional = {
    page: 1
};
export default class OrderRequest extends React.Component {
    constructor(props) {
        super(props)
        moment.locale("es");
        this.state = {
            isLoading: false,
            checkAll: false,
            asignadoa: [],
            visible: false,
            error: false,
            warning: false,
            resumen: "",
            asignadoaSel: null,
            asignarError: "none",
            activoError: "none",
            activo: null
        }
    }

    async componentDidMount() {
        this.getTecnicos()
    }

    getTecnicos = () => {
        let asignadoa = []
        Api.list({ filtro: 1 }).then((response) => {
            if (response) {
                const res = response.data
                for (const key in res) {
                    asignadoa.push({
                        value: res[key].usuarioId,
                        label: res[key].persona.nombre + " " + res[key].persona.apellido
                    })
                }
            }
        }).finally(() => {
            this.setState({
                asignadoa,
                asignadoaSel: null,
                loading: false
            })
        })
    }

    selectAsignadoa = selectedOption => {
        this.setState(
            { asignadoaSel: selectedOption }
        );
    };

    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }


    onSearchChange = (selectedOption) => {
        if (selectedOption) {
            this.setState({ activo: selectedOption });
        }
    };

    loadOptions = async (search, prevOptions, { page }) => {
        if (search === "") search = "a";
        let activos = []
        const res = await Plan.listActivoBySearch({ search, page, filtro: 1 });
        const response = res.data
        const totalPages = res.totalPages;
        const currentPage = res.currentPage;
        if (response) {
            const response = res.data
            for (let key in response) {

                activos.push({
                    label: `${response[key].nombre} (${response[key].codigo})`,
                    value: response[key].activoId,
                    tipo: response[key].tiposActivo.tipoActivoId
                })
            }
        }
        const results = {
            options: activos,
            hasMore: currentPage !== totalPages,
            additional: {
                page: page + 1
            }
        }
        return results;
    };

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    save = () => {
        const { resumen, activo, asignadoaSel, checkAll } = this.state;
        this.setState({ isLoading: true })
        OT.sendRequest({
            descripcion: resumen,
            activo: {
                activoId: activo.value,
                descripcion: activo.label
            },
            enviarTodos: checkAll,
            usuarioAsignado: checkAll ? null : {
                usuarioId: asignadoaSel.value
            }
        }).then((res) => {
            if (res) {
                this.setState({
                    visible: res.success,
                    message: res.message
                })
            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    validate() {
        //validate selects
        const { activo, checkAll, asignadoaSel } = this.state;
        this.setState({
            activoError: activo === null ? "block" : "none",
            asignarError: !checkAll && asignadoaSel === null ? "block" : "none",
        },
            () => this.form.submit())
    }

    handleSubmit(event, errors, values) {
        //validate select
        const { activoError, asignarError } = this.state;
        if (activoError === "none" && asignarError === "none") {
            this.save()
        }
    }

    reset = () => {
        window.location.reload();
    }


    render() {
        const { loading } = this.state;
        if (loading) return <Loading />;
        return (
            <Container menuId="tareas" submenu="enviar" taskToggled>
                <section className="content">
                    <div className="block-header">
                        <Row >
                            <Col lg={7} md={6} sm={12} >
                                <h2>Enviar Solicitud de Orden de Trabajo</h2>
                                <Breadcrumb tag="ul" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Tareas</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="li">Enviar solicitud de orden de Trabajos</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"></Button>
                            </Col>
                            <Col lg={5} md={6} sm={12} >
                                {<Button color={"success"} className="btn-icon float-right" type="button" onClick={() => this.reset()}>
                                    <i className="zmdi zmdi-plus" />
                                </Button>}
                            </Col>
                        </Row>
                    </div>
                    <AvForm onValidSubmit={() => this.handleSubmit()}
                        ref={e => this.form = e}>
                        <Card className="card">
                            <Col className="body">
                                <div className="header">
                                    <h2><strong>Datos</strong> de la Solicitud</h2>
                                </div>
                                <Row className="clearfix">
                                    <Col md={12} lg={4}>
                                        <FormGroup>
                                            <label>Buscar activo</label>
                                            <AsyncPaginate noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.activoError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                additional={defaultAdditional}
                                                placeholder={"Escriba para buscar activo"}
                                                cacheOptions
                                                defaultOptions
                                                loadingMessage={() => "Buscando activo.."}
                                                value={this.state.activo}
                                                onChange={(e) => { this.onSearchChange(e); }}
                                                loadOptions={this.loadOptions}

                                                onInputChange={this.handleInputChange}
                                                onBlur={() => { this.state.activo === null ? this.setState({ activoError: "block" }) : this.setState({ activoError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.activoError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione el activo</div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} lg={4}>
                                        <FormGroup >
                                            <label>Enviar a:</label>
                                            <Select noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: !this.state.checkAll && this.state.asignarError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                placeholder={"Enviar a"}
                                                value={this.state.asignadoaSel}
                                                onChange={this.selectAsignadoa}
                                                options={this.state.asignadoa}
                                                isDisabled={this.state.checkAll}
                                                onBlur={() => { !this.state.checkAll && this.state.asignadoaSel === null ? this.setState({ asignarError: "block" }) : this.setState({ asignarError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.asignarError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione un usuario</div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} lg={4}>
                                        <FormGroup >
                                            <label></label>
                                            <div className="checkbox" >
                                                <Input id="procheck1" type="checkbox" checked={this.state.checkAll} onChange={() => this.setState({ checkAll: !this.state.checkAll })} />
                                                <Label htmlFor="procheck1">Enviar a todos los usuarios</Label>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="clearfix">
                                    <Col md={12} lg={12}>
                                        <FormGroup >
                                            <label>Resumen</label>
                                            <AvField name="resumen"
                                                type="textarea"
                                                value={this.state.resumen}
                                                onChange={(event) => this.setState({ resumen: event.target.value })}
                                                className="form-control"
                                                placeholder="Resumen"
                                                rows={3}
                                                validate={{
                                                    required: { value: false, errorMessage: '' },
                                                    pattern: { value: '^[A-z0-9À-ž .,:-\n]+$', errorMessage: 'El resumen debe contener solo letras y numeros' },
                                                    maxLength: { value: 150, errorMessage: 'El resumen debe tener menos de 150 caracteres' }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button disabled={this.state.disabled} onClick={() => this.validate()} className="btn  badge-success btn-lg">{this.state.isLoading ? <Spinner color="light" size="sm" /> : "Enviar"}</Button>
                                <Button onClick={() => history.goBack()} className="btn badge-default btn-lg">Cancelar</Button>
                            </Col>
                        </Card>
                    </AvForm>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        No se pudo crear el nuevo usuario
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                </section>
            </Container>
        )
    }
}