import Reportes from '../services/reportes'

export default {

  //create new season
  async dashboard(data) {
    let result;
    try {
      const id = localStorage.getItem('id_token')
      data.id = id;
      result = await Reportes.dashboard(data);
    } catch (error) {
      result = false;
    }
    return result ? result.data : false;
  },
  async solicitudes(data) {
    let result;
    try {
      const id = localStorage.getItem('id_token')
      data.id = id;
      result = await Reportes.solicitudes(data);
    } catch (error) {
      result = false;
    }
    return result ? result.data : false;
  },
  async indicadoresMantenimiento(data) {
    let result;
    try {
      result = await Reportes.indicadoresMantenimiento(data);
    } catch (error) {
      result = false;
    }
    return result ? result.data : false;
  }
}