import React from 'react';
import Loading from "../home/Loading"
import SuperContainer from "../Container"
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css'
import 'react-big-calendar/lib/sass/styles.scss';
import { Input, Label, Table, Button, Row, Col, Breadcrumb, BreadcrumbItem, Card, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import Plan from "../modules/plan"
import moment from "moment"
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import _ from "lodash"
import CalendarEvent from "../components/CalendarEvent"
import TableHeaderlist from '../components/TableHeaderList';
import TableItemlist from '../components/TableItemList';
import SweetAlert from 'react-bootstrap-sweetalert';
import history from "../history";
import Select from 'react-select';
import User from "../modules/user"
import Proveedor from "../modules/proveedor"

const localizer = momentLocalizer(moment)


export default class CalendarTask extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            events: [],
            monthEvents: [],
            modal: false,
            actividades: [],
            userRol: "",
            confirm: false,
            tipoOrderCache: -1,
            tecnicoProveedor: { value: 0, label: "Ninguno" },
            tecnicos: [],
            dataProveedor: [],
            proveedor: [],
            checked: false,
            prioridad: [
                { value: "1", label: "Alta" },
                { value: "2", label: "Media" },
                { value: "3", label: "Baja" },
            ],
            prioridadSel: { value: "1", label: "Alta" },
            monthView: false,

        }
    }

    componentDidMount() {
        let userRol = (localStorage.getItem('user_rol'));
        if (userRol === "4" || userRol === "5") {
            history.replace('/404');
        }
        this.setState({ userRol: parseInt(userRol, 10) })
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
        this.fillCalendar(startOfMonth, endOfMonth, true);

        this.getTecnicos();
        this.getProveedores();
    }

    getTecnicos = () => {
        let tecnicos = []
        User.list({ filtro: 1 }).then((response) => {
            if (response) {
                const res = response.data
                for (const key in res) {
                    tecnicos.push({
                        value: res[key].usuarioId,
                        label: res[key].persona.nombre + " " + res[key].persona.apellido
                    })
                }
            }
        }).finally(() => {
            this.setState({
                tecnicos,
                loading: false
            })
        })
    }

    getProveedores = () => {
        let asignadoa = []
        Proveedor.listBy({}).then((res) => {
            if (res) {
                for (const key in res) {
                    asignadoa.push({
                        value: res[key].proveedorId,
                        label: res[key].persona.nombre + " " + res[key].persona.apellido
                    })
                }
            }
        }).finally(() => {
            this.setState({
                dataProveedor: asignadoa,
                loading: false
            })
        })
    }
    fillCalendar(start, end, fill) {
        let events = []
        Plan.calendar({ from: start, to: end }).then((response) => {
            if (response) {
                for (const key in response.data) {
                    const event = response.data[key];
                    events.push({
                        id: event.id,
                        title: event.descripcion,
                        start: moment(event.fecha).toDate(),
                        end: moment(event.fecha).add(2, "hours").toDate(),//agregar 2 horas a cada evento
                        momentStart: moment(event.fecha),
                        //allDay: false,
                        className: 'bg-info',
                        checked: false,
                        planId: event.planId,
                        key: event.id,
                        hasOrder: event.hasOrder,
                    })
                }
            }
        }).finally(() => {
            if (fill) {
                this.setState({
                    monthEvents: _.take(_.filter(_.orderBy(events, ['momentStart'], ['asc']), (o) => { return o.momentStart.isSameOrAfter(moment()); }), 3),
                })
            }
            this.setState({
                events,
                loading: false
            })
        })
    }

    updateCalendar = (info) => {
        if (_.isArray(info)) {
            const activeStart = moment(info[0]).format('YYYY-MM-DD');
            const activeEnd = moment(info[info.length > 1 ? (info.length - 1) : 0]).format('YYYY-MM-DD');
            this.fillCalendar(activeStart, activeEnd, false)
        } else {
            const activeStart = moment(info.start).format('YYYY-MM-DD');
            const activeEnd = moment(info.end).format('YYYY-MM-DD');
            this.fillCalendar(activeStart, activeEnd, false)
        }
    }

    getActivities = (event) => {
        const { events } = this.state;
        // console.log("🚀 ~ file: Calendar.js:154 ~ CalendarTask ~ events", event)
        // console.log( _.filter(events ,  (e)=> { return e.momentStart.isSame(event.momentStart) }))
        this.setState({
            event,
            actividades: _.filter(events, (e) => { return e.momentStart.isSame(event.momentStart) }),
            modal: true
        })
    }

    updateChecked = (id, value) => {
        const { actividades } = this.state;
        let arr = actividades;
        const index = arr.findIndex(element => element.id === id);
        if (index >= 0) {
            arr[index].checked = value;
        }
        this.setState({
            actividades: arr,
            checked: !value
        })
    }

    completeGenerateOrder = () => {
        const { event, actividades, tipoOrderCache, tecnico, proveedor, prioridadSel } = this.state;
        console.log(event, 'event')
        console.log(actividades, 'actividades')
        console.log(tipoOrderCache, 'tipoOrderCache')
        console.log(tecnico, 'tecnico')
        console.log(proveedor, 'proveedor')
        console.log(prioridadSel, 'prioridadSel')
        let descripcion = []
        for (const key in actividades) {
            if (actividades[key].checked) {
                descripcion.push(actividades[key].title)
            }
        }
        let data = {
            id: event.planId,
            fecha: moment(event.start).format("YYYY-MM-DD HH:mm"),
            descripcion,
            tipoOrden: tipoOrderCache,
            tecnicoId: tecnico ? tecnico.value : null,
            proveedorId: proveedor ? proveedor.value : null,
            prioridad: prioridadSel.value
        }
        console.log(data, 'data')
        Plan.generateOrder({ data }).then((res) => {
            if (res) {
                for (const key in actividades) {
                    actividades[key].checked = false;
                }
                alert(res.message)
                this.setState({
                    modal: false,
                    confirm: false,
                    checked: false,
                    tecnicoProveedor: { value: 0, label: "Asignar plan de mantenimiento a" },
                    actividades,
                    prioridadSel: { value: "1", label: "Alta" }
                })
                const index = this.state.events.findIndex(element => element.id === event.id);
                const oldevents = this.state.events;
                oldevents[index].hasOrder = true;
                this.setState({
                    events: oldevents
                })
            } else {
                alert("Error al crear orden")
            }
        }).catch(err => {
            alert("Error al crear orden")
        })
    }

    generateOrder = (tipoOrden) => {
        const { event, actividades } = this.state;
        const index = actividades.findIndex((t) => t.checked === true);
        if (index < 0) {
            alert("Debe Seleccionar una actividad")
        } else {
            Plan.validateOrder({
                id: event.planId,
            }).then((response) => {
                // console.log(response)
                if (response && response.success) {
                    if (response.hasOrder) {
                        this.setState({
                            tipoOrderCache: tipoOrden,
                            confirm: true
                        })
                    } else {
                        this.setState({
                            tipoOrderCache: tipoOrden,
                        }, () => this.completeGenerateOrder())
                    }
                }
            }).catch(err => {
                alert("Intente de nuevo")
            })
        }

    }
    closeModal = () => {
        const { actividades } = this.state;
        let arr = actividades;
        // console.log(arr)
        for (const key in arr) {
            arr[key].checked = false;
        }

        this.setState({
            modal: false,
            checked: false,
            tecnicoProveedor: { value: 0, label: "Asignar plan de mantenimiento a" },
            // tecnicos:[],
            // proveedor:[],
            actividades: arr,
            prioridadSel: { value: "1", label: "Alta" }
        })
    }

    selectPrioridad = selectedOption => {
        this.setState(
            { prioridadSel: selectedOption }
        );
    };

    // eventPropGetter = useCallback(
    //     (event, start, end, isSelected) => ({
    //         ...(isSelected && {
    //             style: {
    //                 backgroundColor: '#000',
    //             },
    //         }),
    //         ...(moment(start).hour() < 12 && {
    //             className: 'powderBlue',
    //         }),
    //         ...(event.title.includes('Meeting') && {
    //             className: 'darkGreen',
    //         }),
    //     }),
    //     []
    // )

    eventPropGetter = (event, start, end, isSelected) => {
        //console.log(start, end, isSelected)
        return ({
            // ...(isSelected && { style: { backgroundColor: '#000' } }),
            // ...(event.hasOrder && { style: { backgroundColor: '#04BE5B' } }),
            ...(this.state.monthView && event.hasOrder && {
                className: 'darkGreen',
            }),
        })
    }

    onView = (view) => {
        this.setState({
            monthView: view !== 'agenda'
        })
    }


    render() {
        const { loading } = this.state;
        if (loading) return <Loading />;
        return (
            <SuperContainer menuId="tareas" submenu="planes" taskToggled>
                <section className="content page-calendar"
                    style={{
                        height: "calc(95vh)",
                    }}
                >
                    <div className="body_scroll">
                        <div className="block-header">
                            <Row >
                                <Col lg={7} md={6} sm={12} >
                                    <h2>Planes de Mantenimientos</h2>
                                    <Breadcrumb tag="ul" className="breadcrumb">
                                        <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                        <BreadcrumbItem className="breadcrumb-item" tag="span">Tareas</BreadcrumbItem>
                                        <BreadcrumbItem className="breadcrumb-item active" tag="li">Planes de Mantenimientos</BreadcrumbItem>
                                    </Breadcrumb>
                                    <Button color="primary" className="btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></Button>
                                </Col>
                            </Row>
                        </div>
                        {/* <Container className="container-fluid"> */}
                        <Row className="row">
                            <Col md={12} lg={9} xl={9}>
                                <Card >
                                    <div className="body">
                                        <Calendar
                                            popup
                                            style={{ height: "calc(70vh)" }}
                                            defaultView={Views.MONTH}
                                            length={15}
                                            views={['month', 'week', 'day', 'agenda']}
                                            onView={this.onView}
                                            // onSelectEvent={event => this.state.userRol < 3 ? this.getActivities(event) : console.log(event)}
                                            messages={{
                                                month: 'Mes',
                                                day: 'Día',
                                                week: 'Semana',
                                                today: "Hoy",
                                                next: ">",
                                                previous: "<",
                                                date: "Fecha",
                                                time: "Hora",
                                                event: "Actividad",
                                                allDay: "Todo el Día",
                                                showMore: (count) => count + " Más"
                                            }}
                                            onRangeChange={(info) => this.updateCalendar(info)}
                                            step={30}
                                            timeslots={2}
                                            min={new Date(new Date().setHours(8, 0, 0))}
                                            max={new Date(new Date().setHours(18, 0, 0))}
                                            localizer={localizer}
                                            events={this.state.events}
                                            startAccessor="start"
                                            endAccessor="end"
                                            formats={{
                                                agendaHeaderFormat: ({ start, end }, culture, localizer) =>
                                                    moment(start).format("MMM DD") + ' — ' +
                                                    moment(end).format("MMM DD")
                                            }}
                                            eventPropGetter={this.eventPropGetter}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col md={12} lg={3} xl={3} >
                                <Card className="card body" key="card_event_list"
                                    style={{ height: "calc(70vh)" }}
                                >
                                    <div className="event_list body">
                                        {this.state.userRol < 3 && <Button color="info" block className="waves-effect" onClick={() => window.location.href = '/addevent'}>Nuevo Plan</Button>}
                                        {this.state.userRol < 3 && <Button color="info" block className="waves-effect" onClick={() => window.location.href = '/events'}>Ver Planes</Button>}
                                        {
                                            this.state.monthEvents.map((e, i) => {
                                                return (
                                                    <CalendarEvent fecha={e.start}
                                                        key={i}
                                                        nombre={"mantenimiento"}
                                                        descripcion={e.title} />
                                                )
                                            })
                                        }
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        {/* </Container> */}
                    </div>
                </section>
                <Modal isOpen={this.state.modal} toggle={() => this.closeModal()} className={""}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>Generar orden para las siguientes actividades</ModalHeader>
                    <ModalBody>
                        <Row className="clearfix">
                            <Col sm={12}>
                                <Table responsive className={"mb-0 c_list"}>
                                    <thead className="thead-light">
                                        <tr>
                                            <TableHeaderlist title="#" />
                                            <TableHeaderlist title="Descripción" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.actividades.map((e, index) => {
                                                return (
                                                    <tr key={index} >
                                                        <td>
                                                            <div className="checkbox">
                                                                <Input id={"delete_" + e.id} type="checkbox" checked={e.checked} onChange={() => this.updateChecked(e.id, !e.checked)} />
                                                                <Label for={"delete_" + e.id}></Label>
                                                            </div>
                                                        </td>
                                                        <TableItemlist item={e.title} />
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <div className="header">
                            <FormGroup className="form-group">
                                <label>Seleccione prioridad:</label>
                                <Select noOptionsMessage={() => 'Sin Resultados'}
                                    styles={{
                                        container: base => ({
                                            ...base,
                                            border: this.state.prioridadError === "block" ? "1px solid #ced4da" : "none",
                                            borderRadius: ".25rem",
                                            borderColor: "#dc3545"
                                        }),
                                    }}
                                    placeholder={"Prioridad"}
                                    value={this.state.prioridadSel}
                                    onChange={this.selectPrioridad}
                                    options={this.state.prioridad}
                                    onBlur={() => { this.state.prioridadSel === null ? this.setState({ prioridadError: "block" }) : this.setState({ prioridadError: "none" }) }}
                                />
                                <div style={{ display: this.state.prioridadError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione la prioridad de la orden</div>
                            </FormGroup>
                            <FormGroup>
                                <label>Asignar orden a:</label>
                                <Select noOptionsMessage={() => 'Sin Resultados'}
                                    placeholder={"Seleccione a quien va dirigida"}
                                    value={this.state.tecnicoProveedor}
                                    onChange={(tecnicoProveedor) => this.setState({ tecnicoProveedor })}
                                    options={[
                                        { value: 0, label: "Ninguno" },
                                        { value: 1, label: "Técnico" },
                                        { value: 2, label: "Proveedor" }
                                    ]}
                                    styles={{
                                        container: base => ({
                                            ...base,
                                            border: this.state.tipoMantError === "block" ? "1px solid #ced4da" : "none",
                                            borderRadius: ".25rem",
                                            borderColor: "#dc3545"
                                        })
                                    }}
                                />
                            </FormGroup>
                            <label>Asignado a:</label>
                            <FormGroup>
                                {this.state.tecnicoProveedor.value === 0 ?
                                    <Select placeholder={"Ninguno"} isDisabled /> :
                                    this.state.tecnicoProveedor.value === 1 ?
                                        <Select noOptionsMessage={() => 'Sin Resultados'}
                                            styles={{
                                                container: base => ({
                                                    ...base,
                                                    border: this.state.tipoMantError === "block" ? "1px solid #ced4da" : "none",
                                                    borderRadius: ".25rem",
                                                    borderColor: "#dc3545"
                                                }),
                                            }}
                                            placeholder={"Seleccione Técnico Asignado"}
                                            value={this.state.tecnico}
                                            onChange={(tecnico) => this.setState({ tecnico })}
                                            options={this.state.tecnicos}
                                            isDisabled={this.state.tecnicoProveedor.value === 0}
                                        /> :
                                        this.state.tecnicoProveedor.value === 2 &&
                                        <Select noOptionsMessage={() => 'Sin Resultados'}
                                            styles={{
                                                container: base => ({
                                                    ...base,
                                                    border: this.state.tipoMantError === "block" ? "1px solid #ced4da" : "none",
                                                    borderRadius: ".25rem",
                                                    borderColor: "#dc3545"
                                                }),
                                            }}
                                            placeholder={"Seleccione Proveedor Asignado"}
                                            value={this.state.proveedor}
                                            onChange={(proveedor) => this.setState({ proveedor })}
                                            options={this.state.dataProveedor}
                                            isDisabled={this.state.tecnicoProveedor.value === 0}
                                        />}
                            </FormGroup>
                        </div>
                        <SweetAlert info showCancel
                            confirmBtnText="Si, Generar"
                            cancelBtnText="No, Cancelar"
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="default"
                            focusCancelBtn
                            title={"Confirmar"}
                            onCancel={() => this.setState({ confirm: false })}
                            onConfirm={this.completeGenerateOrder}
                            show={this.state.confirm}
                            allowEscape={true}
                            showCloseButton={true}
                        >
                            Esta actividad ya ha sido generada anteriormente. ¿Generar de nuevo?
                        </SweetAlert>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.tecnicoProveedor.value !== 2 ? <Button color="secondary" className="waves-effect btn-round" onClick={() => this.generateOrder(1)}>Generar O.Trabajo</Button> : null}
                        {this.state.tecnicoProveedor.value !== 1 ? <Button color="secondary" className="waves-effect btn-round" onClick={() => this.generateOrder(2)}>Generar O.Servicio</Button> : null}
                        <Button color="danger" className="waves-effect" onClick={() => this.closeModal()}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </SuperContainer>

        )
    }
}