import React from 'react';


export default class Loading extends React.Component {
    render(){
        return(
            <div className="page-loader">
                <div className="loader">
                    <div className="m-t-30"><img className="zmdi-hc-spin" src="/logo192.png" width={48} height={48} alt="Cargando" /></div>
                    <p>Por favor espere...</p>
                </div>
            </div>
        )
    }
}