import React, { Component } from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import Api from "../modules/activos"
import { Badge, Row, Col, Button, Alert, Breadcrumb, BreadcrumbItem, Table, InputGroup, InputGroupAddon, InputGroupText, Input, Pagination, PaginationItem, PaginationLink, Card } from 'reactstrap';
import history from '../history';
import SweetAlert from 'react-bootstrap-sweetalert';
import TableHeaderlist from '../components/TableHeaderList';
import TableItemlist from '../components/TableItemList';
import NotificationAlert from 'react-notification-alert';
import "react-notification-alert/dist/animate.css";
const options = {
    place: "br",
    message: "Sin Resultados",
    type: "warning",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
const options2 = {
    place: "br",
    message: "Error Inesperado",
    type: "danger",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
export default class Building extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            confirm: false,
            activoId: undefined,
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "",
            loading: true,
            view: "table",
            totalPages: 0,
            currentPage: 0,
            pages: [],
            search: "",
            userRol: ""
        }
        this.deleteLocalizacion = this.deleteLocalizacion.bind(this)
    }

    componentDidMount() {
        let userRol = (localStorage.getItem('user_rol'));
        this.setState({ userRol: parseInt(userRol, 10) })
        this.listLocalizaciones(1);
    }

    async listLocalizaciones(page) {
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        Api.listLocalizaciones({ page }).then(res => {
            if (res) {
                const response = res.data;
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    data.push({
                        id: response[key].localizacionId,
                        categoriaActivo: "2",
                        nombre: response[key].nombre,
                        codigo: response[key].codigo,
                        localizacion: response[key].localizacionDescripcion,
                        tipoEquipo: response[key].categoriaLocalizacion ? response[key].categoriaLocalizacion.descripcion : "",
                        esPadre: response[key].esPadre,
                        padreId: response[key].tieneHijos ? response[key].localizacion.localizacionId : "-1",
                        esHijo: response[key].tieneHijos,
                        fueraServicio: response[key].fueraServicio
                    })
                }
                if (totalPages === 0) this.refs.notify.notificationAlert(options);
            }
        }).catch((err) => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                data,
                pages,
                currentPage,
                totalPages,
                loading: false
            })
        })
    }

    onSearch = (page) => {
        const { search } = this.state;
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        if (search !== "") {
            Api.search({ search, page }).then((res) => {
                if (res.success) {
                    const response = res.data
                    totalPages = res.totalPages;
                    currentPage = res.currentPage;
                    for (let key in response) {
                        data.push({
                            id: response[key].localizacionId,
                            categoriaActivo: "2",
                            nombre: response[key].nombre,
                            codigo: response[key].codigo,
                            localizacion: response[key].localizacionDescripcion,
                            tipoEquipo: response[key].categoriaLocalizacion ? response[key].categoriaLocalizacion.descripcion : "",
                            esPadre: response[key].esPadre,
                            padreId: response[key].tieneHijos ? response[key].localizacion.localizacionId : "-1",
                            esHijo: response[key].tieneHijos,
                            fueraServicio: response[key].fueraServicio
                        })
                    }
                    if (totalPages === 0) this.refs.notify.notificationAlert(options);
                }
            }).catch((err) => {
                this.refs.notifyError.notificationAlert(options2);
            }).finally(() => {
                for (let i = 0; i < totalPages; i++) {
                    pages.push((i + 1));
                }
                this.setState({
                    data,
                    pages,
                    currentPage,
                    totalPages,
                    loading: false
                })
            })
        } else {
            this.listLocalizaciones(page);
        }
    }

    deleteLocalizacion = () => {
        const { localizacionoId } = this.state;
        this.setState({ confirm: false, visible: true })
        //console.log(localizacionoId)
        Api.deleteLocalizacion({ id: localizacionoId }).then((response) => {
            if (response) {
                this.setState({
                    visible: response.success,
                    warning: response.warning,
                    message: response.message,
                    confirm: false
                }, () => this.listLocalizaciones(1))
            } else {
                this.setState({ error: true, confirm: false })
            }
        })
    }

    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }

    showDialog = (value) => {
        this.setState({ confirm: true, localizacionoId: value })
    }

    printTableView = () => {
        const { data } = this.state;
        return <Table hover responsive className="mb-0 c_list c_table break_table theme-color">
            <thead>
                <tr>
                    <TableHeaderlist title="#" />
                    <TableHeaderlist title="Nombre" />
                    <TableHeaderlist title="Tipo" />
                    <TableHeaderlist title="Localización" />
                    {this.state.userRol < 3 && <TableHeaderlist title="Accion" />}
                </tr>
            </thead>
            <tbody>
                {
                    data.map((value, i) => {
                        return (
                            <tr key={i}>
                                <TableItemlist item={((this.state.currentPage - 1) * 15) + (i + 1)} style={{ width: "60px" }} />
                                <td>
                                    {value.nombre}<br />
                                    <small>{value.codigo} {value.fueraServicio && <Badge color="danger">Fuera de Servicio</Badge>}</small>
                                </td>
                                <TableItemlist item={value.tipoEquipo} />
                                <TableItemlist item={value.localizacion} />
                                {this.state.userRol < 3 && <td style={{ width: "150px" }}>
                                    <Button color="warning" size="sm" onClick={() => this.updateActivo(value.id, true)}><i className="zmdi zmdi-eye" /></Button>
                                    <Button onClick={() => this.updateActivo(value.id)} className="btn btn-primary btn-sm"><i className="zmdi zmdi-edit" /></Button>
                                    <Button onClick={() => this.showDialog(value.id)} className="btn btn-danger btn-sm"><i className="zmdi zmdi-delete" /></Button>
                                </td>}
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    }

    updateActivo(id, e) {
        history.push('/createassestbuildings', { id, e });
    }

    render() {
        return (
            <Container menuId="activos" submenu="localizaciones" assetsToggled>
                {this.state.loading && <Loading />}
                <NotificationAlert ref="notify" />
                <NotificationAlert ref="notifyError" />
                <section className="content">
                    <div className="container-fluid">
                        <div >
                            <div className="block-header">
                                <Row >
                                    <Col lg={7} md={6} sm={12} >
                                        <h2>Localizaciones</h2>
                                        <Breadcrumb tag="ul" listTag="li" className="breadcrumb">
                                            <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                            <BreadcrumbItem className="breadcrumb-item" tag="span">Activos</BreadcrumbItem>
                                            <BreadcrumbItem className="breadcrumb-item" active tag="span">Localizaciones</BreadcrumbItem>
                                        </Breadcrumb>
                                        <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"></Button>
                                    </Col>
                                    <Col lg={5} md={6} sm={12} >
                                        {this.state.userRol < 3 &&
                                            <Button color="success" className="btn-icon float-right" type="button" onClick={() => history.push("/createassestbuildings")}>
                                                <i className="zmdi zmdi-plus" />
                                            </Button>
                                        }
                                        <InputGroup className="float-right">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText><i className="zmdi zmdi-search" /></InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="text"
                                                onKeyUp={(event) => { if (event.keyCode === 13) this.onSearch(1) }}
                                                className="form-control"
                                                placeholder="Busqueda..."
                                                value={this.state.search}
                                                onChange={(event) => this.setState({ search: event.target.value })} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </div>
                            <div className="container-fluid">
                                <Row className="clearfix">
                                    <Col lg={12} >
                                        <Card className="card">
                                            {this.printTableView()}
                                            <div className="justify-content-center">
                                                <Pagination className="pagination pagination-success mt-4">
                                                    {
                                                        this.state.pages.map((value, index) => {
                                                            return (
                                                                <PaginationItem key={index} active={value === this.state.currentPage}>
                                                                    <PaginationLink href="#" onClick={() => this.onSearch(value)}>{value}</PaginationLink>
                                                                </PaginationItem>
                                                            );
                                                        })
                                                    }
                                                </Pagination>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        Error al eliminar
                    </Alert>
                </section>
                <SweetAlert warning showCancel
                    confirmBtnText="Sí, eliminar"
                    cancelBtnText="No"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    focusCancelBtn
                    title={"¿Estas Seguro?"}
                    onConfirm={this.deleteLocalizacion}
                    onCancel={() => this.setState({ confirm: false })}
                    show={this.state.confirm}
                >
                    Ésta acción es irreversible
                </SweetAlert>
            </Container>
        )
    }
}