import React from 'react';
import { Col, Row, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';

export default function BreadcrumbComponent(props) {
    const { title, b1, href1, b2, href2, b3, href3 } = props;
    return (
        <Col className="block-header">
            <Row >
                <Col lg={7} md={8} sm={12}>
                    <h2>{title}</h2>
                    <Breadcrumb tag="ul" listTag="li" className="breadcrumb">
                        <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href={href1}><i className="zmdi zmdi-home" /> {b1}</BreadcrumbItem>
                        {b2 && <BreadcrumbItem className="breadcrumb-item" tag="span" href={href2}>{b2}</BreadcrumbItem>}
                        {b3 && <BreadcrumbItem className="breadcrumb-item" active tag="span" href={href3}>{b3}</BreadcrumbItem>}
                    </Breadcrumb>
                    <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"></Button>
                </Col>
            </Row>
        </Col>
    )
}