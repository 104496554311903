import React from 'react';
import { Row, Col, Card, Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import ReportCard from './ReportCard';
import C3Chart from 'react-c3js';
import 'c3/c3.css';
import DashboardCard from './DashboardCard';
import TableHeaderlist from './TableHeaderList';
import TableItemlist from './TableItemList';

const DashboardOrders = (props) => {
    const { state, onSearch } = props;

    return (
        <div >
            <Row className="clearfix">
                {(state.userRol < 4 || state.userRol === 5) && <DashboardCard showProgress title="Solicitudes Abiertas" value={state.openSoliOT} iconName="documents" valuePorct={state.openSoliOT} value2={` de ${state.soliOT}`} valuePorctSt={`${((state.openSoliOT * 100) / state.soliOT)}%`} valueMax={state.soliOT} />}
                {(state.userRol < 4 || state.userRol === 5) && <DashboardCard showProgress title="Ordenes Abiertas" value={state.openOT} iconName="documents" valuePorct={state.openOT} valuePorctSt={`${state.openOT}%`} valueMax={100} />}
                {(state.userRol < 4 || state.userRol === 6) && <DashboardCard showProgress title="Ordenes En Progreso" value={state.delayOT} value2={` de ${state.openOT}`} iconName="documents" valuePorct={((state.delayOT * 100) / state.openOT)} valuePorctSt={`${((state.delayOT * 100) / state.openOT)}%`} valueMax={100} />}
                {(state.userRol > 4 || state.userRol === 1) && <DashboardCard showProgress title="Productos en Low Stock" value={state.lowStock} value2={` de ${state.products}`} iconName="sales" valuePorct={state.lowStock} valuePorctSt={`${((state.lowStock * 100) / state.products)}%`} valueMax={state.products} />}
            </Row>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    {(state.userRol < 4 || state.userRol === 6) &&
                        <Row className="clearfix">
                            <Col lg={12} >
                                <Card >
                                    <div className="header">
                                        <h2><strong><i className="zmdi zmdi-chart" /> Reporte</strong> de Estadisticas</h2>
                                    </div>
                                    <Row className="clearfix">
                                        <ReportCard title="Ordenes Completadas" monthValue={state.closedOT_Month} weekValues={state.closedOT} barColor={"#82c885"} iconName="zmdi-assignment-check" />
                                        <ReportCard title="Mantenimientos Programados" monthValue={state.programmedMt_Month} weekValues={state.programmedMt} barColor={"#868e96"} iconName="zmdi-calendar" />
                                    </Row>
                                    <div className="body">
                                        <C3Chart data={state.data} axis={state.axis} legend={{ show: true }}
                                            padding={{
                                                bottom: 0,
                                                top: 0,
                                            }} className="c3_chart d_sales" />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    }
                    {
                        (state.userRol === 4 || state.userRol === 5) && <Row className="clearfix">
                            <Col lg={12}>
                                <Card>
                                    <div className="header">
                                        <h2><strong><i className="zmdi zmdi-file-text" /> Solicitudes </strong> de Orden de Trabajo</h2>
                                    </div>
                                    <div className="body mb-2">
                                        <Table hover responsive className="mb-0 c_list c_table break_table theme-color">
                                            <thead>
                                                <tr>
                                                    <TableHeaderlist title="#" />
                                                    <TableHeaderlist title="No. Solicitud" />
                                                    <TableHeaderlist title="Descripción" />
                                                    <TableHeaderlist title="Fecha" />
                                                    <TableHeaderlist title="Estado" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    state.solicitudes.map((value, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <TableItemlist item={((state.currentPage - 1) * 15) + (i + 1)} style={{ width: "60px" }} />
                                                                <TableItemlist item={'A' + value.numero} />
                                                                <TableItemlist item={value.descripcion} />
                                                                <TableItemlist item={value.fecha} />
                                                                <TableItemlist item={value.estado} />
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                        <div className="justify-content-center">
                                            <Pagination className="pagination pagination-success mt-4">
                                                {
                                                    state.pages.map((value, index) => {
                                                        return (
                                                            <PaginationItem key={index} active={value === state.currentPage}>
                                                                <PaginationLink href="#" onClick={() => onSearch(value)}>{value}</PaginationLink>
                                                            </PaginationItem>
                                                        );
                                                    })
                                                }
                                            </Pagination>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </div>
    );
}

export default DashboardOrders;