import React from 'react';
import { Row } from 'reactstrap';
import DashboardCard from './DashboardCard';


const DashboardIndicadoresDesempeno = ({ state }) => {
    const { totalAvailableTime, mtbf, mttr } = state?.indicadoresMantenimiento;

    const dashboardCards = [
        { title: "Tiempo Disponible", value: totalAvailableTime, valueMax: totalAvailableTime, icon: 'traffic', unidad: 'Min' },
        { title: "Activos Fuera de Servicio", value: 0, valueMax: 0, icon: 'unavailable' },
        { title: "MTBF", value: mtbf, valueMax: mtbf, icon: 'available', unidad: 'Min' },
        { title: "MTTR", value: mttr, valueMax: mttr, unidad: 'Min' },
    ];

    return (
        <div >
            <Row className="clearfix">
                {dashboardCards.map((card, index) => (
                    <DashboardCard
                        key={index}
                        title={card?.title}
                        value={`${card?.value || 0} ${(card?.unidad || '')}`}
                        iconName={card?.icon}
                        valuePorct={card?.value}
                        // value2={` de ${card.valueMax}`}
                        valuePorctSt={`${((card?.value * 100) / card?.valueMax)}%`}
                        valueMax={card?.valueMax || 0}
                        showProgress={false}
                        indicadoresMantenimiento={true}
                    />
                ))
                }
            </Row>
        </div>
    );
}


export default DashboardIndicadoresDesempeno;