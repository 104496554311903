import User from '../services/user'

export default {
    
    //create new season
    async list (data) {
      let users;
      if(!data) data ={  filtro : 0 , page : 1  }
      if(!data.filtro)  data.filtro = 0
      if(!data.page)  data.page = 1
      if(!data.count) data.count = 5
      try{
        users = await User.list(data);
      } catch(error){
        //console.log(error)
        users = false;
      }
      return users ? users.data : false;
    },
    async search (data) {
      let users;
      if(!data) data ={ page : 1  }
      if(!data.page)  data.page = 1
      try{
        users = await User.search(data);
      } catch(error){
        //console.log(error)
        users = false;
      }
      return users ? users.data : false;
    },
    
    async save(data){
      let result;
      try {
        result = await User.save(data)
        //console.log(result,"result")
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async listRol () {
      let users;
      try{
        users = await User.listRol();
      } catch(error){
        //console.log(error)
        users = false;
      }
      return users ? users.data : false;
    },
    async get (data) {
      let user;
      try{
        user = await User.get(data.id);
      } catch(error){
        //console.log(error)
        user = false;
      }
      return user ? user.data : false;
    },
        
    async update(data){
      let result;
      try {
        result = await User.update(data)
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async delete (data) {
      let user;
      try{
        user = await User.delete(data.id);
      } catch(error){
        //console.log(error)
        user = false;
      }
      return user ? user.data : false;
    },
    async upload(data)
    {
      let result;
      try {
        result = await User.upload(data)
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async newUser(data){
      let result;
      try {
        result = await User.newUser(data)
        //console.log(result,"result")
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async getTechs () {
      let users;
      try{
        users = await User.getTechs();
      } catch(error){
        //console.log(error)
        users = false;
      }
      return users ? users.data : false;
    }
}