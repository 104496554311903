import React from 'react';
import './App.css';
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import Login from "./authentication/Login"
import Dashboard from "./home/Dashboard"
import User from "./manage/User"
import CreateUser from "./manage/CreateUser"
import EquipmentandProperties from "./inventory/EquipmentandProperties"
import CreateCreateEquipmentandProperties from "./inventory/CreateEquipmentandProperties"
import MyProfile from "./profile/MyProfile"
import CurrentStock from "./inventory/CurrentStock"
import CreateStock from "./inventory/CreateStock"
import Calendar from "./tasks/Calendar"
import NewMaintenantEvent from "./tasks/NewMaintenantEvent"
import Suppliers from "./inventory/Suppliers"
import CreateSuppliers from "./inventory/CreateSuppliers"
import Assest from "./Assest/Assest"
import CreateaAssest from "./Assest/CreateAssest"
import CreateAssestBuildings from "./Assest/CreateBuildings";
import CreateaAssestEquipment from "./Assest/CreateEquipment";
import Building from "./Assest/Building"
import Equipment from "./Assest/Equipment"
import Tools from "./Assest/Tools"
import history from "./history"
import Order from "./Order/Order"
import CreateOrder from "./Order/CreateOrder"
import RequestOrder from "./Order/OrderRequest"
import TareasAsignadas from "./tasks/TareasAsignadas"
import ServiceOrder from "./Order/ServiceOrder"
import ForgotPassword from "./authentication/ForgotPassword"
import Notificaciones from "./home/Notificaciones"
import NotFound from "./404"
import SolicitudesTrabajo from "./tasks/SolicitudesTrabajos"
import PlanesMantenimientos from "./tasks/PlanesMantenimientos"
import Reports from "./reports/Reports"
import AssetsTree from "./Assest/AssetsTree"
import ReporteOrdenTrabajo from "./Order/reporte/ReporteOrdenTrabajo"
import AssestType from "./Assest/AssestType"
import "@cels/react-treeview/dist/styles.css";
export default function App() {
  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/home" component={Dashboard} />
          <Route path="/profile" component={MyProfile} />
          <Route path="/calendarevents" component={Calendar} />
          <Route path="/addevent" component={NewMaintenantEvent} />
          <Route path="/manageuser" component={User} />
          <Route path="/createuser" component={CreateUser} />
          <Route path="/manageequipment" component={EquipmentandProperties} />
          <Route path="/createequipment" component={CreateCreateEquipmentandProperties} />
          <Route path="/managestock" component={CurrentStock} />
          <Route path="/createstock" component={CreateStock} />
          <Route path="/managesuppliers" component={Suppliers} />
          <Route path="/createsuppliers" component={CreateSuppliers} />
          <Route path="/manageassest" component={Assest} />
          <Route path="/createassest" component={CreateaAssest} />
          <Route path="/createassestequipment" component={CreateaAssestEquipment} />
          <Route path="/createassestbuildings" component={CreateAssestBuildings} />
          <Route path="/managebuilding" component={Building} />
          <Route path="/manageequipments" component={Equipment} />
          <Route path="/managetools" component={Tools} />
          <Route path="/order" component={Order} />
          <Route path="/service" component={ServiceOrder} />
          <Route path="/createorder" component={CreateOrder} />
          <Route path="/requestorder" component={RequestOrder} />
          <Route path="/tasks" component={TareasAsignadas} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/notification" component={Notificaciones} />
          <Route path="/orderequests" component={SolicitudesTrabajo} />
          <Route path="/events" component={PlanesMantenimientos} />
          <Route path="/reports" component={Reports} />
          <Route path="/resumen" component={AssetsTree} />
          <Route path="/reporteot" component={ReporteOrdenTrabajo} />
          <Route path="/assesttype" component={AssestType} />
          <Route render={({ staticContext }) => {
            if (staticContext) {
              staticContext.status = 404;
            }
            return (
              <NotFound />
            )
          }} />
        </Switch>
      </div>
    </Router>
  );
}

