import React from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import { Button, Alert, Row, Col, Card, CardBody, CardTitle, FormGroup, InputGroup, InputGroupAddon, Breadcrumb, BreadcrumbItem, Spinner } from 'reactstrap';
import Api from "../modules/user"
import Select from 'react-select';
import history from '../history';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';
import AvFeedback from 'availity-reactstrap-validation/lib/AvFeedback';
import AvGroup from 'availity-reactstrap-validation/lib/AvGroup';

export default class CreateUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            nombre: "",
            apellido: "",
            roles: [],
            rol: null,
            telefono: "",
            correo: "",
            cargo: "",
            cedula: "",
            direccion: "",
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "",
            loading: true,
            editing: false,
            usuarioId: undefined,
            selectError: "none",
            validForm: false,
            rolId: -1,
            newUser: false,
        }
    }

    reset() {
        // this.setState({
        //     nombre: "",
        //     apellido: "",
        //     rol: null,
        //     telefono: "",
        //     correo: "",
        //     cargo: "",
        //     cedula: "",
        //     direccion: "",
        //     selectError: "none"
        // }, () => window.scrollTo(0, 0))
        //reload page
        window.location.reload();
    }

    async componentDidMount() {
        const state = this.props.location.state
        let userRol = (localStorage.getItem('user_rol'));
        const rolId = parseInt(userRol, 10);
        this.setState({ rolId })
        if (rolId === 1) {
            await this.getRoles(state && state.id);
        } else {
            this.setState({
                roles: [{ value: 2, label: "tecnico" }],
                loading: (state && state.id)
            })
        }
        if (state && state.id) {
            await this.getUserData(state.id)
        }
    }

    async getRoles(keepLoading) {
        let roles = []
        Api.listRol().then((response) => {
            if (response) {
                for (let key in response) {
                    roles.push({
                        value: response[key].rolId,
                        label: response[key].descripcion
                    })
                }
            }
        }).finally(() => {
            this.setState({ roles, loading: keepLoading })
        })
    }

    async getUserData(id) {
        Api.get({ id }).then((response) => {
            if (response) {
                this.setState({
                    usuarioId: response.usuarioId,
                    nombre: response.persona.nombre,
                    apellido: response.persona.apellido,
                    rol: {
                        value: response.rol.rolId,
                        label: response.rol.descripcion
                    },
                    telefono: response.persona.telefono,
                    correo: response.correo,
                    cargo: response.persona.cargo,
                    cedula: response.persona.identificacion,
                    direccion: response.persona.direccion,
                    editing: true,
                })
            }
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    selectRol = selectedOption => {
        this.setState(
            { rol: selectedOption }
        );
    };

    save() {
        this.setState({ disabled: true, isLoading: true })
        const { nombre, apellido, rol, telefono, correo, cargo, cedula, direccion } = this.state;
        const data = {
            correo,
            nombreUsuario: correo,
            persona: {
                nombre,
                apellido,
                identificacion: cedula,
                fechaNacimiento: "",
                sexo: "",
                telefono,
                direccion,
                pais: "Venezuela",
                estado: "Zulia",
                ciudad: "Maracaibo",
                cargo: cargo
            },
            rol: {
                rolId: rol.value,
                descripcion: rol.label
            }
        }
        Api.save(data).then((response) => {
            if (response && response.usuarioId > 0) {
                this.setState({
                    visible: true,
                    message: "Un correo fue enviado al nuevo usuario creado.",
                })
            } else {
                if (response.warning) {
                    this.setState({
                        warning: true,
                        message: response.message,
                        disabled: false
                    })
                } else {
                    this.setState({ error: true, disabled: false })
                }

            }
        }).finally(() => {
            this.setState({ isLoading: false, newUser: true })
        })
    }

    update() {
        this.setState({ disabled: true, isLoading: true })
        const { usuarioId, nombre, apellido, rol, telefono, correo, cargo, cedula, direccion } = this.state;
        const data = {
            usuarioId,
            correo,
            nombreUsuario: correo,
            persona: {
                nombre,
                apellido,
                identificacion: cedula,
                fechaNacimiento: "",
                sexo: "",
                telefono,
                direccion,
                pais: "Venezuela",
                estado: "Zulia",
                ciudad: "Maracaibo",
                cargo: cargo
            },
            rol: {
                rolId: rol.value,
                descripcion: rol.label
            }
        }
        Api.update(data).then((response) => {
            if (response) {
                this.setState({
                    visible: response.success,
                    warning: response.warning,
                    message: response.message,
                    disabled: false
                })
            } else {
                this.setState({ error: true, disabled: false })
            }
        }).finally(() => {
            this.setState({ isLoading: false, newUser: true })
        })
    }

    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }

    handleSubmit(event, errors, values) {
        //validate select
        if (this.state.selectError === "none") {
            this.state.editing ? this.update() : this.save()
        }
    }

    handleInvalidSubmit(event, errors, values) {

    }

    validate() {
        //validate selects
        if (this.state.rol === null) {
            this.setState({ selectError: "block" }, () => this.form.submit())

        } else {
            this.setState({ selectError: "none" }, () => this.form.submit())
        }
    }

    render() {
        const { roles, loading } = this.state;
        if (loading) return <Loading />;
        return (
            <Container menuId="configuracion">
                <section className="content">
                    <div className="block-header">
                        <Row className="row">
                            <Col lg={7} md={6} sm={12} >
                                <h2>Crear Usuario</h2>
                                <Breadcrumb tag="ul" listTag="li" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Configuraciones</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="manageuser">Usuarios</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="span">Crear usuario</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"></Button>
                            </Col>
                            <Col lg={5} md={6} sm={12} >
                               {<Button color={"success"} className="btn-icon float-right" type="button" onClick={() => this.reset()}>
                                    <i class="zmdi zmdi-plus" />
                                </Button>}
                            </Col>
                        </Row>
                    </div>
                    <Card >
                        <CardBody className="body">
                            <AvForm
                                onValidSubmit={() => this.handleSubmit()}
                                ref={e => this.form = e}
                                onInvalidSubmit={() => this.handleInvalidSubmit()}>
                                <CardTitle className="card-inside-title">Datos Personales</CardTitle>
                                <Row className="clearfix">
                                    <Col sm={6}>
                                        <FormGroup>
                                            <label>Nombre</label>
                                            <AvField name="nombre"
                                                type="text"
                                                onKeyUp={(event) => { if (event.keyCode === 13) this.appellidoRef.focus() }}
                                                value={this.state.nombre}
                                                onChange={(event) => this.setState({ nombre: event.target.value })}
                                                className="form-control"
                                                placeholder="Nombre"
                                                validate={{
                                                    required: { value: true, errorMessage: 'Ingrese el nombre de usuario' },
                                                    pattern: { value: '^[A-z0-9À-ž ]+$', errorMessage: 'El nombre debe contener solo letras y numeros' },
                                                    maxLength: { value: 50, errorMessage: 'El nombre debe tener menos de 50 caracteres' }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <label>Apellido</label>
                                            <AvField name="apellido"
                                                innerRef={e => this.appellidoRef = e}
                                                onKeyUp={(event) => { if (event.keyCode === 13) this.rolesRef.focus() }}
                                                type="text"
                                                value={this.state.apellido}
                                                onChange={(event) => this.setState({ apellido: event.target.value })}
                                                className="form-control"
                                                placeholder="Apellido"
                                                validate={{
                                                    required: { value: true, errorMessage: 'Ingrese el apellido de usuario' },
                                                    pattern: { value: '^[A-z0-9À-ž ]+$', errorMessage: 'El apellido debe contener solo letras y numeros' },
                                                    maxLength: { value: 50, errorMessage: 'El apellido debe tener menos de 50 caracteres' }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="clearfix">
                                    <Col sm={4}>
                                        <label>Seleccione su rol:</label>
                                        <Select noOptionsMessage={() => 'Sin Resultados'}
                                            ref={ref => { this.rolesRef = ref; }}
                                            placeholder={"-- Seleccione su rol --"}
                                            value={this.state.rol}
                                            onChange={this.selectRol}
                                            isDisabled={this.state.rolId < 1 && this.state.rolId > 2}
                                            onBlur={() => { this.state.rol === null ? this.setState({ selectError: "block" }) : this.setState({ selectError: "none" }) }}
                                            options={roles}
                                        />
                                        <div style={{ display: this.state.selectError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione rol del usuario</div>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup className="masked-input mb-3">
                                            <label>Documento de identidad</label>
                                            <AvField name="cedula"
                                                innerRef={e => this.cedulaRef = e}
                                                onKeyUp={(event) => { if (event.keyCode === 13) this.cargoRef.focus() }}
                                                type="numeric"
                                                value={this.state.cedula}
                                                onChange={(event) => this.setState({ cedula: event.target.value })}
                                                className="form-control"
                                                placeholder="Documento de identidad"
                                                validate={{
                                                    required: { value: true, errorMessage: 'Ingrese la identificación del usuario' },
                                                    pattern: { value: '^[A-z0-9]+$', errorMessage: 'La identificación debe contener solo letras y numeros' },
                                                    maxLength: { value: 15, errorMessage: 'La identificación debe tener menos de 15 caracteres' }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup className="masked-input mb-3">
                                            <label>Cargo</label>
                                            <AvField name="cargo"
                                                innerRef={e => this.cargoRef = e}
                                                onKeyUp={(event) => { if (event.keyCode === 13) this.emailRef.focus() }}
                                                type="text"
                                                value={this.state.cargo}
                                                onChange={(event) => this.setState({ cargo: event.target.value })}
                                                className="form-control"
                                                placeholder="Cargo"
                                                validate={{
                                                    required: { value: false, errorMessage: '' },
                                                    pattern: { value: '^[A-z0-9À-ž ]+$', errorMessage: 'El cargo debe contener solo letras y numeros' },
                                                    maxLength: { value: 50, errorMessage: 'El cargo debe tener menos de 50 caracteres' }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="clearfix">
                                    <Col sm={6}>
                                        <label>Correo Electrónico</label>
                                        <AvGroup >
                                            <InputGroup className="masked-input mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <span className="input-group-text"><i className="zmdi zmdi-email" /></span>
                                                </InputGroupAddon>
                                                <AvInput name="email"
                                                    type="email"
                                                    onKeyUp={(event) => { if (event.keyCode === 13) this.telefonoRef.focus() }}
                                                    innerRef={e => this.emailRef = e}
                                                    value={this.state.correo}
                                                    onChange={(event) => this.setState({ correo: event.target.value })}
                                                    className="form-control"
                                                    placeholder="Dirección de Correo Electrónico"
                                                    validate={{
                                                        required: { value: true, errorMessage: 'Ingrese el correo electronico del usuario' },
                                                        email: { value: true, errorMessage: 'Ingrese un correo electronico válido' },
                                                        maxLength: { value: 50, errorMessage: 'El correo debe tener menos de 50 caracteres' }
                                                    }}
                                                />
                                                <AvFeedback>Ingrese un correo electronico válido</AvFeedback>
                                            </InputGroup>
                                        </AvGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <AvGroup>
                                            <label># de telefono</label>
                                            <InputGroup className="masked-input mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <span className="input-group-text"><i className="zmdi zmdi-phone" /></span>
                                                </InputGroupAddon>
                                                <AvInput name="telefono"
                                                    innerRef={e => this.telefonoRef = e}
                                                    onKeyUp={(event) => { if (event.keyCode === 13) this.direccionRef.focus() }}
                                                    type="text"
                                                    value={this.state.telefono}
                                                    onChange={(event) => this.setState({ telefono: event.target.value })}
                                                    className="form-control mobile-phone-number"
                                                    placeholder="Ex: +00 (000) 000-00-00"
                                                    validate={{
                                                        required: { value: true, errorMessage: 'Ingrese el correo electronico del usuario' },
                                                        pattern: { value: '^[0-9  -+]+$', errorMessage: 'El telefono contiene caracteres inválidos' },
                                                        maxLength: { value: 50, errorMessage: 'El correo debe tener menos de 50 caracteres' }
                                                    }}
                                                />
                                                <AvFeedback>Ingrese un número de telefono válido</AvFeedback>
                                            </InputGroup>
                                        </AvGroup>
                                    </Col>
                                </Row>
                                <Row className="clearfix">
                                    <Col sm={12}>
                                        <AvGroup>
                                            <label>Dirección</label>
                                            <InputGroup className="masked-input mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <span className="input-group-text"><i className="zmdi zmdi-home" /></span>
                                                </InputGroupAddon>
                                                <AvInput name="direccion"
                                                    innerRef={e => this.direccionRef = e}
                                                    onKeyUp={(event) => { if (event.keyCode === 13) this.validate() }}
                                                    type="textarea"
                                                    value={this.state.direccion}
                                                    onChange={(event) => this.setState({ direccion: event.target.value })}
                                                    className="form-control center"
                                                    placeholder="Dirección"
                                                    rows={1}
                                                    validate={{
                                                        required: { value: false, errorMessage: '' },
                                                        // eslint-disable-next-line
                                                        pattern: { value: '/^[A-z0-9À-ž .()-_\s]+$/m', errorMessage: 'La dirección deben contener solo letras y numeros' },
                                                        maxLength: { value: 150, errorMessage: 'La dirección debe tener menos de 150 caracteres' }
                                                    }}
                                                />
                                                <AvFeedback>La dirección deben contener solo letras y numeros</AvFeedback>
                                            </InputGroup>
                                        </AvGroup>
                                    </Col>
                                </Row>
                                <Button color="success" size="lg" disabled={this.state.disabled} onClick={() => this.validate()} >{this.state.isLoading ? <Spinner color="light" size="sm" /> : "Guardar"}</Button>
                                <Button color="default" size="lg" onClick={() => history.goBack()} >Cancelar</Button>
                            </AvForm>
                        </CardBody>
                    </Card>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        No se pudo crear el nuevo usuario
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                </section>
            </Container>
        )
    }
}