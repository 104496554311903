import { server } from './config'
import client from './client'

export default {
  dashboard(data, config = {}) {
    console.log("🚀 ~ dashboard ~ data:", data)
    return client.get(`${server}/user/dashboard/${data.id}?from=${data?.from}&to=${data?.to}&activoId=${data?.activo}`, config)
  },
  solicitudes(data, config = {}) {
    return client.get(`${server}/solicitud?p=${data.page}&id=${data.id}`)
  },
  indicadoresMantenimiento(data, config = {}) {

    return client.get(`${server}/indicadores/mantenimiento?from=${data?.from}&to=${data?.to}&activoId=${data?.activo}&localizationId=${data.localozation}`, config)
  }
}