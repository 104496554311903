import { server } from './config'
import client from './client'

export default {
    list (data,config = {}) {
		return client.get(`${server}/activo?filtro=${data.filtro}&p=${data.page}&c=${15}`)
	},
	listOrders (data,config = {}) {
		return client.get(`${server}/ordenactivo?id=${data.activoId}`)
	},
	listBySearch (data,config = {}) {
		return client.get(`${server}/search/activo/${data.search}?filtro=${data.filtro}&p=${data.page}&c=${15}`)
	},
	listAll (data,config = {}) {
		return client.get(`${server}/activos/${data.view}?p=${data.page}&c=${15}`)
	},
	listChildren (data,config = {}) {
		//console.log(`${server}/activosChildren?padreId=${data.parent}`)
		return client.get(`${server}/activosChildren?padreId=${data.parent}`)
	},
	searchAll (data,config = {}) {
		return client.get(`${server}/search/activos/${data.search}?p=${data.page}&c=${15}`)
	},
	search (data,config = {}) {
		return client.get(`${server}/search/localizacion/${data.search}?p=${data.page}&c=${15}`)
	},
	listLocalizaciones (data,config = {}) {
		return client.get(`${server}/localizacion?p=${data.page}&c=${15}`)
	},
	save(data,config = {}){
       return client.post(`${server}/activo`, data, config)
   },
   saveLocalizacion(data,config = {}){
		return client.post(`${server}/localizacion`, data, config)
	},
   listTiposActivos (config = {}) {
		return client.get(`${server}/tipos/activos`)
	},
	listTiposActivosPath (config = {}) {
		return client.get(`${server}/tipos/activosPath`)
	},
	createTipoActivo(data,config = {}){
		return client.post(`${server}/tipos/activos`, data, config)
	},
	deleteTipoActivo (tipoActivoId,config = {}) {
		return client.delete(`${server}/tipos/activos/${tipoActivoId}`)
	},
	updateTipoActivo(data,config = {}){
		return client.put(`${server}/tipos/activos`, data, config)
	},
	searchTipoActivo (data,config = {}) {
		return client.get(`${server}/search/tipos/activos/${data.search}?p=${data.page}&c=${15}`)
	},
	listCategoriasLocalizacion (config = {}) {
		return client.get(`${server}/categoria/localizacion`)
	},
	get (activoId,config = {}) {
		return client.get(`${server}/activo/${activoId}`)
	},
	getLocalizacion (localizacionId,config = {}) {
		return client.get(`${server}/localizacion/${localizacionId}`)
	},
	update(data,config = {}){
       return client.put(`${server}/activo`, data, config)
   },
   updateLocalizacion(data,config = {}){
		return client.put(`${server}/localizacion`, data, config)
	},
   delete (activoId,config = {}) {
		return client.delete(`${server}/activo/${activoId}`)
	},
	deleteLocalizacion (activoId,config = {}) {
		return client.delete(`${server}/localizacion/${activoId}`)
	},
	upload(data,config = { headers: {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}}){
		return client.post(`${server}/upload`, data, config)
	},
	removeFile(data,config = {}){
		return client.delete(`${server}/activoFiles/${data.fileActivoId}`, data, config)
	},
	listUnidadesMedicion (config = {}) {
		return client.get(`${server}/unidades/medicion`)
	},
	getPlanes (data,config = {}) {
		return client.get(`${server}/activo/plan?activoId=${data.activoId}`)
	},
	listClasificaciones (config = {}) {
		return client.get(`${server}/clasificacion/activo`)
	}
}