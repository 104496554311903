import React from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import TableHeaderlist from '../components/TableHeaderList';
import TableItemlist from '../components/TableItemList';
import Api from "../modules/user"
import { Button, Alert, InputGroup, InputGroupAddon, InputGroupText, Input, Row, Col, Breadcrumb, BreadcrumbItem, Card, Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import history from '../history';
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationAlert from 'react-notification-alert';
import "react-notification-alert/dist/animate.css";
const options = {
    place: "br",
    message: "Sin Resultados",
    type: "warning",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
const options2 = {
    place: "br",
    message: "Error Inesperado",
    type: "danger",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
export default class User extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            confirm: false,
            usuarioId: undefined,
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "",
            loading: true,
            totalPages: 0,
            currentPage: 0,
            pages: [],
            search: "",
            rolId: -1
        }
        this.deleteUser = this.deleteUser.bind(this)
    }

    componentDidMount() {
        let userRol = (localStorage.getItem('user_rol'));
        const rolId = parseInt(userRol, 10);
        this.setState({ rolId })
        this.listUsers(1, rolId > 1 ? 2 : 0);
    }

    async listUsers(page, filtro) {
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        Api.list({ page, filtro }).then(res => {
            if (res) {
                const response = res.data;
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    data.push({
                        id: response[key].usuarioId,
                        email: response[key].correo,
                        fullName: response[key].persona.nombre + " " + response[key].persona.apellido,
                        rol: response[key].rol.descripcion
                    })
                }
                if (totalPages === 0) this.refs.notify.notificationAlert(options);
            }
        }).catch((err) => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                data,
                pages,
                currentPage,
                totalPages,
                loading: false
            })
        })
    }

    updateUser(id) {
        history.push('/createuser', { id });
    }

    deleteUser = () => {
        const { usuarioId } = this.state;
        this.setState({ confirm: false, visible: true })
        //console.log(usuarioId)
        Api.delete({ id: usuarioId }).then((response) => {
            if (response) {
                this.setState({
                    visible: response.success,
                    warning: response.warning,
                    message: response.message,
                    confirm: false
                }, () => this.listUsers())
            } else {
                this.setState({ error: true, confirm: false })
            }
        })
    }

    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }

    showDialog = (value) => {
        this.setState({ confirm: true, usuarioId: value })
    }

    onSearch = (page) => {
        const { search, rolId } = this.state;
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        if (search !== "") {
            Api.search({ search, page, filtro: rolId > 1 ? 2 : 0 }).then(res => {
                if (res) {
                    const response = res.data;
                    totalPages = res.totalPages;
                    currentPage = res.currentPage;
                    for (let key in response) {
                        data.push({
                            id: response[key].usuarioId,
                            email: response[key].correo,
                            fullName: response[key].persona.nombre + " " + response[key].persona.apellido,
                            rol: response[key].rol.descripcion
                        })
                    }
                    if (totalPages === 0) this.refs.notify.notificationAlert(options);
                }
            }).catch((err) => {
                this.refs.notifyError.notificationAlert(options2);
            }).finally(() => {
                for (let i = 0; i < totalPages; i++) {
                    pages.push((i + 1));
                }
                this.setState({
                    data,
                    pages,
                    currentPage,
                    totalPages,
                    loading: false
                })
            })
        } else {
            this.listUsers(page);
        }
    }


    render() {
        const { data } = this.state;
        return (
            <Container menuId="configuracion" submenu="usuarios" usersToggled>
                {this.state.loading && <Loading />}
                <NotificationAlert ref="notify" />
                <NotificationAlert ref="notifyError" />
                <section className="content">
                    <div className="block-header">
                        <Row className="row">
                            <Col lg={7} md={6} sm={12} >
                                <h2>Usuarios</h2>
                                <Breadcrumb tag="ul" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Configuraciones</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="li">Usuarios</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></Button>
                            </Col>
                            <Col lg={5} md={6} sm={12} >
                                <a href="/createuser" className="App-link">
                                    <Button color={"success"} className="btn-icon float-right" type="button">
                                        <i className="zmdi zmdi-plus" />
                                    </Button>
                                </a>
                                <InputGroup className="float-right">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><i className="zmdi zmdi-search" /></InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text"
                                        onKeyUp={(event) => { if (event.keyCode === 13) this.onSearch(1) }}
                                        className="form-control"
                                        placeholder="Busqueda..."
                                        value={this.state.search}
                                        onChange={(event) => this.setState({ search: event.target.value })} />
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                    <Card >
                        <Table hover responsive className="mb-0 c_list c_table theme-color">
                            <thead>
                                <tr>
                                    <TableHeaderlist title="#" />
                                    <TableHeaderlist title="Correo" />
                                    <TableHeaderlist title="Nombre" />
                                    <TableHeaderlist title="Rol" />
                                    <TableHeaderlist title="Accion" />
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((value, i) => {
                                        return (
                                            <tr key={i}>
                                                <TableItemlist item={((this.state.currentPage - 1) * 15) + (i + 1)} />
                                                <TableItemlist item={value.email} />
                                                <TableItemlist item={value.fullName} />
                                                <TableItemlist item={value.rol} />
                                                <td>
                                                    <Button onClick={() => this.updateUser(value.id)} className="btn btn-primary btn-sm"><i className="zmdi zmdi-edit" /></Button>
                                                    <Button onClick={() => this.showDialog(value.id)} className="btn btn-danger btn-sm"><i className="zmdi zmdi-delete" /></Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        <div className="justify-content-center">
                            <Pagination className="pagination pagination-success mt-4">
                                {
                                    this.state.pages.map((value, index) => {
                                        return (
                                            <PaginationItem active={value === this.state.currentPage} key={index}>
                                                <PaginationLink href="#" onClick={() => this.onSearch(value)}>{value}</PaginationLink>
                                            </PaginationItem>
                                        );
                                    })
                                }
                            </Pagination>
                        </div>
                    </Card>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        Error al eliminar
                    </Alert>
                </section>
                <SweetAlert warning showCancel
                    confirmBtnText="Sí, eliminar"
                    cancelBtnText="No"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    focusCancelBtn
                    title={"¿Estas Seguro?"}
                    onConfirm={this.deleteUser}
                    onCancel={() => this.setState({ confirm: false })}
                    show={this.state.confirm}
                >
                    Una vez eliminado, éste usuario no podrá acceder al sistema
                </SweetAlert>
            </Container>
        )
    }
}