import Notificaciones from '../services/notifications'

export default {
    async list (data) {
      let notificaciones;
      const id = localStorage.getItem('id_token')
      if(!data) data = { page : 1}
      data.id = id;
      try{
        notificaciones = await Notificaciones.list(data);
      } catch(error){
        //console.log(error)
        notificaciones = false;
      }
      return notificaciones ? notificaciones.data : false;
    },
    async update(data){
      let notificaciones;
      try{
        notificaciones = await Notificaciones.update(data);
      } catch(error){
        console.log(error)
        notificaciones = false;
      }
      return notificaciones ? notificaciones.data : false;
    }

}