import React, { useEffect } from 'react';
import '../../App.css';
import moment from 'moment';
import { TIPO_MANTENIMIENTO } from "../../constants"
import { Card, Col, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';

export default function ModalOrderJob(props) {
    const { show, onHide, data } = props;

    useEffect(() => {
        if (show) {
            console.log("===================> Modal Revisions <===================");
            console.log(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const ordenEstado = [
        "Abierta",
        "En Progreso",
        "Completada",
        "No Completada"
    ]

    return (
        <>
            <Modal isOpen={show} size="xl" backdrop className="p-0" >
                <ModalHeader toggle={() => { onHide() }}>
                    <div className="header">
                        <h6><strong>REVISIONES </strong></h6>
                    </div>
                </ModalHeader>
                <section className="content" style={{ width: '100%', margin: 0 }}>
                    <ModalBody>
                        <Card className="mb-3">
                            {data.length > 0 ?
                                <Table hover  className="mb-0 c_table">
                                    <thead>
                                        <tr>
                                            <th># Orden.</th>
                                            <th>Tipo de Orden</th>
                                            <th>Tipo de Mantenimiento</th>
                                            <th>Fecha Emisión</th>
                                            <th>Estado Actual</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{`${('00000000' + item.ordenTrabajoNumero).slice(-8)}`}</td>
                                                        <td>{item.tipoOrden === 1 ? "Trabajo" : "Servicio"}</td>
                                                        <td>{TIPO_MANTENIMIENTO[item.tipoMantenimiento]}</td>
                                                        <td>{moment(item.fechaInicio).format("DD/MM/YYYY")}</td>
                                                        <td>{ordenEstado[item.estado - 1]}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table> :
                                <Col className="d-flex justify-content-center align-items-center">
                                    <p> No hay Revisiones </p>
                                </Col>
                            }
                        </Card>
                    </ModalBody>
                </section>
            </Modal>
        </>
    )
}