import React from 'react';
import Loading from "./Loading"
import '../App.css';
import Container from "../Container";
import { Row, Col, Button, Breadcrumb, BreadcrumbItem, Input } from 'reactstrap';
import Api from "../modules/reportes"
import history from "../history"
import Select from 'react-select';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment"
import NotificationAlert from 'react-notification-alert';
import "react-notification-alert/dist/animate.css";
import DashboardOrders from '../components/DashboardOrders'; // Importa el componente
import DashboardIndicadoresDesempeno from '../components/DashboardIndicadoresDesempeno'; // Importa el componente
import Activos from '../modules/activos';

const options2 = {
    place: "br",
    message: "Error Inesperado",
    type: "danger",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}

export default class Dashboard extends React.Component {

    state = {
        loading: true,
        openOT: 0,
        delayOT: 0,
        lowStock: 0,
        soliOT: 0,
        openSoliOT: 0,
        products: 0,
        closedOT_Month: 0,
        closedTasks_Month: 0,
        programmedMt_Month: 0,
        assignedOt_Month: 0,
        closedOT: [],
        closedTasks: [],
        programmedMt: [],
        assignedOt: [],
        solicitudes: [],
        data: {
            columns: []
        },
        indicadoresMantenimiento: {
            totalAvailableTime: 0,
            mtbf: 0,
            mttr: 0,
        },
        axis: {
            x: {
                type: 'category',
                // name of each category
                categories: []
            },
        },
        legend: {
            show: true, //hide legend
        },
        padding: {
            bottom: 0,
            top: 0,
        },
        totalPages: 0,
        currentPage: 0,
        pages: [],
        search: {},
        dashboardSelected: { value: 1, label: "Dasboard" },
        isOpen: false,
        fechaInicio: moment().startOf('month').format("YYYY-MM-DD"),
        fechaFin: moment().endOf('month').format("YYYY-MM-DD"),
        userRol: "",
        activos: [],
        selectedActivo: { value: null, label: "Todos" },
        availableTime: "",
        isOpen1: false,

    }

    async componentDidMount() {
        this.getIndicadoresMantenimiento();
        this.listActivosTree(1);
        this.solicitudes(1);
        this.dashboardOrders();
        let userRol = (localStorage.getItem('user_rol'));
        if (!userRol) {
            history.replace('/login');
        }
        this.setState({ userRol: parseInt(userRol, 10) })



    }

    async dashboardOrders() {
        const { fechaInicio, fechaFin, selectedActivo } = this.state;
        let closedOT_Month = 0, closedTasks_Month = 0, programmedMt_Month = 0, assignedOt_Month = 0;
        let closedOT = [], closedTasks = [], programmedMt = [], assignedOt = [];
        let weeks = []
        const data = {
            from: moment(fechaInicio).format("YYYY-MM-DD"),
            to: moment(fechaFin).format("YYYY-MM-DD"),
            activo: selectedActivo.value === null ? -1 : selectedActivo.value,
        }
        Api.dashboard(data).then((res) => {
            if (res) {
                for (const key in res.ordenesCerradas) {
                    const ot_closed = res.ordenesCerradas[key]
                    closedOT.push(ot_closed.value);
                    closedOT_Month = closedOT_Month + ot_closed.value;
                    weeks.push("Semana " + ot_closed.week)
                }
                for (const key in res.tareasFinalizadas) {
                    const task_finished = res.tareasFinalizadas[key]
                    closedTasks.push(task_finished.value);
                    closedTasks_Month = closedTasks_Month + task_finished.value;
                }
                for (const key in res.mantenimientosProgramados) {
                    const programmed_mt = res.mantenimientosProgramados[key]
                    programmedMt.push(programmed_mt.value);
                    programmedMt_Month = programmedMt_Month + programmed_mt.value;
                }
                for (const key in res.ordenesAsignadas) {
                    const ot_assigned = res.ordenesAsignadas[key]
                    assignedOt.push(ot_assigned.value);
                    assignedOt_Month = assignedOt_Month + ot_assigned.value;
                }
                this.setState({
                    openOT: res.ordenesAbiertas,
                    delayOT: res.ordenesAtrasadas,
                    lowStock: res.productosLow,
                    soliOT: res.ordenesSolicitadas,
                    openSoliOT: res.ordenesSolicitadasAbiertas,
                    products: res.productos,
                    closedOT,
                    closedOT_Month,
                    closedTasks,
                    closedTasks_Month,
                    programmedMt,
                    programmedMt_Month,
                    assignedOt,
                    assignedOt_Month,
                    data: {
                        columns: [
                            // each columns data
                            ['data1', ...closedOT],
                            // ['data2', ...closedTasks],
                            ['data3', ...programmedMt],
                        ],
                        type: 'area-spline', // default type of chart
                        groups: [
                            ['data1', 'data2', 'data3']
                        ],
                        colors: {
                            'data1': '#82c885',
                            // 'data2': '#2bcbba',
                            'data3': '#868e96',
                        },
                        names: {
                            // name of each serie
                            'data1': 'Ordenes',
                            // 'data2': 'Tareas',
                            'data3': 'Mantenimientos',
                        }
                    },
                    axis: {
                        x: {
                            type: 'category',
                            // name of each category
                            categories: weeks
                        },
                    },
                    loading: false
                })
            }
        }).catch((err) => {
            history.replace('/login');
        })
    }

    async solicitudes(page) {
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        Api.solicitudes({ page }).then((res) => {
            if (res) {
                const response = res.data;
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    data.push({
                        id: response[key].solicitudTrabajoId,
                        numero: ('00000000' + response[key].solicitudTrabajoId).slice(-8),
                        descripcion: response[key].descripcion,
                        fecha: moment(response[key].fechaSolicitud).format("DD/MM/YYYY"),
                        estado: response[key].estado === 0 ? 'Pendiente' : (response[key].estado === 1 ? 'En Progreso' : 'Completada')

                    })
                }
            }
        }).catch((err) => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                solicitudes: data,
                pages,
                currentPage,
                totalPages,
                loading: false
            })
        })
    }

    async listActivosTree(page) {
        this.setState({ loading: true });
        try {
            const res = await Activos.listAll({ view: "table", page });
            const activos = [
                { value: null, label: "Todos" },
                ...res.data
                    .filter(item => item.activo) // Filtra solo los elementos activos
                    .map(item => ({
                        value: item.id,
                        label: item.nombre,
                    }))
            ];
            this.setState({
                activos,
                loading: false
            });
            console.log(activos);
        } catch (err) {
            console.log(err);
            this.setState({ loading: false });
        }
    }

    onSearch = (page) => {
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        Api.solicitudes({ page: page }).then((res) => {
            if (res) {
                const response = res.data;
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    data.push({
                        id: response[key].solicitudTrabajoId,
                        numero: ('00000000' + response[key].solicitudTrabajoId).slice(-8),
                        descripcion: response[key].descripcion,
                        fecha: moment(response[key].fechaSolicitud).format("DD/MM/YYYY"),
                        estado: response[key].estado === 0 ? 'Pendiente' : (response[key].estado === 1 ? 'En Progreso' : 'Completada')

                    })
                }
            }
        }).catch((err) => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                solicitudes: data,
                pages,
                currentPage,
                totalPages,
                loading: false
            })
        })
    }

    handleChangeSelect = (e) => {
        console.log(e);
    }

    handleChange = (e) => {
        console.log(e);
    }

    selectedItem = (e) => {
        console.log(e);
    }

    async getIndicadoresMantenimiento() {
        let result;
        try {
            const from = moment(this.state.fechaInicio).format("YYYY-MM-DD");
            const to = moment(this.state.fechaFin).format("YYYY-MM-DD");
            const activo = this.state.selectedActivo.value === null ? -1 : this.state.selectedActivo.value;
            const localization = -1;
            const data = {
                from,
                to,
                activo,
                localization
            };
            result = await Api.indicadoresMantenimiento(data);
            console.log("🚀 ~ Dashboard ~ getIndicadoresMantenimiento ~ result:", result)
            this.setState({
                indicadoresMantenimiento: result
            })
        } catch (error) {
            result = false;
        }
        return result ? result.data : false;

    }


    render() {
        return (
            <Container menuId="home" >
                {this.state.loading && <Loading />}
                < NotificationAlert ref="notifyError" />
                <section className="content">
                    <div className="block-header">
                        <Row className="row justify-content-between">
                            <Col lg={3} md={3} sm={12}>
                                <h2>Dashboard</h2>
                                <Breadcrumb className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="li">Dashboard</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></Button>
                            </Col>

                        </Row>
                    </div>
                    {
                        <div className="container-fluid">
                            <Row className="justify-content-between">
                                <Col className="pl-0">
                                    <span>Dashboards:</span>
                                    <Select
                                        noOptionsMessage={() => 'Sin Resultados'}
                                        placeholder="Seleccione el Dashboard que desea ver"
                                        value={this.state.dashboardSelected}
                                        onChange={(e) => { this.setState({ dashboardSelected: e }) }}
                                        options={[
                                            { value: 1, label: "Dasboard" },
                                            { value: 2, label: "Indicadores de Mantenimiento" },
                                        ]}
                                    />
                                </Col>
                                <Col >
                                    <span>Activos:</span>
                                    <Select
                                        noOptionsMessage={() => 'Sin Resultados'}
                                        placeholder="Seleccione un Activo"
                                        value={this.state.selectedActivo}
                                        onChange={(e) => { this.setState({ selectedActivo: e }) }}
                                        options={this.state?.activos}
                                        defaultValue={this.state?.activos[0]}
                                    />
                                </Col>
                                <Col >
                                    <Row className="row justify-content-between ">
                                        <Col className="input-group masked-input mb-3">
                                            <span>Desde:</span>
                                            <div
                                                className="input-group"
                                                onClick={() => this.setState({ isOpen: true })}
                                            >
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="zmdi zmdi-calendar" /></span>
                                                </div>
                                                <Input
                                                    type="text"
                                                    value={this.state.fechaInicio !== "" ? moment(this.state.fechaInicio).format("DD/MM/YYYY") : ""}
                                                    className="form-control datetimepicker"
                                                    placeholder="Fecha de Inicio"
                                                    readOnly
                                                    editable="false"
                                                />
                                            </div>
                                            <div style={{ display: "none" }}>
                                                <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={"es"}>
                                                    <DatePicker
                                                        value={this.state.fechaInicio}
                                                        open={this.state.isOpen}
                                                        onOpen={() => this.setState({ isOpen: true })}
                                                        onClose={() => { this.setState({ isOpen: false }); }}
                                                        onChange={(date) => {
                                                            console.log("🚀 ~ Dashboard ", moment(date).format("YYYY-MM-DD"))

                                                            this.setState({
                                                                fechaInicio: moment(date).format("YYYY-MM-DD")
                                                            });
                                                        }}
                                                        label="Fecha de Inicio"
                                                        showTodayButton
                                                        cancelLabel={"Cancelar"}
                                                        okLabel={"Aceptar"}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col >
                                    <Row className="row justify-content-between ">
                                        <Col className="input-group masked-input mb-3">
                                            <span>Hasta:</span>
                                            <div
                                                className="input-group"
                                                onClick={() => this.setState({ isOpen1: true })}
                                            >
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="zmdi zmdi-calendar" /></span>
                                                </div>
                                                <Input
                                                    type="text"
                                                    value={this.state.fechaFin !== "" ? moment(this.state.fechaFin).format("DD/MM/YYYY") : ""}
                                                    className="form-control datetimepicker"
                                                    placeholder="Fecha de Inicio"
                                                    readOnly
                                                    editable="false"
                                                />
                                            </div>
                                            <div style={{ display: "none" }}>
                                                <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={"es"}>
                                                    <DatePicker
                                                        value={this.state.fechaFin}
                                                        disablePast={false}
                                                        open={this.state.isOpen1}
                                                        onOpen={() => this.setState({ isOpen1: true })}
                                                        onClose={() => { this.setState({ isOpen1: false }); }}
                                                        onChange={(date) => {
                                                            this.setState({
                                                                fechaFin: moment(date).format("YYYY-MM-DD")
                                                            });
                                                        }}

                                                        label="Fecha de Inicio"
                                                        showTodayButton
                                                        cancelLabel={"Cancelar"}
                                                        okLabel={"Aceptar"}
                                                        todayLabel={"HOY"}
                                                        minDate={moment(this.state.fechaInicio).startOf('quarter')}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="align-self-center mt-1">
                                    <Button
                                        color="primary"
                                        className="btn btn-outline-secondary"
                                        onClick={() => {
                                            if (this.state.dashboardSelected.value === 1) {
                                                this.dashboardOrders();
                                                this.solicitudes(1);
                                            } else {
                                                this.getIndicadoresMantenimiento();
                                            }
                                        }}
                                    >
                                        Buscar
                                    </Button>
                                </Col>
                            </Row>

                        </div>
                    }

                    {this.state?.dashboardSelected?.value === 1 &&
                        <DashboardOrders state={this.state} onSearch={this.onSearch} />
                    }
                    {this.state?.dashboardSelected?.value === 2 &&
                        <DashboardIndicadoresDesempeno state={this?.state} onSearch={this.onSearch} />
                    }
                </section>
            </Container>
        )
    }
}