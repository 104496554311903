import React from 'react';
import { server } from "../services/config"
import SweetAlert from 'react-bootstrap-sweetalert';
import { Scrollbars } from 'react-custom-scrollbars';
import ApiOrden from "../modules/ordenTrabajo"
import AsyncSelect from "react-select/async";
import ApiProducto from "../modules/inventario"
import ApiPlan from "../modules/plan"
import { Input, FormGroup, Button } from 'reactstrap';
import Select from 'react-select';
import moment from "moment"
import "moment/locale/es";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import history from '../history';
import { DATOS_BASICOS } from '../constants/index.js'
export default class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            userPhoto: "",
            title: "",
            showOtherAlert: false,
            inputAlert: false,
            message: "",
            alert: false,
            usuarios: [],
            showTecnicos: false,
            planAlert: false,
            isOpen: false,
            isOpen2: false,
            fechaFinal: "",
            fechaInicio: "",
            userRol: -1,
            taskToggled: false,
            assetsToggled: false,
            inventoryToggled: false,
            reportsToggled: false,
            usersToggled: false,
            menuToggled: false,
            expiresAt: null,
        }
        moment.locale("es");
    }

    async componentDidMount() {
        let userName = (localStorage.getItem('user_name'));
        let userPhoto = (localStorage.getItem('user_photo'));
        let userRol = (localStorage.getItem('user_rol'));

        // console.log(localStorage.getItem('expires_at'))
        //show countdown using localstorage

        this.setState({
            userName: userName ? userName : "Desconocido",
            userPhoto: userPhoto && userPhoto !== "" ? userPhoto : "/assets/images/profile_av.jpg",
            userRol,
            taskToggled: this.props.taskToggled,
            assetsToggled: this.props.assetsToggled,
            inventoryToggled: this.props.inventoryToggled,
            reportsToggled: this.props.reportsToggled,
            usersToggled: this.props.usersToggled,
        })

    }

    getRolName = (rolId) => {
        rolId = parseInt(rolId, 10);
        switch (rolId) {
            case 1:
                return "Super Admin";
            case 2:
                return "Supervisor";
            case 3:
                return "Tecnico";
            case 4:
                return "Usuario";
            case 5:
                return "Almacenista";
            case 6:
                return "Coordinador";
            default:
                return "";
        }
    }

    searchOrden = (response) => {
        ApiOrden.listById({ id: response }).then((res) => {
            console.log("🚀 ~ file: Menu.js:96 ~ Menu ~ ApiOrden.listById ~ res", res)

            if (!res) {
                this.setState({ title: "ups", message: "Número de orden no encontrado", inputAlert: false, alert: true })
            } else {
                // window.open(`${server}/report/orden/${res.ordenTrabajoId}`, "_blank")
                history.push({
                    pathname: '/reporteot',
                    state: {
                        data: {
                            ...res,
                            orden_trabajo_id: res.ordenTrabajoId,
                        },
                    }
                })
            }
        }).finally(() => {
            this.setState({ inputAlert: false })
        })
    }

    loadProductsOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        let productos = []
        ApiProducto.listProductoBySearch({ search: inputValue }).then((res) => {
            if (res) {
                const response = res.data;
                for (let key in response) {
                    productos.push({
                        label: `${response[key].nombre} (${response[key].codigo})`,
                        value: response[key].productoId
                    })
                }
            }

        }).finally(() => {
            return callback(productos);
        })
    };

    onSearchProducto = (selectedOption) => {
        if (selectedOption) {
            this.setState({
                productoSel: selectedOption
            });
        }
    };

    handleInputProducto = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    searchProducto = () => {
        const { productoSel } = this.state;
        this.setState({ showOtherAlert: false })
        if (productoSel && productoSel.value > 0) {
            window.open(`${server}/report/inventario/${productoSel.value}`, "_blank")
        } else {
            this.setState({ title: "ups", message: "Seleccione un producto", showOtherAlert: false, alert: true })
        }

    }

    fillUsers = () => {
        let { usuarios } = this.state
        if (usuarios.length === 0) {
            ApiPlan.listUserByRol({ id: 3 }).then((res) => {
                if (res) {
                    for (const key in res) {
                        usuarios.push({
                            value: res[key].usuarioId,
                            label: res[key].persona.nombre + " " + res[key].persona.apellido
                        })
                    }
                }
            }).finally(() => {
                this.setState({
                    usuarios,
                    showTecnicos: true
                })
            })
        } else {
            this.setState({
                showTecnicos: true
            })
        }

    }

    searchOrdenAsignada() {
        const { asignadoaSel } = this.state;
        this.setState({ showTecnicos: false })
        if (asignadoaSel && asignadoaSel.value > 0) {
            window.open(`${server}/report/user/orden/${asignadoaSel.value}`, "_blank")
        } else {
            this.setState({ title: "ups", message: "Seleccione un usuario", showTecnicos: false, alert: true })
        }
    }

    selectUsuario = selectedOption => {
        this.setState(
            { asignadoaSel: selectedOption },
        );
    };

    searchPlanes = () => {
        const { fechaInicio, fechaFinal } = this.state;
        this.setState({ planAlert: false })
        if (fechaInicio !== "" && fechaFinal !== "") {
            window.open(`${server}/report/plan/${moment(fechaInicio).format("YYYY-MM-DD")}/${moment(fechaFinal).format("YYYY-MM-DD")}`, "_blank")
        } else {
            this.setState({ title: "ups", message: "Seleccione un rango válido", planAlert: false, alert: true })
        }
    }

    toggleMenu = () => {
        this.setState({
            menuToggled: !this.state.menuToggled
        }, () => {
            if (this.state.menuToggled) {
                document.body.classList.add('ls-toggle-menu');
            } else {
                document.body.classList.remove('ls-toggle-menu');
            }
        })
    }


    render() {

        let className = "active open"
        const toggled = "menu-toggle waves-effect waves-block"
        const toogleMenu = "toggled waves-effect waves-block"
        const blockMenu = "waves-effect waves-block"
        const block = "menu-toggle waves-effect waves-block"

        let { usuarios, userRol, taskToggled, assetsToggled, inventoryToggled, usersToggled } = this.state;
        let { submenu } = this.props;
        userRol = parseInt(userRol, 10);
        return (
            <Scrollbars autoHide className="slimScrollDiv">
                <div className="navbar-brand">
                    <button onClick={() => this.toggleMenu()} className="btn-menu ls-toggle-btn" type="button"><i className="zmdi zmdi-menu" /></button>
                    <a href="/home"><img src={`/logo512.png`} width={50} alt="logo" /><span className="m-l-10">{DATOS_BASICOS.NOMEBRE_SISTEMA}</span></a>
                </div>
                <div className="menu">
                    <ul className="list">
                        <li >
                            <div className="user-info">
                                <a className="image" href="/profile"><img src={this.state.userPhoto} alt="User" /></a>
                                <div className="detail">
                                    <h4>{this.state.userName}</h4>
                                    <small>{this.getRolName(this.state.userRol)}</small>
                                </div>
                            </div>
                        </li>
                        <li className={this.props.id === "home" ? className : ""}><Button size="lg" onClick={() => history.push("/home")} color="link" ><i className="zmdi zmdi-home" /><span>Dashboard</span></Button></li>
                        {
                            (<li className={this.props.id === "tareas" ? className : ""}><Button size="lg" color="link" onClick={() => this.setState({ taskToggled: !this.state.taskToggled })} className={(this.state.taskToggled ? toggled : block)}><i className="zmdi zmdi-apps" /><span>Tareas</span></Button>
                                <ul className="ml-menu" style={{ display: taskToggled ? "block" : "none" }}>
                                    {(userRol === 1 || userRol === 2 || userRol === 3 || userRol === 6) && <li className={submenu === "planes" ? 'active' : ""}><a href="/calendarevents" className={submenu ? toogleMenu : blockMenu}>Planes de Mantenimientos</a></li>}
                                    <li className={submenu === "resumen" ? 'active' : ""}><a size="lg" href="/resumen" className={submenu ? toogleMenu : blockMenu}>Resumen de Mantenimiento</a></li>

                                    {/* {(userRol === 1 || userRol === 2 || userRol === 3) && <li className={submenu === "asignadas" ? 'active' : ""}><a href="/tasks" className={submenu ? toogleMenu : ""}>Tareas Asignadas</a></li>} */}
                                    {(userRol === 1 || userRol === 2 || userRol === 3 || userRol === 6) && <li className={submenu === "trabajo" ? 'active' : ""}><a href="/order" className={submenu ? toogleMenu : blockMenu}>Ordenes de Trabajo</a></li>}
                                    {(userRol === 1 || userRol === 2 || userRol === 3 || userRol === 6) && <li className={submenu === "servicio" ? "active" : ""}><a href="/service" className={submenu ? toogleMenu : blockMenu}>Ordenes de Servicio</a></li>}
                                    {(userRol === 1 || userRol === 2 || userRol === 3) && <li className={submenu === "solicitud_trabajo" ? "active" : ""}><a href="/orderequests" className={submenu ? toogleMenu : blockMenu}>Solicitudes de Orden de Trabajo</a></li>}
                                    <li className={submenu === "enviar" ? "active" : ""}><a href="/requestorder" className={submenu ? toogleMenu : ""}>Enviar Solicitud de Trabajo</a></li>
                                </ul>
                            </li>)
                        }
                        {
                            (userRol !== 4 && userRol !== 5) &&
                            (<li className={this.props.id === "activos" ? className : ""} > <Button size="lg" color="link" onClick={() => this.setState({ assetsToggled: !this.state.assetsToggled })} className={(this.state.assetsToggled ? toggled : block)} ><i className="zmdi zmdi-folder" /><span>Activos</span></Button>
                                <ul className="ml-menu" style={{ display: assetsToggled ? "block" : "none" }}>
                                    <li className={submenu === "todos" ? "active" : ""}><a href="/manageassest" className={submenu ? toogleMenu : blockMenu}>Todos los Activos</a></li>
                                    <li className={submenu === "localizaciones" ? "active" : ""}><a href="/managebuilding" className={submenu ? toogleMenu : blockMenu}>Localizaciones</a></li>
                                    <li className={submenu === "equipos" ? "active" : ""}><a href="/manageequipments" className={submenu ? toogleMenu : blockMenu}>Equipos</a></li>
                                    <li className={submenu === "herramientas" ? "active" : ""}><a href="/managetools" className={submenu ? toogleMenu : blockMenu}>Herramientas</a></li>
                                </ul>
                            </li>)
                        }
                        {
                            (userRol < 2 || userRol > 4) &&
                            (<li className={this.props.id === "reservas" ? className : ""}> <Button size="lg" color="link" onClick={() => this.setState({ inventoryToggled: !this.state.inventoryToggled })} className={(this.state.inventoryToggled ? toggled : block)}><i className="zmdi zmdi-hourglass-alt" /><span>Inventario</span></Button>
                                <ul className="ml-menu" style={{ display: inventoryToggled ? "block" : "none" }}>
                                    <li className={submenu === "repuestos_materiales" ? "active" : ""}><a href="/manageequipment" className={submenu ? toogleMenu : blockMenu}>Repuestos y Materiales</a></li>
                                    <li className={submenu === "stock" ? "active" : ""}><a href="/managestock" className={submenu ? toogleMenu : blockMenu}>Movimientos</a></li>
                                    <li className={submenu === "proveedores" ? "active" : ""}><a href="/managesuppliers" className={submenu ? toogleMenu : blockMenu}>Proveedores</a></li>
                                </ul>
                            </li>)
                        }
                        {/* {(userRol !== 4) && <li className={this.props.id === "reportes" ? className + " open_top" : "open_top"}><Button size="lg" color="link"  onClick={()=> this.setState({reportsToggled : !this.state.reportsToggled})} className={(this.state.reportsToggled ? toggled : "") + "menu-toggle"}><i className="zmdi zmdi-file-text" /><span>Reportes</span></Button>
                            <ul className="ml-menu"  style={{display: this.state.reportsToggled ? "block": "none"}}>
                                {(userRol === 1 || userRol === 2 || userRol === 3 || userRol === 6) && <li><a href={`${server}/report/activos`} target="_blank" rel="noopener noreferrer">Activos</a></li>}
                                {(userRol === 1 || userRol === 4 || userRol === 5) && <li><a href={`${server}/report/inventario`} target="_blank" rel="noopener noreferrer">Inventario</a></li>}
                                {(userRol === 1 || userRol === 4 || userRol === 5) && <li><a href={`${server}/report/proveedores`} target="_blank" rel="noopener noreferrer">Proveedores</a></li>}
                                {(userRol === 1 || userRol === 6) && <li><a href={`${server}/report/usuarios`} target="_blank" rel="noopener noreferrer">Usuarios</a></li>}
                                {(userRol === 1 || userRol === 2 || userRol === 6) && <li><a href={`${server}/report/planes`} target="_blank" rel="noopener noreferrer">Planes de Mantenimientos</a></li>}
                                {(userRol === 1 || userRol === 2 || userRol === 6) && <li><a href={`${server}/report/ordenes`} target="_blank" rel="noopener noreferrer">Ordenes</a></li>}
                                {(userRol === 1 || userRol === 2 || userRol === 6) && <li><Button size="lg" color="link" onClick={()=> this.setState({inputAlert : true})} >Detalle Orden</Button></li>}
                                {(userRol === 1 || userRol === 4 || userRol === 5) && <li><Button size="lg" color="link" onClick={()=> this.setState({showOtherAlert : true})} >Detalle Producto</Button></li>}
                                {(userRol === 1 || userRol === 2 || userRol === 3 || userRol === 6) && <li><Button size="lg" color="link" onClick={()=> this.fillUsers()}>Ordenes Asignadas</Button></li>}
                                {(userRol === 1 || userRol === 2 || userRol === 6) && <li><Button size="lg" color="link" onClick={()=> this.setState({planAlert : true})}>Próximos planes</Button></li>}
                            </ul>
                        </li>} */}
                        {
                            (userRol !== 4) && <li className={this.props.id === "reportes" ? className : ""}><Button size="lg" onClick={() => history.push("/reports")} color="link" ><i className="zmdi zmdi-file-text" /><span>Reportes</span></Button></li>
                        }
                        <li className={this.props.id === "profile" ? className : ""}><Button size="lg" onClick={() => history.push("/profile")} color="link" ><i className="zmdi zmdi-account" /><span>Mi Perfil</span></Button></li>
                        {
                            (userRol < 3 || userRol > 5) &&
                            (<li className={this.props.id === "configuracion" ? className : ""}><Button size="lg" color="link" onClick={() => this.setState({ usersToggled: !this.state.usersToggled })} className={(this.state.usersToggled ? toggled : block) + "menu-toggle"}><i className="zmdi zmdi-settings" /><span>Configuraciones</span></Button>
                                <ul className="ml-menu" style={{ display: usersToggled ? "block" : "none" }}>
                                    <li className={submenu === "usuarios" ? "active" : ""}><a href="/manageuser" className={submenu ? toogleMenu : blockMenu}>Usuarios</a></li>
                                    <li className={submenu === "tipo" ? "active" : ""}><a href="/assesttype" className={submenu ? toogleMenu : blockMenu}>Tipo de activo</a></li>
                                    {/* <a href="/assesttype">Tipo de activo</a></li> */}
                                    {/* <li><a href="#">Empresa</a></li>                    
                                    <li><a href="#">Importacion</a></li>
                                    <li><a href="#">MMC</a></li>
                                    <li><a href="#">Cuenta</a></li>                     */}
                                </ul>
                            </li>)
                        }
                        {/* <li>
                            <span style={{
                                fontSize: "10px",
                            }}>Version: {DATOS_BASICOS.VERSION}</span>
                        </li> */}
                    </ul>
                </div>
                <SweetAlert
                    title={"Detalle Producto"}
                    onConfirm={() => this.searchProducto()}
                    onCancel={() => this.setState({ showOtherAlert: false })}
                    show={this.state.showOtherAlert}
                >
                    <FormGroup>
                        <AsyncSelect noOptionsMessage={() => 'Sin Resultados'}
                            placeholder={"-- Busque producto --"}
                            cacheOptions
                            value={this.state.productoSel}
                            onChange={(e) => { this.onSearchProducto(e); }}
                            loadOptions={this.loadProductsOptions}
                            defaultOptions={false}
                            onInputChange={this.handleInputProducto}

                        />
                    </FormGroup>
                </SweetAlert>
                <SweetAlert
                    title={"Ordenes Asignadas a"}
                    onConfirm={() => this.searchOrdenAsignada()}
                    onCancel={() => this.setState({ showTecnicos: false })}
                    show={this.state.showTecnicos}
                >
                    <FormGroup>
                        <Select noOptionsMessage={() => 'Sin Resultados'}
                            placeholder={"Seleccione usuario"}
                            value={this.state.asignadoaSel}
                            onChange={this.selectUsuario}
                            options={usuarios}
                        />
                    </FormGroup>
                </SweetAlert>
                <SweetAlert warning title={this.state.title}
                    onConfirm={() => this.setState({ alert: false })}
                    onCancel={() => this.setState({ alert: false })}
                    show={this.state.alert}>
                    {this.state.message}
                </SweetAlert>
                <SweetAlert
                    input
                    showCancel
                    cancelBtnBsStyle="default"
                    title="Ingrese numero de orden"
                    placeHolder="#orden ej: 1"
                    onConfirm={(response) => this.searchOrden(response)}
                    onCancel={() => this.setState({ inputAlert: false })}
                    show={this.state.inputAlert}
                >
                </SweetAlert>
                <SweetAlert
                    title={"Seleccione rango de consulta"}
                    onConfirm={() => this.searchPlanes()}
                    onCancel={() => this.setState({ planAlert: false })}
                    show={this.state.planAlert}
                >
                    <div>
                        <Input type="text" value={this.state.fechaInicio !== "" ? moment(this.state.fechaInicio).format("DD/MM/YYYY") : ""} className="form-control datetimepicker" placeholder="Fecha de Inicio" onClick={() => this.setState({ isOpen: true })} />
                        <Input type="text" value={this.state.fechaFinal !== "" ? moment(this.state.fechaFinal).format("DD/MM/YYYY") : ""} className="form-control datetimepicker" placeholder="Fecha de Fin" onClick={() => this.setState({ isOpen2: true })} />
                        <div style={{ display: "none" }}>
                            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={"es"}>
                                <DatePicker
                                    value={this.state.fechaInicio}
                                    open={this.state.isOpen}
                                    onOpen={() => this.setState({ isOpen: true })}
                                    onClose={() => this.setState({ isOpen: false })}
                                    onChange={(date) => this.setState({ fechaInicio: date })}
                                    label="Fecha de Inicio"
                                    showTodayButton
                                    cancelLabel={"Cancelar"}
                                    okLabel={"Aceptar"}
                                    todayLabel={"HOY"}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div style={{ display: "none" }}>
                            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={"es"}>
                                <DatePicker
                                    value={this.state.fechaFinal}
                                    open={this.state.isOpen2}
                                    onOpen={() => this.setState({ isOpen2: true })}
                                    onClose={() => this.setState({ isOpen2: false })}
                                    onChange={(date) => this.setState({ fechaFinal: date })}
                                    label="Fecha Final"
                                    showTodayButton
                                    cancelLabel={"Cancelar"}
                                    okLabel={"Aceptar"}
                                    todayLabel={"HOY"}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </SweetAlert>
            </Scrollbars>
        )
    }
}