import React from 'react';
import '../App.css';

export default class TableItemlist extends React.Component {
    render() {
        let className = "badge "
        const priority = this.props.priority;
        switch (priority) {
            case 1:
                className = className + "badge-success"
                break;
            case 2:
                className = className + "badge-warning"
                break;
            case 3:
                className = className + "badge-primary"
                break;
            default:
        }
        return (
            <td className="hidden-md-down"><span className={className}>{this.props.item}</span></td>
        )
    }
}